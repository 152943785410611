@charset "UTF-8";
/**
 * ------------------------------------------------
 * CSS Variables
 * ------------------------------------------------
 */
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&display=swap");
:root {
  --color-primary: #BC9168;
  --color-primary-2: #CCAB8B;
  --color-primary-white: #F8F4EF;
  --color-primary-white-2: #EBDED2;
  --color-secondary: #528881;
  --color-secondary-white: #f3f7f7;
  --color-secondary-white-2: #E8F1F0;
  --color-tertiary: #16263F;
  --color-tertiary-2: #1B2C46;
  --color-tertiary-3: #365481;
  --color-tertiary-4: #d8e1ef;
  --color-tertiary-5: #1F3558;
  --color-dark: #0d1726;
  --color-grey: #6E7F99;
  --color-grey-2: #888;
  --color-grey-3: #F5F5F5;
  --color-grey-4: #eeeeee;
  --color-grey-5: #ccc;
  --color-grey-6: #aaa;
  --color-white: #fff;
  --color-danger: #fc4453;
  --color-success: #28b133;
  --color-facebook: #1877f2;
  --color-instagram: #c32aa3;
  --color-linkedin: #0a66c2;
  --color-twitter: #1da1f2;
  --font-family-1st: "Lato", sans-serif;
  --font-family-2nd: "Lato", sans-serif;
}

/**
 * ------------------------------------------------
 * SASS Config Variables
 * ------------------------------------------------
 */
/* -----------------------------------------------
 *  Background mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Button mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Font icon mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Flex mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Grid mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Hover mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Icon mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Image mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Pellet mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Section mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Title mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Visibility mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Z-index mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  CUSTOM mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Grid creation
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Grid edit
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Grid helpers
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Background extend
 ------------------------------------------------- */
.section-join-us .section-overlay {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* -----------------------------------------------
 *  Button extend
 ------------------------------------------------- */
.button-white-secondary, .button-white-danger, .button-white-outline, .button-neutral-outline, .modal .btn-secondary, .button-secondary-outline, .button-primary-outline, .button-primary, .header__button, .modal .btn-primary {
  border-radius: 99rem;
  border: 2px solid transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  gap: 1rem;
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: 1.5rem;
  padding: 2rem 5.5rem;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s;
}

.button-icon-primary, .carousel-controllers .prev, .carousel-controllers .next {
  border: 2px solid transparent;
  border-radius: 5.8rem;
  cursor: pointer;
  width: 5.8rem;
  height: 5.8rem;
  transition: 0.4s;
}

/* -----------------------------------------------
 *  Font icon extend
 ------------------------------------------------- */
.carousel-controllers .prev::before, .carousel-controllers .next::before, .fig-users::before, .fig-user-add::before, .event-participant__icon::before, .identity-card::after, .fig-user::before, .fig-trash::before, .fig-sign-out-alt::before, .member-card__verified::after, .fig-shield-check::before, .fig-search::before, .fig-picture::before, .fig-phone-call::before, .fig-list::before, .fig-lock::before, .event-card__location::before, .fig-map-marker::before, .fig-marker::before, .fig-id-badge::before, .fig-grid::before, .fig-globe::before, .see-password-wrap .see-password::before, .fig-eye-crossed::before, .see-password-wrap .see-password.active::before, .fig-eye::before, .fig-euro::before, .fig-envelope::before, .fig-edit::before, .fig-document::before, .fig-dashboard::before, .event-participant__option--without .option-label::before, .alert-container .alert__close::before, .fig-cross::before, .event-card--feat .event-card__hour::before, .fig-clock::before, .fig-checkbox::before, .event-participant__option--with .option-label::before, .checkbox-switch::after, .checkbox-group .label::before, .fig-check::before, .fig-calendar-plus::before, .fig-calendar-minus::before, .notification--danger::before, .fig-calendar-exclamation::before, .fig-calendar-check::before, .fig-calendar::before, .fig-building::before, .widget--checkbox input:checked + .widget__content::after, .notification--secondary::before, .fig-bell-ring::before, .widget--checkbox .widget__content::after, .fig-bell::before, .fig-arrow-right::before, .scroll-top::before, .fig-arrow-to-top::before, .accordion__header::before, .fig-angle-down::before {
  display: inline-block;
  font-family: uicons-regular-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateY(0.09em);
}

.fib-twitter::before, .fib-linkedin::before, .fib-instagram::before, .fib-facebook::before {
  display: inline-block;
  font-family: uicons-brands !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateY(1px);
}

/* -----------------------------------------------
 *  Flex extend
 ------------------------------------------------- */
.dashboard-bottom-nav a, .dashboard-main-nav a, .header__extra, .header__menu, .header__nav, .header__wrap, .event-card__footer, .event-card__location, .carousel-controllers, .cards-content-2__item, .banner__breadcrumb .breadcrumb, .alert-container .alert, .accordion__title {
  display: flex;
  align-items: center;
}

.event-chronology__left {
  display: flex;
  justify-content: center;
}

.event-participant__icon, .dashboard-main-nav a i, .socials__item, .socials, .scroll-top, .partners__item, .pagination__item, .pagination__list, .member-card__verified, .member-card__action, .member-card__actions, .list-card__remove, .identity-card::after, .icon-text__icon, .accordion__title i, .input-group__label, .checkbox-button__content, .button-icon-primary, .carousel-controllers .prev, .carousel-controllers .next {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.stable-label__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center-between-w {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* -----------------------------------------------
 *  Grid extend
 ------------------------------------------------- */
.page-content, .section-login, .section-partners, .section-events, .section-join-us, .section-become-member, .section-banner, .section-grid, .footer {
  display: grid;
  grid-template-columns: minmax(2rem, 1fr) minmax(0, 140rem) minmax(2rem, 1fr);
}
.page-content > *, .section-login > *, .section-partners > *, .section-events > *, .section-join-us > *, .section-become-member > *, .section-banner > *, .section-grid > *, .footer > * {
  grid-column: 2/-2;
}

.page-member .container, .section-events .container, .section-goals .container, .events-container--grid, .section-event-sub .container, .section-event-desc .container, .container-overview, .section-contact .container, .row-2c-smart, .partners, .members-list, .gallery-grid, .gallery, .event-chronology, .cards-content-2, .cards-content, .banner .container {
  display: grid;
  grid-gap: 2rem;
}

.row-3-9, .row-3c, .row-2c {
  display: grid;
  grid-column-gap: 2rem;
}

.section-event-sub__overlay {
  grid-column: 1/-1;
}

.banner__overlays {
  grid-column: 1/-1 !important;
}

/* -----------------------------------------------
 *  Hover extend
 ------------------------------------------------- */
.event-card__title:hover, .event-card__title:focus {
  color: var(--color-primary);
}

.link-feat-secondary:hover, .link-feat-secondary:focus {
  color: var(--color-secondary);
}

/* -----------------------------------------------
 *  Icon extend
 ------------------------------------------------- */
.input-icon__icon i {
  display: inline-block;
  transform: translateY(1px);
}

/* -----------------------------------------------
 *  Image extend
 ------------------------------------------------- */
.section-event-sub__overlay img, .section-event-resume__image img, .section-checkout__image img, .section-about-intro__overlay img, .photo-grid__image img, .member-card-h__picture img, .member-card__picture img, .gallery-grid__item img, .gallery__item img, .event-card--2 .event-card__image > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

/* -----------------------------------------------
 *  Section extend
 ------------------------------------------------- */
.section-login, .section-partners, .section-events, .section-join-us, .section-become-member, .section-goals, .section-banner {
  position: relative;
}

/* -----------------------------------------------
 *  Stacking extend
 ------------------------------------------------- */
.page-subscription .right, .section-event-sub, .section-event-desc, .section-event-resume, .dashboard-content, .section-checkout, .section-about-intro, .member-card-h, .member-card, .filter__wrap, .event-chronology__left, .cards-content__rightStyle, .card--bubble1 {
  position: relative;
  isolation: isolate;
}

/* -----------------------------------------------
 *  Title extend
 ------------------------------------------------- */
h6, .h6, .event-participant__name, .footer__title, .footer__slogan, .member-card__title, h5, .h5, .icon-text__title, .event-card__title, .cards-content-2__title, .blocks-overlay__title, .banner__suptitle, h4, .h4, .member-card-h__title, .cards-content__title, h3, .h3, .section-title__main, h2, .h2, .banner__title, h1, .h1 {
  color: var(--color-tertiary);
  font-family: var(--font-family-1st);
  font-weight: bold;
  text-transform: uppercase;
}

.banner__title, h1, .h1 {
  font-size: 4rem;
  letter-spacing: 0.08em;
  line-height: 1.2;
  margin-bottom: 4rem;
}

.section-title__main, h2, .h2 {
  font-size: 3rem;
  letter-spacing: 0.08em;
  line-height: 1.3;
  margin-bottom: 3rem;
}

.member-card-h__title, .cards-content__title, h3, .h3 {
  font-size: 2.5rem;
  letter-spacing: 0.05em;
  line-height: 1.3;
  margin-bottom: 1.5rem;
}

.icon-text__title, .event-card__title, .cards-content-2__title, .blocks-overlay__title, .banner__suptitle, h4, .h4 {
  font-size: 2rem;
  letter-spacing: 0.05em;
  line-height: 1.4;
  margin-bottom: 1rem;
}

.event-participant__name, .footer__title, .footer__slogan, .member-card__title, h5, .h5 {
  font-size: 1.8rem;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin-bottom: 1rem;
}

h6, .h6 {
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  line-height: 1.6;
  margin-bottom: 1rem;
}

/* -----------------------------------------------
 *  Visibility extend
 ------------------------------------------------- */
.scroll-top, .condition-step > *.accepted, .condition-step > *:first-child ~ *:not(.active), .collapse__content, .profile-image .mt-overlay, .nice-select-dropdown, .link-feat-secondary::after, .link::after {
  opacity: 0;
  visibility: hidden;
}

.scroll-top.active, .condition-step > *.active, .collapse__content.active, .profile-image:hover .mt-overlay, .nice-select.open .nice-select-dropdown, .link-feat-secondary:hover::after, .link:hover::after, .link-feat-secondary:focus::after, .link:focus::after {
  opacity: 1;
  visibility: visible;
}

/* -----------------------------------------------
 *  Z-index extend
 ------------------------------------------------- */
.section-join-us .container, .section-join-us, .section-goals .left, .section-goals > *, .section-title--1 > *, .section-title--1 + *, .footer__main > *, .identity-card > *, .cards-content__item > *, .section-icon > *, .icon-text--secondary .icon-text__icon > *, .icon-text--tertiary .icon-text__icon > *, .icon-text--tertiary3 .icon-text__icon > *, .icon-text--primary .icon-text__icon > *, .event-card__date > * {
  position: relative;
  z-index: 1;
}

/* -----------------------------------------------
 *  CUSTOM extend
 ------------------------------------------------- */
.section-icon, .icon-text--secondary .icon-text__icon, .icon-text--tertiary .icon-text__icon, .icon-text--tertiary3 .icon-text__icon, .icon-text--primary .icon-text__icon, .event-card__date {
  background-color: var(--color-secondary);
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 10.5rem;
  width: 10.5rem;
}
.section-icon > svg, .icon-text--secondary .icon-text__icon > svg, .icon-text--tertiary .icon-text__icon > svg, .icon-text--tertiary3 .icon-text__icon > svg, .icon-text--primary .icon-text__icon > svg, .event-card__date > svg {
  width: 7.2rem;
  height: 7.2rem;
}
.section-icon::after, .icon-text--secondary .icon-text__icon::after, .icon-text--tertiary .icon-text__icon::after, .icon-text--tertiary3 .icon-text__icon::after, .icon-text--primary .icon-text__icon::after, .event-card__date::after {
  content: "";
  background-color: var(--color-secondary);
  position: absolute;
  bottom: 5%;
  right: 5%;
  height: 33%;
  width: 33%;
  transform: skewX(20deg) rotate(7deg);
  z-index: 0;
}

.event-card__date {
  background-color: var(--color-primary);
}
.event-card__date::after {
  background-color: var(--color-primary);
}

.icon-text--primary .icon-text__icon {
  background-color: var(--color-primary-white);
}
.icon-text--primary .icon-text__icon::after {
  background-color: var(--color-primary-white);
}

.icon-text--tertiary .icon-text__icon, .icon-text--tertiary3 .icon-text__icon {
  background-color: var(--color-grey-3);
}
.icon-text--tertiary .icon-text__icon::after, .icon-text--tertiary3 .icon-text__icon::after {
  background-color: var(--color-grey-3);
}

.icon-text--secondary .icon-text__icon {
  background-color: var(--color-secondary-white);
}
.icon-text--secondary .icon-text__icon::after {
  background-color: var(--color-secondary-white);
}

.banner__overlays::before {
  -webkit-mask-image: url("/storage/icones/bubble-left.svg");
  mask-image: url("/storage/icones/bubble-left.svg");
  -webkit-mask-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: auto;
  mask-size: auto;
  content: "";
  background: url("/storage/businesspeople-celebrating-success-overlay-tertiary-w1000.webp") center center no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  height: 100rem;
  width: 100rem;
  z-index: 0;
  transform: translate(14.5%, -17%);
}

.footer__main::before {
  -webkit-mask-image: url("/storage/icones/bubble-left.svg");
  mask-image: url("/storage/icones/bubble-left.svg");
  -webkit-mask-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: auto;
  mask-size: auto;
  content: "";
  background: url("/storage/image-color-tertiaty-overlay-produc-meaux-w1000x1000t.jpg") center center no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  height: 100rem;
  width: 100rem;
  z-index: 0;
  transform: translate(14.5%, -17%);
}

.footer__slogan span, .banner__title span {
  color: var(--color-secondary);
  font-weight: bold;
}

.link-feat-secondary, .link {
  color: var(--color-primary);
  display: inline-block;
  font-size: inherit;
  font-weight: bold;
  position: relative;
}
.link-feat-secondary::after, .link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  transition: 0.4s;
}
.link-feat-secondary:hover::after, .link:hover::after, .link-feat-secondary:focus::after, .link:focus::after {
  background-color: var(--color-primary);
  width: 100%;
}

.banner__suptitle, .section-title--white .section-title__main, .section-title__main, .footer__title, .member-card-h__title, .banner--page404 .title-404 {
  padding-bottom: 2rem;
  position: relative;
}
.banner__suptitle::after, .section-title--white .section-title__main::after, .section-title__main::after, .footer__title::after, .member-card-h__title::after, .banner--page404 .title-404::after {
  content: "";
  background: url("/storage/arriere-plan/section-line-primary.svg") center center no-repeat;
  background-size: cover;
  width: 10rem;
  height: 1.4rem;
  z-index: 0;
  position: absolute;
  left: 0;
  bottom: 0;
}

.section-title__main::after, .footer__title::after, .member-card-h__title::after, .banner--page404 .title-404::after {
  background-image: url("/storage/arriere-plan/section-line-secondary.svg");
}

.section-title--white .section-title__main::after {
  background-image: url("/storage/arriere-plan/section-line-white.svg");
}

.section-join-us, .section-about-intro, .banner {
  position: relative;
}
.section-join-us::after, .section-about-intro::after, .banner::after {
  content: "";
  background: url("/storage/arriere-plan/section-line-secondary.svg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 24rem;
  z-index: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(50%);
  z-index: 5;
}

.footer {
  position: relative;
}
.footer::before {
  content: "";
  background: url("/storage/arriere-plan/section-line-tertiary-3.svg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 24rem;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  z-index: 5;
}

/* -----------------------------------------------
 *  Auto generated extends
 * -> You should not modify that part
 ------------------------------------------------- */
.u-mb-0 {
  margin-bottom: 0 !important;
}

.u-ml-0 {
  margin-left: 0 !important;
}

.u-pl-0 {
  padding-left: 0 !important;
}

.u-pb-0 {
  padding-bottom: 0 !important;
}

.u-mr-5 {
  margin-right: calc(5rem/10) !important;
}

.u-mb-5 {
  margin-bottom: calc(5rem/10) !important;
}

.u-mt-10 {
  margin-top: calc(10rem/10) !important;
}

.u-mb-10 {
  margin-bottom: calc(10rem/10) !important;
}

.u-my-10 {
  margin-top: calc(10rem/10) !important;
  margin-bottom: calc(10rem/10) !important;
}

.u-mt-20 {
  margin-top: calc(20rem/10) !important;
}

.u-mb-20 {
  margin-bottom: calc(20rem/10) !important;
}

.u-mt-30 {
  margin-top: calc(30rem/10) !important;
}

.u-mb-30 {
  margin-bottom: calc(30rem/10) !important;
}

.u-mt-40 {
  margin-top: calc(40rem/10) !important;
}

.u-mt-50 {
  margin-top: calc(50rem/10) !important;
}

.u-my-50 {
  margin-top: calc(50rem/10) !important;
  margin-bottom: calc(50rem/10) !important;
}

.u-mb-50 {
  margin-bottom: calc(50rem/10) !important;
}

.u-mb-70 {
  margin-bottom: calc(70rem/10) !important;
}

.section-login, .section-partners, .section-events, .section-join-us, .section-become-member, .section-goals, .section-banner {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.footer__main {
  padding-bottom: 10rem;
}

.section-events, .footer {
  padding-top: 15rem;
}

.section-join-us, .section-about-intro, .banner {
  padding-bottom: 15rem;
}

.section-login .container {
  padding-top: 18rem;
}

.section-become-member {
  padding-bottom: 18rem;
}

.footer {
  padding-top: 20rem;
}

.section-partners {
  padding-bottom: 30rem;
}

@keyframes spinning {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes fadeInBottomSmall {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@keyframes growTransp {
  from {
    opacity: 0.8;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(4);
  }
}
/* -----------------------------------------------
 *  Google fonts
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Uicons fonts

    -> If you want to use an icon, you have to copy and paste this one in the appropriate SASS array
        You can then extend this font with its name.
        ° Examples: "add-folder" is a "General Icon" and can be used as "@extend %fig-add-folder" and also "<i class="fig-add-folder"></i>"
                    "facebook" is a "Brand Icon" and can be used as "@extend %fib-facebook" and also "<i class="fib-facebook"></i>"


    >>> GENERAL ICONS ($uicons-fonts)
    "a":"\f101",
    "ad":"\f102",
    "add-document":"\f103",
    "add-folder":"\f104",
    "add":"\f105",
    "address-book":"\f106",
    "air-conditioner":"\f107",
    "air-freshener":"\f108",
    "alarm-clock":"\f109",
    "alarm-exclamation":"\f10a",
    "alarm-plus":"\f10b",
    "alarm-snooze":"\f10c",
    "align-justify":"\f10d",
    "align-left":"\f10e",
    "ambulance":"\f10f",
    "angle-circle-down":"\f110",
    "angle-circle-left":"\f111",
    "angle-circle-right":"\f112",
    "angle-circle-up":"\f113",
    "angle-double-left":"\f114",
    "angle-double-right":"\f115",
    "angle-double-small-down":"\f116",
    "angle-double-small-left":"\f117",
    "angle-double-small-right":"\f118",
    "angle-double-small-up":"\f119",
    "angle-down":"\f11a",
    "angle-left":"\f11b",
    "angle-right":"\f11c",
    "angle-small-down":"\f11d",
    "angle-small-left":"\f11e",
    "angle-small-right":"\f11f",
    "angle-small-up":"\f120",
    "angle-square-down":"\f121",
    "angle-square-left":"\f122",
    "angle-square-right":"\f123",
    "angle-square-up":"\f124",
    "angle-up":"\f125",
    "angry":"\f126",
    "apple-whole":"\f127",
    "apps-add":"\f128",
    "apps-delete":"\f129",
    "apps-sort":"\f12a",
    "apps":"\f12b",
    "archive":"\f12c",
    "arrow-alt-circle-down":"\f12d",
    "arrow-alt-circle-left":"\f12e",
    "arrow-alt-circle-right":"\f12f",
    "arrow-alt-circle-up":"\f130",
    "arrow-alt-down":"\f131",
    "arrow-alt-from-bottom":"\f132",
    "arrow-alt-from-left":"\f133",
    "arrow-alt-from-right":"\f134",
    "arrow-alt-from-top":"\f135",
    "arrow-alt-left":"\f136",
    "arrow-alt-right":"\f137",
    "arrow-alt-square-down":"\f138",
    "arrow-alt-square-left":"\f139",
    "arrow-alt-square-right":"\f13a",
    "arrow-alt-square-up":"\f13b",
    "arrow-alt-to-bottom":"\f13c",
    "arrow-alt-to-left":"\f13d",
    "arrow-alt-to-right":"\f13e",
    "arrow-alt-to-top":"\f13f",
    "arrow-alt-up":"\f140",
    "arrow-circle-down":"\f141",
    "arrow-circle-left":"\f142",
    "arrow-circle-right":"\f143",
    "arrow-circle-up":"\f144",
    "arrow-down":"\f145",
    "arrow-from-bottom":"\f146",
    "arrow-from-left":"\f147",
    "arrow-from-right":"\f148",
    "arrow-from-top":"\f149",
    "arrow-left":"\f14a",
    "arrow-right":"\f14b",
    "arrow-small-down":"\f14c",
    "arrow-small-left":"\f14d",
    "arrow-small-right":"\f14e",
    "arrow-small-up":"\f14f",
    "arrow-square-down":"\f150",
    "arrow-square-left":"\f151",
    "arrow-square-right":"\f152",
    "arrow-square-up":"\f153",
    "arrow-to-bottom":"\f154",
    "arrow-to-left":"\f155",
    "arrow-to-right":"\f156",
    "arrow-to-top":"\f157",
    "arrow-up":"\f158",
    "arrows-alt-h":"\f159",
    "arrows-alt-v":"\f15a",
    "arrows-alt":"\f15b",
    "arrows-h-copy":"\f15c",
    "arrows-h":"\f15d",
    "arrows":"\f15e",
    "assept-document":"\f15f",
    "assistive-listening-systems":"\f160",
    "asterik":"\f161",
    "at":"\f162",
    "aubergine":"\f163",
    "avocado":"\f164",
    "b":"\f165",
    "baby-carriage":"\f166",
    "backpack":"\f167",
    "bacon":"\f168",
    "badge-sheriff":"\f169",
    "badge-check":"\f16a",
    "badge-dollar":"\f16b",
    "badge-percent":"\f16c",
    "badge":"\f16d",
    "bags-shopping":"\f16e",
    "bahai":"\f16f",
    "balance-scale-left":"\f170",
    "balance-scale-right":"\f171",
    "ball":"\f172",
    "balloons":"\f173",
    "ballot":"\f174",
    "ban":"\f175",
    "band-aid":"\f176",
    "bank":"\f177",
    "barber-shop":"\f178",
    "bars-progress":"\f179",
    "baseball-alt":"\f17a",
    "baseball":"\f17b",
    "basket":"\f17c",
    "basketball":"\f17d",
    "bed-alt":"\f17e",
    "bed":"\f17f",
    "beer":"\f180",
    "bell-ring":"\f181",
    "bell-school":"\f182",
    "bell":"\f183",
    "bike":"\f184",
    "biking-mountain":"\f185",
    "biking":"\f186",
    "billiard":"\f187",
    "blinds-open":"\f188",
    "blinds-raised":"\f189",
    "blinds":"\f18a",
    "bold":"\f18b",
    "bolt":"\f18c",
    "book-alt":"\f18d",
    "book-arrow-right":"\f18e",
    "book-arrow-up":"\f18f",
    "book-bookmark":"\f190",
    "book-open-cover":"\f191",
    "book-open-reader":"\f192",
    "book":"\f193",
    "bookmark":"\f194",
    "books":"\f195",
    "bottle":"\f196",
    "bow-arrow":"\f197",
    "bowling-ball":"\f198",
    "bowling-pins":"\f199",
    "bowling":"\f19a",
    "box-check":"\f19b",
    "box-tissue":"\f19c",
    "box-alt":"\f19d",
    "box-ballot":"\f19e",
    "box":"\f19f",
    "boxes":"\f1a0",
    "boxing-glove":"\f1a1",
    "braille":"\f1a2",
    "bread-slice":"\f1a3",
    "bread":"\f1a4",
    "briefcase":"\f1a5",
    "broccoli":"\f1a6",
    "broom":"\f1a7",
    "browser":"\f1a8",
    "brush":"\f1a9",
    "bug":"\f1aa",
    "building":"\f1ab",
    "bulb":"\f1ac",
    "bullseye-pointer":"\f1ad",
    "bullseye":"\f1ae",
    "burrito":"\f1af",
    "bus-alt":"\f1b0",
    "bus":"\f1b1",
    "business-time":"\f1b2",
    "butterfly":"\f1b3",
    "cake-birthday":"\f1b4",
    "cake-wedding":"\f1b5",
    "calculator":"\f1b6",
    "calendar-check":"\f1b7",
    "calendar-clock":"\f1b8",
    "calendar-exclamation":"\f1b9",
    "calendar-lines-pen":"\f1ba",
    "calendar-lines":"\f1bb",
    "calendar-minus":"\f1bc",
    "calendar-pen":"\f1bd",
    "calendar-plus":"\f1be",
    "calendar":"\f1bf",
    "calendars":"\f1c0",
    "call-history":"\f1c1",
    "call-incoming":"\f1c2",
    "call-missed":"\f1c3",
    "call-outgoing":"\f1c4",
    "camcorder":"\f1c5",
    "camera":"\f1c6",
    "camping":"\f1c7",
    "candy-alt":"\f1c8",
    "candy":"\f1c9",
    "canned-food":"\f1ca",
    "car-bolt":"\f1cb",
    "car-circle-bolt":"\f1cc",
    "car-side-bolt":"\f1cd",
    "car-alt":"\f1ce",
    "car-battery":"\f1cf",
    "car-building":"\f1d0",
    "car-bump":"\f1d1",
    "car-bus":"\f1d2",
    "car-crash":"\f1d3",
    "car-garage":"\f1d4",
    "car-mechanic":"\f1d5",
    "car-side":"\f1d6",
    "car-tilt":"\f1d7",
    "car-wash":"\f1d8",
    "car":"\f1d9",
    "caravan-alt":"\f1da",
    "caravan":"\f1db",
    "caret-circle-down":"\f1dc",
    "caret-circle-right":"\f1dd",
    "caret-circle-up":"\f1de",
    "caret-down":"\f1df",
    "caret-left":"\f1e0",
    "caret-quare-up":"\f1e1",
    "caret-right":"\f1e2",
    "caret-square-down":"\f1e3",
    "caret-square-left_1":"\f1e4",
    "caret-square-left":"\f1e5",
    "caret-square-right":"\f1e6",
    "caret-up":"\f1e7",
    "carrot":"\f1e8",
    "cars":"\f1e9",
    "cash-register":"\f1ea",
    "chair-office":"\f1eb",
    "chair":"\f1ec",
    "charging-station":"\f1ed",
    "chart-area":"\f1ee",
    "chart-connected":"\f1ef",
    "chart-histogram":"\f1f0",
    "chart-line-up":"\f1f1",
    "chart-network":"\f1f2",
    "chart-pie-alt":"\f1f3",
    "chart-pie":"\f1f4",
    "chart-pyramid":"\f1f5",
    "chart-scatter":"\f1f6",
    "chart-set-theory":"\f1f7",
    "chart-tree":"\f1f8",
    "chat-arrow-down":"\f1f9",
    "chat-arrow-grow":"\f1fa",
    "check":"\f1fb",
    "checkbox":"\f1fc",
    "cheese":"\f1fd",
    "cherry":"\f1fe",
    "chess-piece":"\f1ff",
    "chess-bishop":"\f200",
    "chess-board":"\f201",
    "chess-clock-alt":"\f202",
    "chess-clock":"\f203",
    "chess-king-alt":"\f204",
    "chess-king":"\f205",
    "chess-knight-alt":"\f206",
    "chess-knight":"\f207",
    "chess-pawn-alt":"\f208",
    "chess-queen-alt":"\f209",
    "chess-queen":"\f20a",
    "chess-rook-alt":"\f20b",
    "chess-rook":"\f20c",
    "chess":"\f20d",
    "chevron-double-down":"\f20e",
    "chevron-double-up":"\f20f",
    "child-head":"\f210",
    "chocolate":"\f211",
    "circle-phone":"\f212",
    "circle-envelope":"\f213",
    "circle-phone-flip":"\f214",
    "circle-phone-hangup":"\f215",
    "circle-small":"\f216",
    "circle":"\f217",
    "clip":"\f218",
    "clipboard-list":"\f219",
    "clipboard-list-check":"\f21a",
    "clock-eight-thirty":"\f21b",
    "clock-eleven-thirty":"\f21c",
    "clock-eleven":"\f21d",
    "clock-five-thirty":"\f21e",
    "clock-five":"\f21f",
    "clock-four-thirty":"\f220",
    "clock-nine-thirty":"\f221",
    "clock-nine":"\f222",
    "clock-one-thirty":"\f223",
    "clock-one":"\f224",
    "clock-seven-thirty":"\f225",
    "clock-seven":"\f226",
    "clock-six-thirty":"\f227",
    "clock-six":"\f228",
    "clock-ten-thirty":"\f229",
    "clock-ten":"\f22a",
    "clock-three-thirty":"\f22b",
    "clock-three":"\f22c",
    "clock-twelve-thirty":"\f22d",
    "clock-twelve":"\f22e",
    "clock-two-thirty":"\f22f",
    "clock-two":"\f230",
    "clock":"\f231",
    "cloud-upload":"\f232",
    "cloud-check":"\f233",
    "cloud-disabled":"\f234",
    "cloud-download-alt":"\f235",
    "cloud-download":"\f236",
    "cloud-drizzle":"\f237",
    "cloud-hail-mixed":"\f238",
    "cloud-hail":"\f239",
    "cloud-moon-rain":"\f23a",
    "cloud-moon":"\f23b",
    "cloud-rain":"\f23c",
    "cloud-rainbow":"\f23d",
    "cloud-share":"\f23e",
    "cloud-showers-heavy":"\f23f",
    "cloud-showers":"\f240",
    "cloud-sleet":"\f241",
    "cloud-snow":"\f242",
    "cloud-sun-rain":"\f243",
    "cloud-sun":"\f244",
    "cloud-upload-alt":"\f245",
    "cloud":"\f246",
    "clouds-moon":"\f247",
    "clouds-sun":"\f248",
    "clouds":"\f249",
    "club":"\f24a",
    "cocktail-alt":"\f24b",
    "cocktail":"\f24c",
    "coffee-pot":"\f24d",
    "coffee":"\f24e",
    "coin":"\f24f",
    "coins":"\f250",
    "comet":"\f251",
    "comment-alt-middle-top":"\f252",
    "comment-alt-middle":"\f253",
    "comment-alt":"\f254",
    "comment-arrow-down":"\f255",
    "comment-arrow-up-right":"\f256",
    "comment-arrow-up":"\f257",
    "comment-check":"\f258",
    "comment-code":"\f259",
    "comment-dollar":"\f25a",
    "comment-exclamation":"\f25b",
    "comment-heart":"\f25c",
    "comment-image":"\f25d",
    "comment-info":"\f25e",
    "comment-pen":"\f25f",
    "comment-question":"\f260",
    "comment-quote":"\f261",
    "comment-slash":"\f262",
    "comment-sms":"\f263",
    "comment-text":"\f264",
    "comment-user":"\f265",
    "comment-xmark":"\f266",
    "comment":"\f267",
    "comments-dollar":"\f268",
    "comments-question-check":"\f269",
    "comments-question":"\f26a",
    "comments":"\f26b",
    "compress-alt":"\f26c",
    "compress":"\f26d",
    "computer":"\f26e",
    "confetti":"\f26f",
    "cookie":"\f270",
    "copy-alt":"\f271",
    "copy":"\f272",
    "copyright":"\f273",
    "corn":"\f274",
    "cow":"\f275",
    "cream":"\f276",
    "credit-card":"\f277",
    "cricket":"\f278",
    "croissant":"\f279",
    "cross-circle":"\f27a",
    "cross-small":"\f27b",
    "cross":"\f27c",
    "crown":"\f27d",
    "crystal-ball":"\f27e",
    "cube":"\f27f",
    "cupcake":"\f280",
    "curling":"\f281",
    "cursor-finger":"\f282",
    "cursor-plus":"\f283",
    "cursor-text-alt":"\f284",
    "cursor-text":"\f285",
    "cursor":"\f286",
    "d":"\f287",
    "dart":"\f288",
    "dashboard":"\f289",
    "data-transfer":"\f28a",
    "database":"\f28b",
    "delete-document":"\f28c",
    "delete-user":"\f28d",
    "delete":"\f28e",
    "democrat":"\f28f",
    "dewpoint":"\f290",
    "diamond":"\f291",
    "dice-alt":"\f292",
    "dice-d10":"\f293",
    "dice-d12":"\f294",
    "dice-d20":"\f295",
    "dice-d4":"\f296",
    "dice-d6":"\f297",
    "dice-d8":"\f298",
    "dice-four":"\f299",
    "dice-one":"\f29a",
    "dice-six":"\f29b",
    "dice-three":"\f29c",
    "dice-two":"\f29d",
    "dice":"\f29e",
    "diploma":"\f29f",
    "disco-ball":"\f2a0",
    "disk":"\f2a1",
    "dizzy":"\f2a2",
    "doctor":"\f2a3",
    "document-signed":"\f2a4",
    "document":"\f2a5",
    "dollar":"\f2a6",
    "donate":"\f2a7",
    "down-left-and-up-right-to-center":"\f2a8",
    "download":"\f2a9",
    "drafting-compass":"\f2aa",
    "dreidel":"\f2ab",
    "drink-alt":"\f2ac",
    "drumstick":"\f2ad",
    "duplicate":"\f2ae",
    "e-learning":"\f2af",
    "e":"\f2b0",
    "earnings":"\f2b1",
    "eclipse-alt":"\f2b2",
    "eclipse":"\f2b3",
    "edit-alt":"\f2b4",
    "edit":"\f2b5",
    "egg-fried":"\f2b6",
    "egg":"\f2b7",
    "engine-warning":"\f2b8",
    "enter":"\f2b9",
    "envelope-ban":"\f2ba",
    "envelope-bulk":"\f2bb",
    "envelope-download":"\f2bc",
    "envelope-marker":"\f2bd",
    "envelope-open-dollar":"\f2be",
    "envelope-open-text":"\f2bf",
    "envelope-open":"\f2c0",
    "envelope-plus":"\f2c1",
    "envelope":"\f2c2",
    "equality":"\f2c3",
    "euro":"\f2c4",
    "exchange-alt":"\f2c5",
    "exchange":"\f2c6",
    "exclamation":"\f2c7",
    "exit":"\f2c8",
    "expand-arrows-alt":"\f2c9",
    "expand-arrows":"\f2ca",
    "expand":"\f2cb",
    "eye-crossed":"\f2cc",
    "eye-dropper":"\f2cd",
    "eye":"\f2ce",
    "f":"\f2cf",
    "feather":"\f2d0",
    "ferris-wheel":"\f2d1",
    "fighter-jet":"\f2d2",
    "file-invoice-dollar":"\f2d3",
    "file-invoice":"\f2d4",
    "file-ai":"\f2d5",
    "file-chart-line":"\f2d6",
    "file-chart-pie":"\f2d7",
    "file-eps":"\f2d8",
    "file-psd":"\f2d9",
    "file-spreadsheet":"\f2da",
    "file-video":"\f2db",
    "file":"\f2dc",
    "fill":"\f2dd",
    "film-slash":"\f2de",
    "film":"\f2df",
    "filter-slash":"\f2e0",
    "filter":"\f2e1",
    "filters":"\f2e2",
    "fingerprint":"\f2e3",
    "fish":"\f2e4",
    "flag-alt":"\f2e5",
    "flag-usa":"\f2e6",
    "flag":"\f2e7",
    "flame":"\f2e8",
    "flip-horizontal":"\f2e9",
    "flower-bouquet":"\f2ea",
    "flower-tulip":"\f2eb",
    "flower":"\f2ec",
    "flushed":"\f2ed",
    "fog":"\f2ee",
    "folder-download":"\f2ef",
    "folder-minus":"\f2f0",
    "folder-times":"\f2f1",
    "folder-tree":"\f2f2",
    "folder-upload":"\f2f3",
    "folder":"\f2f4",
    "folders":"\f2f5",
    "following":"\f2f6",
    "football":"\f2f7",
    "fork":"\f2f8",
    "form":"\f2f9",
    "forward":"\f2fa",
    "fox":"\f2fb",
    "french-fries":"\f2fc",
    "frown":"\f2fd",
    "ftp":"\f2fe",
    "funnel-dollar":"\f2ff",
    "g":"\f300",
    "gallery":"\f301",
    "game-board-alt":"\f302",
    "gamepad":"\f303",
    "garage-car":"\f304",
    "garage-open":"\f305",
    "garage":"\f306",
    "garlic":"\f307",
    "gas-pump-alt":"\f308",
    "gas-pump-slash":"\f309",
    "gas-pump":"\f30a",
    "gem":"\f30b",
    "gif":"\f30c",
    "gift-card":"\f30d",
    "gift":"\f30e",
    "gifts":"\f30f",
    "gingerbread-man":"\f310",
    "glass-cheers":"\f311",
    "glass":"\f312",
    "glasses":"\f313",
    "globe-alt":"\f314",
    "globe":"\f315",
    "golf-ball":"\f316",
    "golf":"\f317",
    "graduation-cap":"\f318",
    "grape":"\f319",
    "graphic-tablet":"\f31a",
    "grid-alt":"\f31b",
    "grid":"\f31c",
    "grill":"\f31d",
    "grimace":"\f31e",
    "grin-alt":"\f31f",
    "grin-beam-sweat":"\f320",
    "grin-beam":"\f321",
    "grin-hearts":"\f322",
    "grin-squint-tears":"\f323",
    "grin-squint":"\f324",
    "grin-stars":"\f325",
    "grin-tears":"\f326",
    "grin-tongue-squint":"\f327",
    "grin-tongue-wink":"\f328",
    "grin-tongue":"\f329",
    "grin-wink":"\f32a",
    "grin":"\f32b",
    "guitar":"\f32c",
    "gym":"\f32d",
    "h":"\f32e",
    "hamburger-soda":"\f32f",
    "hamburger":"\f330",
    "hand-holding-box":"\f331",
    "hand-holding-heart":"\f332",
    "hand-holding-seeding":"\f333",
    "hand":"\f334",
    "handshake":"\f335",
    "hastag":"\f336",
    "hat-birthday":"\f337",
    "hat-chef":"\f338",
    "head-side-thinking":"\f339",
    "headphones":"\f33a",
    "headset":"\f33b",
    "heart-arrow":"\f33c",
    "heart":"\f33d",
    "heat":"\f33e",
    "helicopter-side":"\f33f",
    "highlighter":"\f340",
    "hiking":"\f341",
    "hockey-puck":"\f342",
    "hockey-sticks":"\f343",
    "home-location-alt":"\f344",
    "home-location":"\f345",
    "home":"\f346",
    "hotdog":"\f347",
    "hourglass-end":"\f348",
    "hourglass":"\f349",
    "house-flood":"\f34a",
    "hryvnia":"\f34b",
    "humidity":"\f34c",
    "hurricane":"\f34d",
    "i":"\f34e",
    "ice-cream":"\f34f",
    "ice-skate":"\f350",
    "id-badge":"\f351",
    "inbox-in":"\f352",
    "inbox-out":"\f353",
    "inbox":"\f354",
    "incognito":"\f355",
    "indent":"\f356",
    "infinity":"\f357",
    "info":"\f358",
    "interactive":"\f359",
    "interlining":"\f35a",
    "interrogation":"\f35b",
    "italic":"\f35c",
    "j":"\f35d",
    "jam":"\f35e",
    "jpg":"\f35f",
    "k":"\f360",
    "kerning":"\f361",
    "key":"\f362",
    "keyboard":"\f363",
    "keynote":"\f364",
    "kiss-beam":"\f365",
    "kiss-wink-heart":"\f366",
    "kiss":"\f367",
    "kite":"\f368",
    "knife":"\f369",
    "l":"\f36a",
    "label":"\f36b",
    "laptop":"\f36c",
    "lasso":"\f36d",
    "laugh-beam":"\f36e",
    "laugh-squint":"\f36f",
    "laugh-wink":"\f370",
    "laugh":"\f371",
    "layer-minus":"\f372",
    "layer-plus":"\f373",
    "layers":"\f374",
    "layout-fluid":"\f375",
    "leaf":"\f376",
    "lemon":"\f377",
    "letter-case":"\f378",
    "lettuce":"\f379",
    "level-down-alt":"\f37a",
    "level-down":"\f37b",
    "level-up-alt":"\f37c",
    "level-up":"\f37d",
    "life-ring":"\f37e",
    "lightbulb-dollar":"\f37f",
    "line-width":"\f380",
    "link-alt":"\f381",
    "link-slash-alt":"\f382",
    "link-slash":"\f383",
    "link":"\f384",
    "lipstick":"\f385",
    "lira-sign":"\f386",
    "list-check":"\f387",
    "list":"\f388",
    "loading":"\f389",
    "location-alt":"\f38a",
    "lock-alt":"\f38b",
    "lock":"\f38c",
    "luchador":"\f38d",
    "luggage-rolling":"\f38e",
    "m":"\f38f",
    "magic-wand":"\f390",
    "mailbox":"\f391",
    "makeup-brush":"\f392",
    "man-head":"\f393",
    "map-marker-cross":"\f394",
    "map-marker-home":"\f395",
    "map-marker-minus":"\f396",
    "map-marker-plus":"\f397",
    "map-marker":"\f398",
    "map":"\f399",
    "marker-time":"\f39a",
    "marker":"\f39b",
    "mars-double":"\f39c",
    "mars":"\f39d",
    "mask-carnival":"\f39e",
    "mask":"\f39f",
    "medicine":"\f3a0",
    "megaphone":"\f3a1",
    "meh-blank":"\f3a2",
    "meh-rolling-eyes":"\f3a3",
    "meh":"\f3a4",
    "melon":"\f3a5",
    "menu-burger":"\f3a6",
    "menu-dots-vertical":"\f3a7",
    "menu-dots":"\f3a8",
    "meteor":"\f3a9",
    "microphone-alt":"\f3aa",
    "microphone":"\f3ab",
    "mind-share":"\f3ac",
    "minus-small":"\f3ad",
    "minus":"\f3ae",
    "mobile-button":"\f3af",
    "mobile-notch":"\f3b0",
    "mobile":"\f3b1",
    "mode-landscape":"\f3b2",
    "mode-portrait":"\f3b3",
    "money-bill-wave-alt":"\f3b4",
    "money-bill-wave":"\f3b5",
    "money-check":"\f3b6",
    "money-check-edit-alt":"\f3b7",
    "money-check-edit":"\f3b8",
    "money":"\f3b9",
    "moon-stars":"\f3ba",
    "moon":"\f3bb",
    "motorcycle":"\f3bc",
    "mountains":"\f3bd",
    "mouse":"\f3be",
    "mug-alt":"\f3bf",
    "mug-hot-alt":"\f3c0",
    "mug-hot":"\f3c1",
    "mug-tea":"\f3c2",
    "mug":"\f3c3",
    "mushroom":"\f3c4",
    "music-file":"\f3c5",
    "music-alt":"\f3c6",
    "music":"\f3c7",
    "n":"\f3c8",
    "navigation":"\f3c9",
    "network-cloud":"\f3ca",
    "network":"\f3cb",
    "noodles":"\f3cc",
    "notebook":"\f3cd",
    "o":"\f3ce",
    "oil-can":"\f3cf",
    "oil-temp":"\f3d0",
    "olive-oil":"\f3d1",
    "olives":"\f3d2",
    "onion":"\f3d3",
    "opacity":"\f3d4",
    "overline":"\f3d5",
    "p":"\f3d6",
    "package":"\f3d7",
    "page-break":"\f3d8",
    "paint-roller":"\f3d9",
    "paint-brush":"\f3da",
    "palette":"\f3db",
    "pan":"\f3dc",
    "paper-plane":"\f3dd",
    "password":"\f3de",
    "pause":"\f3df",
    "paw":"\f3e0",
    "peach":"\f3e1",
    "pencil-ruler":"\f3e2",
    "pencil":"\f3e3",
    "pennant":"\f3e4",
    "people-poll":"\f3e5",
    "pepper-hot":"\f3e6",
    "pepper":"\f3e7",
    "percentage":"\f3e8",
    "pharmacy":"\f3e9",
    "phone-office":"\f3ea",
    "phone-call":"\f3eb",
    "phone-cross":"\f3ec",
    "phone-pause":"\f3ed",
    "phone-slash":"\f3ee",
    "photo-film-music":"\f3ef",
    "photo-video":"\f3f0",
    "physics":"\f3f1",
    "picnic":"\f3f2",
    "picture":"\f3f3",
    "pie":"\f3f4",
    "piece":"\f3f5",
    "piggy-bank":"\f3f6",
    "pineapple":"\f3f7",
    "ping-pong":"\f3f8",
    "pizza-slice":"\f3f9",
    "plane-alt":"\f3fa",
    "plane":"\f3fb",
    "plate":"\f3fc",
    "play-alt":"\f3fd",
    "play-pause":"\f3fe",
    "play":"\f3ff",
    "playing-cards":"\f400",
    "plus-small":"\f401",
    "plus":"\f402",
    "podium-star":"\f403",
    "podium":"\f404",
    "poker-chip":"\f405",
    "poo":"\f406",
    "popcorn":"\f407",
    "portrait":"\f408",
    "pot":"\f409",
    "pound":"\f40a",
    "power":"\f40b",
    "presentation":"\f40c",
    "print":"\f40d",
    "protractor":"\f40e",
    "pulse":"\f40f",
    "pumpkin":"\f410",
    "puzzle":"\f411",
    "pyramid":"\f412",
    "q":"\f413",
    "question-square":"\f414",
    "question":"\f415",
    "quote-right":"\f416",
    "r":"\f417",
    "racquet":"\f418",
    "radish":"\f419",
    "rainbow":"\f41a",
    "raindrops":"\f41b",
    "rec":"\f41c",
    "receipt":"\f41d",
    "record-vinyl":"\f41e",
    "rectabgle-vertical":"\f41f",
    "rectangle-horizontal":"\f420",
    "rectangle-panoramic":"\f421",
    "recycle":"\f422",
    "redo-alt":"\f423",
    "redo":"\f424",
    "reflect":"\f425",
    "refresh":"\f426",
    "registered":"\f427",
    "remove-user":"\f428",
    "reply-all":"\f429",
    "republican":"\f42a",
    "resize":"\f42b",
    "resources":"\f42c",
    "restaurant":"\f42d",
    "rewind":"\f42e",
    "rhombus":"\f42f",
    "rings-wedding":"\f430",
    "road":"\f431",
    "rocket-lunch":"\f432",
    "rocket":"\f433",
    "room-service":"\f434",
    "rotate-left":"\f435",
    "rotate-right":"\f436",
    "ruble-sign":"\f437",
    "rugby":"\f438",
    "ruler-combined":"\f439",
    "ruler-horizontal":"\f43a",
    "ruler-triangle":"\f43b",
    "ruler-vertical":"\f43c",
    "running":"\f43d",
    "rupee-sign":"\f43e",
    "rv":"\f43f",
    "s":"\f440",
    "sack-dollar":"\f441",
    "sack":"\f442",
    "sad-cry":"\f443",
    "sad-tear":"\f444",
    "sad":"\f445",
    "salad":"\f446",
    "salt-pepper":"\f447",
    "sandwich":"\f448",
    "sauce":"\f449",
    "sausage":"\f44a",
    "scale":"\f44b",
    "school-bus":"\f44c",
    "school":"\f44d",
    "scissors":"\f44e",
    "screen":"\f44f",
    "search-dollar":"\f450",
    "search-location":"\f451",
    "search-alt":"\f452",
    "search-heart":"\f453",
    "search":"\f454",
    "security":"\f455",
    "sensor-alert":"\f456",
    "sensor-fire":"\f457",
    "sensor-on":"\f458",
    "sensor-smoke":"\f459",
    "sensor":"\f45a",
    "settings-sliders":"\f45b",
    "settings":"\f45c",
    "share":"\f45d",
    "shekel-sign":"\f45e",
    "shield-check":"\f45f",
    "shield-exclamation":"\f460",
    "shield-interrogation":"\f461",
    "shield-plus":"\f462",
    "shield":"\f463",
    "ship-side":"\f464",
    "ship":"\f465",
    "shop":"\f466",
    "shopping-basket":"\f467",
    "shopping-bag-add":"\f468",
    "shopping-bag":"\f469",
    "shopping-cart-add":"\f46a",
    "shopping-cart-check":"\f46b",
    "shopping-cart":"\f46c",
    "shrimp":"\f46d",
    "shuffle":"\f46e",
    "shuttle-van":"\f46f",
    "shuttlecock":"\f470",
    "sign-in-alt":"\f471",
    "sign-out-alt":"\f472",
    "signal-alt-1":"\f473",
    "signal-alt-2":"\f474",
    "signal-alt":"\f475",
    "skateboard":"\f476",
    "skating":"\f477",
    "skewer":"\f478",
    "ski-jump":"\f479",
    "ski-lift":"\f47a",
    "skiing-nordic":"\f47b",
    "skiing":"\f47c",
    "sledding":"\f47d",
    "sleigh":"\f47e",
    "smartphone":"\f47f",
    "smile-beam":"\f480",
    "smile-wink":"\f481",
    "smile":"\f482",
    "smog":"\f483",
    "smoke":"\f484",
    "snow-blowing":"\f485",
    "snowboarding":"\f486",
    "snowflake":"\f487",
    "snowflakes":"\f488",
    "snowmobile":"\f489",
    "snowplow":"\f48a",
    "soap":"\f48b",
    "social-network":"\f48c",
    "sort-alpha-down-alt":"\f48d",
    "sort-alpha-down":"\f48e",
    "sort-alpha-up-alt":"\f48f",
    "sort-alpha-up":"\f490",
    "sort-alt":"\f491",
    "sort-amount-down-alt":"\f492",
    "sort-amount-down":"\f493",
    "sort-amount-up-alt":"\f494",
    "sort-amount-up":"\f495",
    "sort-down":"\f496",
    "sort-numeric-down-alt":"\f497",
    "sort-numeric-down":"\f498",
    "sort":"\f499",
    "soup":"\f49a",
    "spa":"\f49b",
    "space-shuttle":"\f49c",
    "spade":"\f49d",
    "sparkles":"\f49e",
    "speaker":"\f49f",
    "sphere":"\f4a0",
    "spinner":"\f4a1",
    "spoon":"\f4a2",
    "sport":"\f4a3",
    "square-root":"\f4a4",
    "square":"\f4a5",
    "stamp":"\f4a6",
    "star-octogram":"\f4a7",
    "star":"\f4a8",
    "starfighter":"\f4a9",
    "stars":"\f4aa",
    "stats":"\f4ab",
    "steak":"\f4ac",
    "steering-wheel":"\f4ad",
    "stethoscope":"\f4ae",
    "sticker":"\f4af",
    "stop":"\f4b0",
    "stopwatch":"\f4b1",
    "store-alt":"\f4b2",
    "strawberry":"\f4b3",
    "subtitles":"\f4b4",
    "subway":"\f4b5",
    "suitcase-alt":"\f4b6",
    "summer":"\f4b7",
    "sun":"\f4b8",
    "sunrise-alt":"\f4b9",
    "sunrise":"\f4ba",
    "sunset":"\f4bb",
    "surfing":"\f4bc",
    "surprise":"\f4bd",
    "sushi":"\f4be",
    "swimmer":"\f4bf",
    "sword":"\f4c0",
    "symbol":"\f4c1",
    "syringe":"\f4c2",
    "t":"\f4c3",
    "tablet":"\f4c4",
    "tachometer-alt-average":"\f4c5",
    "tachometer-alt-fastest":"\f4c6",
    "tachometer-alt-slow":"\f4c7",
    "tachometer-alt-slowest":"\f4c8",
    "tachometer-average":"\f4c9",
    "tachometer-fast":"\f4ca",
    "tachometer-fastest":"\f4cb",
    "tachometer-slow":"\f4cc",
    "tachometer-slowest":"\f4cd",
    "tachometer":"\f4ce",
    "taco":"\f4cf",
    "tags":"\f4d0",
    "tally":"\f4d1",
    "target":"\f4d2",
    "taxi":"\f4d3",
    "temperature-down":"\f4d4",
    "temperature-frigid":"\f4d5",
    "temperature-high":"\f4d6",
    "temperature-low":"\f4d7",
    "temperature-up":"\f4d8",
    "tenge":"\f4d9",
    "tennis":"\f4da",
    "terrace":"\f4db",
    "test-tube":"\f4dc",
    "test":"\f4dd",
    "text-check":"\f4de",
    "text-slash":"\f4df",
    "text":"\f4e0",
    "thermometer-half":"\f4e1",
    "thumbtack":"\f4e2",
    "thunderstorm-moon":"\f4e3",
    "thunderstorm-sun":"\f4e4",
    "thunderstorm":"\f4e5",
    "ticket":"\f4e6",
    "time-quarter-past":"\f4e7",
    "time-add":"\f4e8",
    "time-check":"\f4e9",
    "time-delete":"\f4ea",
    "time-fast":"\f4eb",
    "time-forward-sixty":"\f4ec",
    "time-forward-ten":"\f4ed",
    "time-forward":"\f4ee",
    "time-half-past":"\f4ef",
    "time-oclock":"\f4f0",
    "time-past":"\f4f1",
    "time-quarter-to":"\f4f2",
    "time-twenty-four":"\f4f3",
    "tire-flat":"\f4f4",
    "tire-pressure-warning":"\f4f5",
    "tire-rugged":"\f4f6",
    "tire":"\f4f7",
    "tired":"\f4f8",
    "tomato":"\f4f9",
    "tool-box":"\f4fa",
    "tool-crop":"\f4fb",
    "tool-marquee":"\f4fc",
    "tooth":"\f4fd",
    "tornado":"\f4fe",
    "tractor":"\f4ff",
    "trailer":"\f500",
    "train-side":"\f501",
    "train":"\f502",
    "tram":"\f503",
    "transform":"\f504",
    "trash":"\f505",
    "treasure-chest":"\f506",
    "treatment":"\f507",
    "tree-christmas":"\f508",
    "tree":"\f509",
    "triangle":"\f50a",
    "trophy":"\f50b",
    "truck-container":"\f50c",
    "truck-couch":"\f50d",
    "truck-loading":"\f50e",
    "truck-monster":"\f50f",
    "truck-moving":"\f510",
    "truck-pickup":"\f511",
    "truck-plow":"\f512",
    "truck-ramp":"\f513",
    "truck-side":"\f514",
    "tty":"\f515",
    "turkey":"\f516",
    "tv-music":"\f517",
    "typewriter":"\f518",
    "u":"\f519",
    "umbrella":"\f51a",
    "underline":"\f51b",
    "undo-alt":"\f51c",
    "undo":"\f51d",
    "unlock":"\f51e",
    "upload":"\f51f",
    "usb-pendrive":"\f520",
    "usd-circle":"\f521",
    "usd-square":"\f522",
    "user-add":"\f523",
    "user-time":"\f524",
    "user":"\f525",
    "users-alt":"\f526",
    "users":"\f527",
    "utensils":"\f528",
    "v":"\f529",
    "vector-alt":"\f52a",
    "vector":"\f52b",
    "venus-double":"\f52c",
    "venus-mars":"\f52d",
    "venus":"\f52e",
    "video-arrow-down-left":"\f52f",
    "video-arrow-up-right":"\f530",
    "video-camera-alt":"\f531",
    "video-camera":"\f532",
    "video-plus":"\f533",
    "video-slash":"\f534",
    "volcano":"\f535",
    "volleyball":"\f536",
    "volume":"\f537",
    "w":"\f538",
    "wagon-covered":"\f539",
    "wallet":"\f53a",
    "water-bottle":"\f53b",
    "water-lower":"\f53c",
    "water-rise":"\f53d",
    "water":"\f53e",
    "watermelon":"\f53f",
    "wheat":"\f540",
    "wheelchair":"\f541",
    "whistle":"\f542",
    "wifi-alt":"\f543",
    "wind-warning":"\f544",
    "wind":"\f545",
    "windsock":"\f546",
    "woman-head":"\f547",
    "world":"\f548",
    "x":"\f549",
    "y":"\f54a",
    "yen":"\f54b",
    "z":"\f54c",
    "zoom-in":"\f54d",
    "zoom-out":"\f54e",


    >>> BRANDS ICONS ($uicons-brands-fonts)
    "3m":"\f101",
    "500px":"\f102",
    "abbot-laboratories":"\f103",
    "accusoft":"\f104",
    "acrobat":"\f105",
    "adobe":"\f106",
    "aecom":"\f107",
    "aero":"\f108",
    "after-effects":"\f109",
    "airbnb":"\f10a",
    "algolia":"\f10b",
    "amd":"\f10c",
    "american-express":"\f10d",
    "android":"\f10e",
    "animate":"\f10f",
    "app-store-ios":"\f110",
    "apple-pay":"\f111",
    "apple":"\f112",
    "artstation":"\f113",
    "astrazeneca":"\f114",
    "asus":"\f115",
    "atandt":"\f116",
    "atlassian":"\f117",
    "atom":"\f118",
    "audition":"\f119",
    "behance":"\f11a",
    "bitcoin":"\f11b",
    "blackberry":"\f11c",
    "blogger":"\f11d",
    "bluetooth":"\f11e",
    "bootstrap":"\f11f",
    "bridgestone":"\f120",
    "burger-king":"\f121",
    "c":"\f122",
    "capture":"\f123",
    "cc-apple-pay":"\f124",
    "cc-diners-club":"\f125",
    "cc-visa":"\f126",
    "cc-amazon-pay":"\f127",
    "centos":"\f128",
    "character":"\f129",
    "chromecast":"\f12a",
    "cloudflare":"\f12b",
    "confluence":"\f12c",
    "creative-commons-by":"\f12d",
    "creative-commons-nc-eu":"\f12e",
    "creative-commons-nc-jp":"\f12f",
    "creative-commons-nc":"\f130",
    "creative-commons-nd":"\f131",
    "creative-commons-pd-alt":"\f132",
    "creative-commons-pd":"\f133",
    "creative-commons-remix":"\f134",
    "creative-commons-sa":"\f135",
    "creative-commons-sampling-plus":"\f136",
    "creative-commons-sampling":"\f137",
    "creative-commons-share":"\f138",
    "creative-commons-zero":"\f139",
    "creative-commons":"\f13a",
    "css3-alt":"\f13b",
    "css3":"\f13c",
    "dailymotion":"\f13d",
    "deezer":"\f13e",
    "delphi":"\f13f",
    "dev":"\f140",
    "devianart":"\f141",
    "digg":"\f142",
    "dimension":"\f143",
    "discord":"\f144",
    "docker":"\f145",
    "dribbble":"\f146",
    "dropbox":"\f147",
    "drupal":"\f148",
    "ebay":"\f149",
    "elementor":"\f14a",
    "ethereum":"\f14b",
    "etsy":"\f14c",
    "evernote":"\f14d",
    "facebook-messenger":"\f14e",
    "facebook":"\f14f",
    "fedex":"\f150",
    "figma":"\f151",
    "firefox-browser":"\f152",
    "firefox":"\f153",
    "flickr":"\f154",
    "flipboard":"\f155",
    "fonts":"\f156",
    "foursquare":"\f157",
    "fresco":"\f158",
    "github":"\f159",
    "gitlab":"\f15a",
    "goodreads":"\f15b",
    "google":"\f15c",
    "haskell":"\f15d",
    "hbo":"\f15e",
    "hotjar":"\f15f",
    "html5":"\f160",
    "huawei":"\f161",
    "hubspot":"\f162",
    "ibm":"\f163",
    "illustrator-draw":"\f164",
    "illustrator":"\f165",
    "imdb":"\f166",
    "incopy":"\f167",
    "indesign":"\f168",
    "instagram":"\f169",
    "intel":"\f16a",
    "invision":"\f16b",
    "itunes":"\f16c",
    "janseen":"\f16d",
    "java":"\f16e",
    "jcb":"\f16f",
    "jira":"\f170",
    "johnson-and-johnson":"\f171",
    "joomla":"\f172",
    "js":"\f173",
    "kickstarter":"\f174",
    "line":"\f175",
    "linkedin":"\f176",
    "lisp":"\f177",
    "mailchimp":"\f178",
    "marriott-international":"\f179",
    "mcdonalds":"\f17a",
    "media-encoder":"\f17b",
    "medium":"\f17c",
    "meta":"\f17d",
    "microsoft-edge":"\f17e",
    "microsoft-explorer":"\f17f",
    "microsoft":"\f180",
    "mysql":"\f181",
    "napster":"\f182",
    "nestle":"\f183",
    "netflix":"\f184",
    "node-js":"\f185",
    "nvidia":"\f186",
    "oracle":"\f187",
    "patreon":"\f188",
    "paypal":"\f189",
    "pfizer":"\f18a",
    "photoshop-camera":"\f18b",
    "photoshop-express":"\f18c",
    "photoshop-lightroom-classic":"\f18d",
    "photoshop-lightroom":"\f18e",
    "photoshop":"\f18f",
    "php":"\f190",
    "pinterest":"\f191",
    "postgre":"\f192",
    "premiere-rush":"\f193",
    "premiere":"\f194",
    "product-hunt":"\f195",
    "python":"\f196",
    "raspberry-pi":"\f197",
    "reddit":"\f198",
    "samsung":"\f199",
    "sap":"\f19a",
    "sass":"\f19b",
    "shopify":"\f19c",
    "siemens":"\f19d",
    "sketch":"\f19e",
    "skype":"\f19f",
    "slack":"\f1a0",
    "snapchat":"\f1a1",
    "sony":"\f1a2",
    "soundcloud":"\f1a3",
    "spark":"\f1a4",
    "spotify":"\f1a5",
    "starbucks":"\f1a6",
    "stock":"\f1a7",
    "stripe":"\f1a8",
    "substance-3d-designer":"\f1a9",
    "substance-3d-painter":"\f1aa",
    "substance-3d-sampler":"\f1ab",
    "substance-3d-stager":"\f1ac",
    "swift":"\f1ad",
    "t-mobile":"\f1ae",
    "telegram":"\f1af",
    "tencent":"\f1b0",
    "the-home-depot":"\f1b1",
    "tik-tok":"\f1b2",
    "trello":"\f1b3",
    "tripadvisor":"\f1b4",
    "tumblr":"\f1b5",
    "twitch":"\f1b6",
    "twitter":"\f1b7",
    "typescript":"\f1b8",
    "uber":"\f1b9",
    "ubuntu":"\f1ba",
    "unilever":"\f1bb",
    "unity":"\f1bc",
    "unsplash":"\f1bd",
    "ups":"\f1be",
    "usaa":"\f1bf",
    "verizon":"\f1c0",
    "vimeo":"\f1c1",
    "visa":"\f1c2",
    "visual-basic":"\f1c3",
    "vk":"\f1c4",
    "walmart":"\f1c5",
    "whatsapp":"\f1c6",
    "wikipedia":"\f1c7",
    "windows":"\f1c8",
    "wix":"\f1c9",
    "wordpress":"\f1ca",
    "xd":"\f1cb",
    "xing":"\f1cc",
    "yahoo":"\f1cd",
    "yandex":"\f1ce",
    "yelp":"\f1cf",
    "youtube":"\f1d0",
    "zoom":"\f1d1",
*/
/* >>> GENERAL UICONS 
------------------------------ */
@font-face {
  font-family: "uicons-regular-rounded";
  src: url("/themes/cezam/fonts/uicons-regular-rounded.woff2") format("woff2"), url("/themes/cezam/fonts/uicons-regular-rounded.woff") format("woff");
  font-display: swap;
}
.accordion__header::before, .fig-angle-down::before {
  content: "\f11a";
}

.scroll-top::before, .fig-arrow-to-top::before {
  content: "\f157";
}

.fig-arrow-right::before {
  content: "\f14b";
}

.widget--checkbox .widget__content::after, .fig-bell::before {
  content: "\f183";
}

.widget--checkbox input:checked + .widget__content::after, .notification--secondary::before, .fig-bell-ring::before {
  content: "\f181";
}

.fig-building::before {
  content: "\f1ab";
}

.fig-calendar::before {
  content: "\f1bf";
}

.fig-calendar-check::before {
  content: "\f1b7";
}

.notification--danger::before, .fig-calendar-exclamation::before {
  content: "\f1b9";
}

.fig-calendar-minus::before {
  content: "\f1bc";
}

.fig-calendar-plus::before {
  content: "\f1be";
}

.event-participant__option--with .option-label::before, .checkbox-switch::after, .checkbox-group .label::before, .fig-check::before {
  content: "\f1fb";
}

.fig-checkbox::before {
  content: "\f1fc";
}

.event-card--feat .event-card__hour::before, .fig-clock::before {
  content: "\f231";
}

.event-participant__option--without .option-label::before, .alert-container .alert__close::before, .fig-cross::before {
  content: "\f27c";
}

.fig-dashboard::before {
  content: "\f289";
}

.fig-document::before {
  content: "\f2a5";
}

.fig-edit::before {
  content: "\f2b5";
}

.fig-envelope::before {
  content: "\f2c2";
}

.fig-euro::before {
  content: "\f2c4";
}

.see-password-wrap .see-password.active::before, .fig-eye::before {
  content: "\f2ce";
}

.see-password-wrap .see-password::before, .fig-eye-crossed::before {
  content: "\f2cc";
}

.fig-globe::before {
  content: "\f315";
}

.fig-grid::before {
  content: "\f31c";
}

.fig-id-badge::before {
  content: "\f351";
}

.fig-marker::before {
  content: "\f39b";
}

.event-card__location::before, .fig-map-marker::before {
  content: "\f398";
}

.fig-lock::before {
  content: "\f38c";
}

.fig-list::before {
  content: "\f388";
}

.fig-phone-call::before {
  content: "\f3eb";
}

.fig-picture::before {
  content: "\f3f3";
}

.fig-search::before {
  content: "\f454";
}

.member-card__verified::after, .fig-shield-check::before {
  content: "\f45f";
}

.fig-sign-out-alt::before {
  content: "\f472";
}

.fig-trash::before {
  content: "\f505";
}

.event-participant__icon::before, .identity-card::after, .fig-user::before {
  content: "\f525";
}

.fig-user-add::before {
  content: "\f523";
}

.fig-users::before {
  content: "\f527";
}

/* >>> BRANDS UICONS 
------------------------------ */
@font-face {
  font-family: "uicons-brands";
  src: url("/themes/cezam/fonts/uicons-brands.woff2") format("woff2"), url("/themes/cezam/fonts/uicons-brands.woff") format("woff");
  font-display: swap;
}
.fib-facebook::before {
  content: "\f14f";
}

.fib-instagram::before {
  content: "\f169";
}

.fib-linkedin::before {
  content: "\f176";
}

.fib-twitter::before {
  content: "\f1b7";
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}
@media (max-width: 74.99em) {
  html {
    font-size: 50%;
  }
}

body {
  color: var(--color-grey);
  font-family: var(--font-family-1st);
  font-size: 1.8rem;
  line-height: 1.6;
}
@media (max-width: 74.99em) {
  body {
    font-size: 2rem;
  }
}
body.preload * {
  transition: none !important;
}

a {
  color: currentColor;
  text-decoration: none;
  transition: 0.4s;
}
a:hover, a:focus {
  color: var(--color-primary);
}

img {
  display: inline-block;
  max-width: 100%;
}

ul {
  list-style: none;
}

::selection {
  background-color: var(--color-primary);
  color: #fff;
}

.checkbox-group {
  cursor: pointer;
  display: inline-block;
}
.checkbox-group input {
  font-family: var(--font-family-1st);
  display: none;
}
.checkbox-group input:checked ~ .label {
  color: var(--color-primary);
}
.checkbox-group input:checked ~ .label::before {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.checkbox-group .label {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  transition: 0.4s;
}
.checkbox-group .label::before {
  background-color: #fff;
  border: 2px solid rgba(54, 84, 129, 0.15);
  color: #fff;
  display: inline-block;
  margin-right: 1rem;
  padding: 2px;
  transition: 0.4s;
}

.checkbox-switch-group {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.checkbox-switch-group__label {
  cursor: pointer;
  font-weight: bold;
}

.checkbox-switch {
  background-color: #d8e1e3;
  border-radius: 99rem;
  border: 2px solid rgba(50, 50, 50, 0.2);
  cursor: pointer;
  display: inline-block;
  position: relative;
  height: 2.6rem;
  width: 5rem;
  transition: 0.4s;
}
.checkbox-switch::before {
  content: "";
  background-color: #fff;
  border-radius: 99rem;
  position: absolute;
  height: 2.6rem;
  width: 2.6rem;
  top: 50%;
  left: -0.2rem;
  transform: translateY(-50%);
  transition: 0.4s;
}
.checkbox-switch::after {
  color: var(--color-primary);
  font-size: 1.4rem;
  line-height: 1;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0.4rem;
  transform: translateY(-50%) rotate(180deg);
  transition: 0.4s;
}

.checkbox-switch-input {
  display: none;
}
.checkbox-switch-input:checked + .checkbox-switch, .checkbox-switch-input:checked + * .checkbox-switch {
  background-color: var(--color-primary);
}
.checkbox-switch-input:checked + .checkbox-switch::before, .checkbox-switch-input:checked + * .checkbox-switch::before {
  transform: translate(2.6rem, -50%);
}
.checkbox-switch-input:checked + .checkbox-switch::after, .checkbox-switch-input:checked + * .checkbox-switch::after {
  opacity: 1;
  transform: translate(2.6rem, -50%) rotate(0);
}

.checkbox-button__content {
  flex-flow: row wrap;
  border: 3px solid var(--color-grey-3);
  border-radius: 99rem;
  color: var(--color-tertiary);
  cursor: pointer;
  padding: 1rem 2.5rem;
  transition: 0.4s;
}
.checkbox-button__label {
  font-weight: bold;
  transition: 0.4s;
}
.checkbox-button__icon {
  color: var(--color-primary);
  margin-right: 1rem;
  transition: 0.4s;
}
.checkbox-button__input {
  display: none;
}
.checkbox-button__input:checked + .checkbox-button__content {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: #fff;
}
.checkbox-button__input:checked + .checkbox-button__content > * {
  color: #fff;
}
.checkbox-button--2 .checkbox-button__icon {
  color: var(--color-secondary);
}
.checkbox-button--2 .checkbox-button__input:checked + .checkbox-button__content {
  border-color: var(--color-secondary);
  background-color: var(--color-secondary);
}

.form-group-packed {
  margin-bottom: 2rem;
  position: relative;
}
.form-group-packed .fgp-wrap {
  position: relative;
}
.form-group-packed label {
  font-size: 1.4rem;
  position: absolute;
  top: 0.75rem;
  left: 3.5rem;
  transition: 0.4s;
}
.form-group-packed i {
  color: var(--color-grey);
  font-size: 2rem;
  line-height: 1;
  position: absolute;
  top: 2.2rem;
  right: 3rem;
  transition: 0.4s;
}
.form-group-packed input {
  background-color: #fff;
  border-radius: 99rem;
  border: 2px solid rgba(54, 84, 129, 0.15);
  color: var(--color-tertiary);
  display: block;
  font-size: 1.8rem;
  font-weight: 700;
  padding: 3rem 2rem 1rem 3rem;
  width: 100%;
  transition: 0.4s;
}
.form-group-packed input::placeholder {
  font-weight: normal;
}
.form-group-packed input:focus {
  border-color: var(--color-primary);
  box-shadow: none;
  outline: none;
}
.form-group-packed input:focus ~ label, .form-group-packed input:focus ~ i {
  color: var(--color-primary);
}

.form-group {
  margin-bottom: 2rem;
}

.form-group-sm {
  margin-bottom: 1rem;
}

.fieldset {
  border: none;
  margin-bottom: 3rem;
}
.fieldset__legend, .stable__head {
  background-color: var(--color-secondary);
  border-radius: 99rem;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 2.2rem;
  gap: 0.5rem 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0.5rem 2.5rem;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  width: 100%;
}

.input-1, .select-nice, .input-2, .select-nice--2 {
  background-color: #fff;
  border: 2px solid var(--color-primary-white-2);
  border-radius: 1rem;
  color: var(--color-tertiary);
  display: block;
  font-family: var(--font-family-1st);
  font-size: inherit;
  font-weight: bold;
  padding: 0.75em 1.25em;
  width: 100%;
  transition: 0.4s;
}
.input-1:hover, .select-nice:hover, .input-2:hover, .select-nice--2:hover, .input-1:focus-visible, .select-nice:focus-visible, .input-2:focus-visible, .select-nice--2:focus-visible {
  border-color: var(--color-primary);
  outline: none;
}

.input-2, .select-nice--2 {
  border: 3px solid var(--color-grey-4);
}
.input-2:hover, .select-nice--2:hover, .input-2:focus-visible, .select-nice--2:focus-visible {
  border-color: var(--color-primary-white-2);
}

.input-group {
  display: flex;
  align-items: flex-start;
}
.input-group__label {
  background-color: var(--color-primary);
  border-radius: 1rem;
  color: #fff;
  margin-right: 1.5rem;
  height: 6rem;
  width: 6rem;
}
.input-group__label--facebook {
  background-color: var(--color-facebook);
}
.input-group__label--instagram {
  background-color: var(--color-instagram);
}
.input-group__label--linkedin {
  background-color: var(--color-linkedin);
}
.input-group__label--twitter {
  background-color: var(--color-twitter);
}
.input-group__inputs {
  flex-grow: 1;
  margin-top: 0.25rem;
}

.input-length-1 {
  width: 45rem;
}

.input-icon {
  position: relative;
}
.input-icon__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.input-icon__icon i {
  color: var(--color-primary);
  font-size: 1.7rem;
}
.input-icon__icon:is(button) {
  background-color: var(--color-primary);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;
  transition: 0.4s;
}
.input-icon__icon:is(button) i {
  color: #fff;
}
.input-icon__icon:is(button):hover {
  background-color: var(--color-secondary);
}

.label {
  display: block;
  font-weight: 400;
}

.see-password-wrap {
  position: relative;
}
.see-password-wrap .see-password {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
}
.see-password-wrap .see-password.active::before {
  color: var(--color-primary);
}

.nice-select {
  cursor: pointer;
  position: relative;
}
.nice-select-dropdown {
  background-color: #fff;
  cursor: pointer;
  overflow-y: scroll;
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 30rem;
  min-width: 25rem;
  width: 100%;
  transform: translateY(-1rem);
  transition: 0.4s;
  z-index: 10;
}
.nice-select-dropdown .list .option {
  padding: 1.5rem;
  transition: 0.4s;
}
.nice-select-dropdown .list .option:hover, .nice-select-dropdown .list .option:focus-visible {
  background-color: var(--color-primary) !important;
  color: #fff !important;
}
.nice-select-dropdown .list .option.selected {
  background-color: var(--color-primary-2);
  color: #fff;
}
.nice-select.open .nice-select-dropdown {
  transform: translateY(1rem);
}

.select-nice {
  line-height: 1.2;
  padding-right: 4rem;
  position: relative;
}
.select-nice::before {
  content: "►";
  color: var(--color-primary);
  display: inline-block;
  font-size: 0.8em;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}
.required {
  position: relative;
}
.required::after {
  content: "*";
  color: var(--color-danger);
  font-weight: bold;
  display: inline-block;
  margin-left: 0.5rem;
}

.notif,
.invalid-feedback,
.outcome .error.visible {
  display: inline-block;
  font-size: 15px;
  margin-top: 4px;
  padding-left: 25px;
  position: relative;
}
.notif::before, .notif::after,
.invalid-feedback::before,
.invalid-feedback::after,
.outcome .error.visible::before,
.outcome .error.visible::after {
  content: "";
  border-radius: 100px;
  position: absolute;
  left: 6px;
  top: 7px;
  height: 6px;
  width: 6px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notif::after,
.invalid-feedback::after,
.outcome .error.visible::after {
  top: 10px;
  left: 9px;
}
.notif::before,
.invalid-feedback::before,
.outcome .error.visible::before {
  -webkit-animation: growTransp 1s infinite backwards;
  animation: growTransp 1s infinite backwards;
}

.notif-success, .notif--success {
  color: var(--color-success);
}
.notif-success::before, .notif-success::after, .notif--success::before, .notif--success::after {
  background-color: var(--color-success);
}

.notif-danger, .notif--danger,
.invalid-feedback,
.outcome .error.visible {
  color: var(--color-danger);
}
.notif-danger::before, .notif-danger::after, .notif--danger::before, .notif--danger::after,
.invalid-feedback::before,
.invalid-feedback::after,
.outcome .error.visible::before,
.outcome .error.visible::after {
  background-color: var(--color-danger);
}

.notif-form-success {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notif-form-success svg {
  flex-shrink: 0;
  margin-right: 15px;
  height: 50px;
  width: 50px;
}
.notif-form-success svg > * {
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
  stroke: var(--color-success);
}
.notif-form-success svg > *:first-child {
  animation: stroke-appear-1 0.75s 0.5s cubic-bezier(0.33, 1, 0.68, 1) forwards;
}
.notif-form-success svg > *:last-child {
  animation: stroke-appear-1 1s 1s cubic-bezier(0.5, 1, 0.89, 1) forwards;
}
.notif-form-success span {
  color: var(--color-success);
  font-size: 18px;
  animation: fade-in 0.5s ease-in-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes grow-transp {
  from {
    opacity: 0.8;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(4);
  }
}
@keyframes stroke-appear-1 {
  from {
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
  }
  to {
    stroke-dasharray: 1200;
    stroke-dashoffset: 0;
  }
}
.block-quote {
  margin: 0 auto;
  position: relative;
  isolation: isolate;
  text-align: center;
  max-width: 110rem;
}
.block-quote::before {
  content: "";
  background: url("/storage/icones/bubble-right-quote-primary.svg") center center;
  background-size: cover;
  opacity: 0.1;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 150px;
  width: 150px;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.block-quote p {
  font-size: 2.2rem;
  font-style: italic;
  margin-bottom: 1rem;
}

.profile-image {
  background-color: #fff;
  border-radius: 999rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 20rem;
  height: 20rem;
}
.profile-image img {
  width: 100%;
}
.profile-image .mt-overlay {
  background-color: rgba(22, 38, 63, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s;
}
.profile-image .mt-overlay span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.profile-image .mt-overlay span i {
  color: #fff;
  font-size: 2.5rem;
}
.dynacontent > *:not(:last-child) {
  margin-bottom: 1.5rem;
}
.dynacontent--md {
  font-size: 2rem;
}

.hr-1 {
  border: none;
  border-top: 3px dashed var(--color-tertiary-4);
  margin: 2rem 0;
}

.mt-card-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  mask-image: none !important;
  overflow: visible;
  height: 100%;
  width: 100%;
}

.picture-update {
  border-radius: 1.5rem;
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  overflow: hidden;
}
.picture-update__image {
  border-radius: 999rem;
  display: block;
  margin-bottom: 1.5rem;
  max-width: 20rem;
}
.picture-update__input {
  display: none;
}

.row-separator {
  border-top: 3px dashed var(--color-tertiary-4);
  padding-top: 2rem;
}

.text-danger {
  color: var(--color-danger);
  font-weight: bold;
}

.text-success {
  color: var(--color-success);
  font-weight: bold;
}

h3, .h3 {
  height: auto;
}

.link-feat-secondary {
  color: var(--color-secondary);
  font-size: 2rem;
  letter-spacing: 0.05em;
}
.link-feat-secondary:hover::after, .link-feat-secondary:focus::after {
  background-color: var(--color-secondary);
}

.para > p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.text-informative {
  color: var(--color-primary);
  font-size: 2rem;
  font-style: italic;
}

.text-note {
  color: var(--color-grey-6);
  font-size: 2rem;
  font-style: italic;
}

.accordion__item {
  margin-bottom: 2rem;
}
.accordion__header {
  background-color: var(--color-tertiary);
  border-radius: 50rem;
  color: #fff;
  cursor: pointer;
  padding: 2rem 2.5rem 2rem 2.5rem;
  position: relative;
}
.accordion__header::before {
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 3.5rem;
  transform: translateY(-50%);
}
.accordion__title {
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.accordion__title i {
  background-color: var(--color-tertiary-3);
  border-radius: 99rem;
  color: var(--color-tertiary-4);
  font-size: 1.8rem;
  line-height: 1;
  margin-right: 1.5rem;
  text-align: center;
  height: 4rem;
  width: 4rem;
}
.accordion__content {
  padding: 0;
  overflow-y: hidden;
  height: 0;
}
.accordion__item.active .accordion__header {
  background-color: var(--color-primary);
}
.accordion__item.active .accordion__header::before {
  transform: translateY(-50%) rotate(-180deg);
}
.accordion__item.active .accordion__title i {
  background-color: var(--color-primary-white-2);
  color: var(--color-primary);
}
.accordion__item.active .accordion__content {
  padding: 3rem;
  height: auto;
}

.alert-container {
  position: fixed;
  top: 20%;
  right: 5rem;
  z-index: 9999999999;
  max-width: 50rem;
  width: 100%;
}
.alert-container .alert {
  gap: 2rem;
  margin-bottom: 1.5rem;
  padding: 2rem;
  position: relative;
  width: 100%;
}
.alert-container .alert::after {
  content: "";
  background: url("/storage/svg/african-line-overlay-1-white.svg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 3rem;
  z-index: 0;
  position: absolute;
  left: 50%;
  bottom: 0.5rem;
  transform: translateX(-50%);
  opacity: 0.2;
}
.alert-container .alert--new {
  animation: fadeInUp 0.4s ease-in-out forwards;
}
.alert-container .alert--danger, .alert-container .alert--error {
  background-color: var(--color-danger);
  color: #fff;
}
.alert-container .alert--success {
  background-color: var(--color-success);
  color: #fff;
}
.alert-container .alert__close {
  cursor: pointer;
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.alert-container .alert__icon {
  font-size: 4rem;
}
.alert-container .alert__message {
  font-size: 2rem;
}
.alert-container .alert__progress {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 0%;
  animation-name: progress;
  animation-timing-function: linear;
}

.banner {
  background-color: var(--color-tertiary);
  min-height: 85rem;
}
.banner .container {
  padding-top: 125px;
  grid-template-columns: 55% 45%;
  position: relative;
  z-index: 1;
}
.banner .container .left {
  padding-top: 50px;
}
.banner__overlays {
  background: url("/storage/pro-crowd.svg") -10% 155% no-repeat;
  background-size: 110rem;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.banner__overlays::before {
  opacity: 0.35;
}
.banner__suptitle {
  color: #fff;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.banner__title {
  color: #fff;
  font-weight: normal;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.banner__breadcrumb .breadcrumb {
  list-style: none;
}
.banner__breadcrumb .breadcrumb li {
  color: #fff;
}
.banner__breadcrumb .breadcrumb li:not(:last-child) {
  position: relative;
}
.banner__breadcrumb .breadcrumb li:not(:last-child)::after {
  content: "►";
  color: var(--color-primary);
  display: inline-block;
  font-size: 0.8em;
  padding: 0 1rem;
}
.banner__breadcrumb .breadcrumb li:last-child {
  opacity: 0.7;
}

.banner--pageHeader {
  height: 35rem;
  min-height: 0;
}
.banner--pageHeader::after {
  display: none;
}
.banner--pageHeader .container {
  padding-top: 11.5rem;
  display: block;
}
.banner--pageHeader .banner__overlays {
  background: none;
}
.banner--pageHeader .banner__title {
  margin-bottom: 1rem;
}

.banner--page404 .container {
  display: block;
}
.banner--page404 .title-404 {
  position: relative;
}
.banner--page404 .title-404::after {
  left: 50%;
  transform: translateX(-50%);
}

.blocks-overlay__item {
  background-color: var(--color-grey-3);
  border-top-left-radius: 99rem;
  border-bottom-left-radius: 99rem;
  padding: 2rem 2rem 2rem 7rem;
  position: relative;
  margin-bottom: 1.5rem;
}
.blocks-overlay__item::before {
  content: "";
  background-color: var(--color-primary);
  position: absolute;
  top: 3.75rem;
  right: calc(100% - 2.5rem);
  height: 2px;
  width: 999rem;
}
.blocks-overlay__item::after {
  content: "";
  background-color: var(--color-grey-3);
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 999rem;
}
.blocks-overlay__title {
  color: var(--color-primary);
  margin-bottom: 0;
}
.blocks-overlay__item--primary {
  background-color: var(--color-primary);
}
.blocks-overlay__item--primary::before {
  display: none;
}
.blocks-overlay__item--primary::after {
  background-color: var(--color-primary);
}
.blocks-overlay__item--primary > * {
  color: #fff;
}
.blocks-overlay__item--primary p {
  opacity: 0.8;
}

.bubble-text {
  background-color: var(--color-primary);
  border-radius: 999rem;
  color: #fff;
  font-size: 2.8rem;
  line-height: 1.3;
  padding-top: 7rem;
  text-align: center;
  height: 37rem;
  width: 37rem;
  text-transform: uppercase;
}
.bubble-text span {
  display: block;
}
.bubble-text span:nth-child(1) {
  font-size: 1.2em;
  font-weight: bold;
}
.bubble-text span:nth-child(2) {
  font-size: 0.7em;
}
.bubble-text span:nth-child(3) {
  font-size: 3em;
  font-weight: bold;
}
.bubble-text span:nth-child(4) {
  font-size: 1em;
  font-weight: bold;
}
.bubble-text::before {
  content: "";
  background-color: var(--color-grey-3);
  border-radius: 999rem;
  position: absolute;
  inset: -3rem;
  z-index: -1;
}

.button-primary, .header__button, .modal .btn-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}
.button-primary:hover, .header__button:hover, .modal .btn-primary:hover, .button-primary:focus, .header__button:focus, .modal .btn-primary:focus {
  background-color: transparent;
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.button-primary-outline {
  background-color: transparent;
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.button-primary-outline:hover, .button-primary-outline:focus {
  background-color: var(--color-primary);
  color: #fff;
}

.button-secondary-outline {
  background-color: transparent;
  border-color: var(--color-secondary);
  color: var(--color-secondary);
}
.button-secondary-outline:hover, .button-secondary-outline:focus {
  background-color: var(--color-secondary);
  color: #fff;
}

.button-neutral-outline, .modal .btn-secondary {
  background-color: transparent;
  border-color: var(--color-grey);
  color: var(--color-grey);
}
.button-neutral-outline:hover, .modal .btn-secondary:hover, .button-neutral-outline:focus, .modal .btn-secondary:focus {
  background-color: var(--color-grey);
  border-color: var(--color-grey);
  color: #fff;
}

.button-white-outline {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.button-white-outline:hover, .button-white-outline:focus {
  background-color: #fff;
  color: var(--color-dark);
}

.button-white-danger {
  background-color: #fff;
  border-color: #fff;
  color: var(--color-danger);
}
.button-white-danger:hover, .button-white-danger:focus {
  background-color: transparent;
  color: #fff;
}

.button-white-secondary {
  background-color: #fff;
  border-color: #fff;
  color: var(--color-secondary);
}
.button-white-secondary:hover, .button-white-secondary:focus {
  background-color: transparent;
  color: #fff;
}

.button--xs {
  font-size: 1.4rem;
  padding: 0.75rem 2rem;
}

.button--sm {
  font-size: 1.6rem;
  padding: 1rem 2.5rem;
}

.button--md {
  font-size: 1.7rem;
  padding: 1.5rem 3.5rem;
}

.button-icon-primary, .carousel-controllers .prev, .carousel-controllers .next {
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.button-icon-primary:hover, .carousel-controllers .prev:hover, .carousel-controllers .next:hover, .button-icon-primary:focus, .carousel-controllers .prev:focus, .carousel-controllers .next:focus {
  background-color: var(--color-primary);
  color: #fff;
}

.button-icon--sm {
  border-radius: 4.5rem;
  height: 4.5rem;
  width: 4.5rem;
}

.btn-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1rem 2rem;
}
.btn-wrap > [class*=button-] {
  min-width: 20rem;
}
.btn-wrap--center {
  justify-content: center;
}

.card {
  background-color: #fff;
  border-radius: 1.5rem;
  padding: 4rem 5rem;
}
.card--bubble1::before {
  content: "";
  background: url("/storage/icones/bubble-outline-grey-3.svg") left bottom no-repeat;
  background-size: 23rem 23rem;
  width: 18rem;
  height: 18rem;
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.card-avatar {
  border-radius: 999rem;
  display: flex;
  align-items: center;
  padding: 1rem;
}
.card-avatar__images {
  position: relative;
  margin-right: 2.5rem;
  width: 8rem;
  height: 8rem;
}
.card-avatar__image-main {
  border-radius: 999rem;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.card-avatar__image-sub {
  background-color: #fff;
  border-radius: 99rem;
  overflow: hidden;
  padding: 0.25rem;
  position: absolute;
  bottom: -0.25rem;
  right: -0.25rem;
  height: 4rem;
  width: 4rem;
}
.card-avatar__name {
  color: var(--color-tertiary);
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.2;
}
.card-avatar__sub {
  font-size: 1.6rem;
}

.card-feat {
  border: 4px dashed var(--color-secondary-white-2);
  border-radius: 2rem;
  color: var(--color-secondary);
  padding: 3rem;
  text-align: center;
}
.card-feat__icon {
  background-color: var(--color-secondary-white-2);
  border-radius: 9rem;
  display: inline-flex;
  align-items: center;
  font-size: 2.5rem;
  justify-content: center;
  height: 5.2rem;
  width: 5.2rem;
}
.card-feat__title {
  font-size: 2.5rem;
  font-weight: bold;
}
.card-feat__desc {
  font-size: 2.2rem;
}

.cards-content {
  grid-template-columns: repeat(2, 1fr);
  padding-top: 5rem;
  position: relative;
}
.cards-content__image {
  border: 1.5rem solid #fff;
  border-radius: 22rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20rem;
  width: 20rem;
  z-index: 1;
}
.cards-content__rightStyle::before {
  content: "";
  background-color: #fff;
  border-radius: 1.5rem;
  position: absolute;
  left: -1rem;
  bottom: -1rem;
  z-index: -2;
  width: 999rem;
  height: 100%;
}
.cards-content__rightStyle::after {
  content: "";
  background-color: var(--color-primary);
  position: absolute;
  left: calc(100% - 3rem);
  bottom: 0;
  z-index: -1;
  width: 999rem;
  height: 100%;
}
.cards-content__item {
  border-radius: 1.5rem;
  padding: 4rem;
  position: relative;
}
.cards-content__item--1 {
  background-color: var(--color-primary);
}
.cards-content__item--2 {
  background-color: var(--color-tertiary-3);
}
.cards-content__item--3 {
  background-color: var(--color-tertiary);
}
.cards-content__item--4 {
  background-color: var(--color-secondary);
}
.cards-content__item p {
  color: #fff;
  opacity: 0.8;
}
.cards-content > .cards-content__item:nth-child(odd) {
  text-align: right;
}
.cards-content > .cards-content__item:nth-child(odd) .cards-content__svg {
  right: auto;
  left: 4rem;
}
.cards-content__svg {
  display: inline-block;
  opacity: 0.08;
  position: absolute;
  top: 50%;
  right: 4rem;
  height: 16.1rem;
  width: 16.1rem;
  transform: translateY(-50%);
  z-index: 0;
}
.cards-content__titleSup {
  color: #fff;
  font-size: 2.2rem;
  margin-bottom: 0;
  text-transform: uppercase;
}
.cards-content__title {
  color: #fff;
  font-size: 2.1rem;
}

.cards-content-2 {
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem;
  position: relative;
}
.cards-content-2__overlay {
  border: 1.5rem solid var(--color-grey-3);
  border-radius: 22rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 15rem;
  width: 15rem;
  z-index: 1;
}
.cards-content-2__item {
  background-color: #fff;
  border-radius: 1.5rem;
  gap: 2rem;
  flex-flow: row wrap;
  padding: 2rem 3rem;
  position: relative;
}
.cards-content-2__item::before {
  content: "";
  background: url(/storage/icones/bubble-left-primary.svg) center center no-repeat;
  background-size: cover;
  flex-shrink: 0;
  width: 9rem;
  height: 9.25rem;
  z-index: 0;
  margin-right: 3rem;
  transform: scaleX(-1);
}
.cards-content-2__item--primary .cards-content-2__title {
  color: var(--color-primary);
}
.cards-content-2__item--secondary::before {
  background-image: url("/storage/icones/bubble-left-secondary.svg");
}
.cards-content-2__item--secondary .cards-content-2__title {
  color: var(--color-secondary);
}
.cards-content-2__item--large {
  justify-content: center;
  flex-flow: column nowrap;
  padding: 4rem 6rem;
  text-align: center;
}
.cards-content-2__item--large::before {
  background-image: url("/storage/icones/bubble-left-white.svg");
  opacity: 0.2;
  position: relative;
  top: auto;
  left: auto;
}
.cards-content-2__item--large .cards-content-2__title {
  color: #fff;
  margin-bottom: 0;
}
.cards-content-2__item--large .cards-content-2__desc {
  color: #fff;
  opacity: 0.7;
}
.cards-content-2__item--large.cards-content-2__item--primary {
  background-color: var(--color-primary);
}
.cards-content-2__item--large.cards-content-2__item--primary .cards-content-2__top {
  color: var(--color-primary);
}
.cards-content-2__item--large.cards-content-2__item--secondary {
  background-color: var(--color-secondary);
}
.cards-content-2__item--large.cards-content-2__item--secondary .cards-content-2__top {
  color: var(--color-secondary);
}
.cards-content-2__itemWrap {
  flex: 1 1 0;
  min-width: 35rem;
}
.cards-content-2__top {
  background-color: #fff;
  border-radius: 1rem;
  display: inline-block;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  text-transform: uppercase;
}
.cards-content-2 > .cards-content-2__item--large {
  grid-column: 2/span 1;
  grid-row: 1/span 2;
}

.carousel-controllers {
  margin-bottom: 1rem;
}
.carousel-controllers > *:not(:last-child) {
  margin-right: 2rem;
}
.carousel-controllers .prev::before, .carousel-controllers .next::before {
  font-size: 1.8rem;
}
.carousel-controllers .prev svg, .carousel-controllers .next svg {
  display: none;
}
.carousel-controllers .prev::before {
  content: "\f14a";
}
.carousel-controllers .next::before {
  content: "\f14b";
}

.swiper.carousel-1 {
  margin-left: 0;
  overflow: visible;
  position: relative;
  z-index: 0;
  width: 42rem;
}
.swiper.carousel-1::before {
  content: "";
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 999rem;
  z-index: 2;
}
.swiper.carousel-1--grey-3::before {
  background-color: var(--color-grey-3);
}
.swiper.carousel-1 .swiper-slide {
  transition: 0.4s;
}
.swiper.carousel-1 .swiper-slide-active {
  width: 50rem !important;
}
.swiper.carousel-1 .swiper-slide:not(.swiper-slide-active) {
  opacity: 0.5;
  transform-origin: top left;
  transform: scale(0.85);
}
.swiper.carousel-1 .swiper-slide:not(.swiper-slide-active) .event-card {
  position: relative;
}
.swiper.carousel-1 .swiper-slide:not(.swiper-slide-active) .event-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.collapse__tabs {
  border-bottom: 1px solid var(--color-tertiary-4);
  display: flex;
}
.collapse__item {
  border-bottom: 2px solid transparent;
  color: var(--color-grey);
  cursor: pointer;
  font-size: 2.2rem;
  line-height: 1;
  display: inline-block;
  padding: 1rem 2rem;
}
.collapse__item.active {
  border-color: var(--color-primary);
  color: var(--color-tertiary);
}
.collapse__content {
  height: 0;
  transition: none;
}
.collapse__content.active {
  padding-top: 2rem;
  height: auto;
  transition: opacity 0.4s, visibility 0.4s;
}

.condition-step > *.accepted, .condition-step > *:first-child ~ *:not(.active) {
  height: 0;
}
.condition-step > *.active {
  height: auto;
  transition: opacity 1s, visibility 1s;
}

.event-card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.event-card__image {
  display: block;
  position: relative;
  height: 27.5rem;
  width: 100%;
}
.event-card__image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.event-card__location {
  display: inline-flex;
  background-color: #fff;
  color: var(--color-secondary);
  font-size: 1.8rem;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
}
.event-card__location::before {
  margin-right: 0.75rem;
  transform: none;
}
.event-card__content {
  position: relative;
}
.event-card__date {
  border: 1rem solid #fff;
  flex-flow: column nowrap;
  width: 17rem;
  height: 17rem;
  position: absolute;
  left: 1.5rem;
  transform: translateY(-60%);
}
.event-card__date > span {
  color: #fff;
  display: inline-block;
  line-height: 1;
}
.event-card__date > span:nth-child(1) {
  font-size: 6rem;
  font-weight: bold;
}
.event-card__date > span:nth-child(2) {
  font-size: 2.3rem;
  margin-bottom: 0.5rem;
}
.event-card__date > span:nth-child(3) {
  font-size: 1.8rem;
}
.event-card__date--2 {
  border: none;
  position: relative;
  left: auto;
  transform: none;
}
.event-card__date-text {
  color: var(--color-primary);
  font-size: 2rem;
  font-weight: bold;
}
.event-card__date-text i {
  margin-right: 0.5rem;
}
.event-card__content {
  padding: 3rem;
}
.event-card__tag {
  float: right;
}
.event-card__title {
  display: block;
  margin-top: 8rem;
}
.event-card__desc {
  margin-bottom: 1.5rem;
}
.event-card__footer {
  gap: 1rem;
  flex-flow: row wrap;
}
.event-card__footer > * {
  flex-grow: 1;
}
.event-card--isFinished .event-card__date,
.event-card--isFinished .event-card__date::after {
  background-color: var(--color-grey-5);
}
.event-card--feat {
  overflow: visible;
  position: relative;
  margin: 0 auto;
  min-width: 0;
  max-width: 50rem;
  width: 100%;
}
.event-card--feat .event-card__image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.event-card--feat .event-card__hour {
  background-color: var(--color-secondary);
  border-radius: 5rem;
  color: #fff;
  font-size: 2.3rem;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 2rem;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 5;
}
.event-card--feat .event-card__hour::before {
  font-size: 0.9em;
  margin-right: 1rem;
}
.event-card--feat .event-card__top {
  background-color: var(--color-primary);
  border-radius: 5rem;
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
  padding: 0.5rem 3rem;
  position: absolute;
  top: 2rem;
  left: -4rem;
  text-transform: uppercase;
  z-index: 1;
}
.event-card--feat .event-card__tag {
  background-color: transparent;
  border: 2px solid var(--color-secondary);
  color: var(--color-secondary);
}
.event-card--2 {
  display: flex;
  justify-content: flex-end;
  position: relative;
  max-width: 113rem;
}
.event-card--2 .event-card__image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 40rem;
  flex-shrink: 0;
}
.event-card--2 .event-card__content {
  width: calc(100% - 40rem);
}
.event-card--2 .event-card__tag {
  float: none;
}
.event-card--2 .event-card__title {
  margin: 1.5rem 0 0.5rem 0;
}
.event-card--2 .event-card__desc {
  margin: 1.5rem 0 2rem 0;
}

.events-chronology > *:last-child .event-chronology__left::before {
  display: none;
}

.event-chronology {
  grid-template-columns: 25rem 1fr;
  margin-bottom: 3rem;
}
.event-chronology__left::before {
  content: "";
  background-color: var(--color-grey);
  opacity: 0.1;
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 0.6rem;
  height: calc(100% + 5rem);
  z-index: -1;
}
.event-chronology--isFinished .event-card__date,
.event-chronology--isFinished .event-card__date::after {
  background-color: var(--color-grey-5);
}

.filter {
  margin-bottom: 3rem;
}
.filter__wrap {
  background-color: #fff;
  border-radius: 99rem;
  display: inline-block;
  padding: 1.5rem 3rem 1.5rem 6rem;
  z-index: 1;
}
.filter__wrap::before {
  content: "";
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  width: 999rem;
  height: 100%;
  z-index: -1;
}
.filter__categories__select {
  min-width: 30rem;
}

.gallery {
  grid-template-columns: repeat(auto-fit, minmax(39.2888888889rem, 1fr));
}
.gallery__item {
  aspect-ratio: 16/11;
  border-radius: 1.5rem;
  display: block;
  overflow: hidden;
  position: relative;
}
.gallery__item::after {
  content: "";
  background-color: var(--color-primary);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s;
}
.gallery__item:hover::after {
  opacity: 0.6;
}
.gallery__item:hover img {
  transform: scale(1.1);
}
.gallery__item img {
  transition: 0.4s;
}

.gallery-grid {
  grid-template-columns: 2fr repeat(2, 1fr);
  grid-auto-rows: 50%;
  position: relative;
}
.gallery-grid::before, .gallery-grid::after {
  content: "";
  aspect-ratio: 1/1;
  border-radius: 999rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.gallery-grid::before {
  background-color: var(--color-grey-3);
  right: 14.5%;
  width: 10.5%;
}
.gallery-grid::after {
  background-color: var(--color-primary);
  right: 17%;
  width: 8%;
}
.gallery-grid__item {
  border-radius: 1.5rem;
  display: block;
  overflow: hidden;
  position: relative;
}
.gallery-grid__item img {
  transition: 0.4s;
}
.gallery-grid > .gallery-grid__item:first-child {
  grid-column: span 1;
  grid-row: span 2;
}

.icon-text {
  display: flex;
  gap: 2rem;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 2rem;
}
.icon-text__icon {
  flex-shrink: 0;
  width: 9rem;
  height: 9rem;
}
.icon-text__icon img {
  width: 60%;
}
.icon-text__content {
  flex: 1 1 0;
  min-width: 30rem;
}
.icon-text--primary .icon-text__title {
  color: var(--color-primary);
}
.icon-text--tertiary3 .icon-text__title {
  color: var(--color-tertiary-3);
}
.icon-text--tertiary .icon-text__title {
  color: var(--color-tertiary);
}
.icon-text--secondary .icon-text__title {
  color: var(--color-secondary);
}

.identity-card {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 1rem 2rem 1rem 7rem;
  position: relative;
}
.identity-card::before {
  content: "";
  background-color: var(--color-tertiary-3);
  opacity: 0.15;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 82%;
  width: 95%;
}
.identity-card::after {
  color: #fff;
  background-color: var(--color-tertiary-3);
  border-radius: 5rem;
  font-size: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 5rem;
  height: 5rem;
}
.identity-card__title {
  color: currentColor;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.05em;
}
.identity-card__sub {
  color: currentColor;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  opacity: 0.7;
}
.identity-card--2::before, .identity-card--2::after {
  background-color: var(--color-primary);
}
.identity-card--2::before {
  top: 0.5rem;
}
.identity-card--2 .identity-card__title {
  color: var(--color-primary);
  font-size: 2rem;
}
.identity-card--2 .identity-card__sub {
  font-size: 2rem;
}

.identity-card.identity-card--email::after {
  content: "\f2c2";
}

.identity-card.identity-card--phone::after {
  content: "\f3eb";
}

.list-1 {
  padding-left: 1.5rem;
  margin-bottom: 2rem;
}
.list-1 li {
  font-weight: bold;
  margin-bottom: 1rem;
  padding-left: 2.5rem;
  position: relative;
  text-align: left;
}
.list-1 li::before {
  content: "►";
  color: var(--color-primary);
  display: inline-block;
  font-size: 1.6rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(0.2em);
}

.list-icon .list-icon__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}
.list-icon .list-icon__item, .list-icon .list-icon__item > * {
  color: currentColor;
}
.list-icon .list-icon__item i {
  margin-right: 1.5rem;
}

.list-card {
  counter-reset: list-card-item;
  margin-bottom: 2rem;
}
.list-card__item {
  background-color: var(--color-secondary-white-2);
  border-radius: 1rem;
  counter-increment: list-card-item;
  margin: 4rem 0 2rem 0;
  padding: 3rem 2rem 1rem 2rem;
  position: relative;
  animation: fadeInBottomSmall 0.5s ease-in-out forwards;
}
.list-card__head {
  background-color: var(--color-secondary);
  border-radius: 1rem;
  color: #fff;
  font-weight: bold;
  padding: 0.5rem 2rem;
  position: absolute;
  top: 0;
  left: 2rem;
  text-transform: uppercase;
  transform: translateY(-50%);
}
.list-card__head::after {
  content: counter(list-card-item);
}
.list-card__remove {
  background-color: var(--color-danger);
  border: none;
  border-radius: 1rem;
  color: #fff;
  cursor: pointer;
  font-size: 2rem;
  line-height: 1;
  position: absolute;
  padding: 0.5rem 1.5rem;
  top: 0;
  right: 2rem;
  transform: translateY(-50%);
}
.list-card--bordered {
  border-top: 3px dashed var(--color-tertiary-4);
}
.list-card--bordered .list-card__item:last-child {
  position: relative;
  margin-bottom: 4rem;
}
.list-card--bordered .list-card__item:last-child::after {
  content: "";
  border-bottom: 3px dashed var(--color-tertiary-4);
  position: absolute;
  bottom: -2rem;
  left: 0;
  width: 100%;
}

.cropperjs-modal {
  border: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 65rem;
}
.cropperjs-modal::backdrop {
  background: rgba(22, 38, 63, 0.6);
}
.cropperjs-modal__title {
  background-color: var(--color-primary);
  color: #fff;
  font-weight: bold;
  padding: 1.5rem 3rem;
}
.cropperjs-modal__content {
  padding: 2rem;
}
.cropperjs-modal__imageWrap {
  height: 40rem;
  width: 100%;
}
.cropperjs-modal__footer {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem;
}
.cropperjs-modal__footer > * {
  margin: 0;
}

.members-list {
  grid-template-columns: repeat(auto-fit, minmax(29.7777777778rem, 1fr));
}

.member-card {
  background-color: #fff;
  border-radius: 1.5rem;
  margin-top: 13rem;
  padding: 14rem 2rem 5rem 2rem;
  position: relative;
  text-align: center;
  max-width: 37.5rem;
}
.member-card::before {
  content: "";
  background: url("/storage/icones/bubble-left-primary.svg") left bottom no-repeat;
  background-size: 17rem 17rem;
  width: 11rem;
  height: 13rem;
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 1.5rem;
  opacity: 0.2;
  z-index: -1;
}
.member-card__picture {
  background-color: var(--color-secondary);
  border: 1rem solid var(--color-secondary);
  border-radius: 99rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 21.6rem;
  width: 21.6rem;
}
.member-card__title {
  margin-bottom: 0;
}
.member-card__sub {
  color: var(--color-primary);
}
.member-card__actions {
  gap: 1rem;
  margin-top: 1rem;
}
.member-card__action {
  background-color: var(--color-primary-white-2);
  border-radius: 99rem;
  color: var(--color-primary);
  cursor: pointer;
  height: 4.6rem;
  width: 4.6rem;
  transition: 0.4s;
}
.member-card__action i {
  font-size: 2.5rem;
  transition: 0.4s;
}
.member-card__action i::before {
  transform: translateY(3px);
}
.member-card__action--feat {
  background-color: var(--color-primary);
  color: #fff;
}
.member-card__action:hover, .member-card__action:focus-visible {
  background-color: var(--color-secondary);
}
.member-card__action:hover i, .member-card__action:focus-visible i {
  color: #fff;
}
.member-card__verified {
  background-color: var(--color-secondary);
  border: 1rem solid var(--color-grey-3);
  border-radius: 99rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 5.8rem;
  width: 5.8rem;
  transform: translate(-50%, 50%);
}
.member-card__verified::after {
  font-size: 2.2rem;
  color: #fff;
}

.member-card-h {
  background-color: #fff;
  border-radius: 1.5rem;
  margin: 13rem auto 5rem auto;
  padding: 20rem 2rem 7rem 2rem;
  position: relative;
  text-align: center;
}
.member-card-h::before {
  content: "";
  background: url("/storage/icones/bubble-left-primary.svg") left bottom no-repeat;
  background-size: 23rem 23rem;
  width: 13rem;
  height: 18rem;
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 1.5rem;
  opacity: 0.2;
  z-index: -1;
}
.member-card-h::after {
  content: "";
  background: url("/storage/arriere-plan/section-line-secondary.svg") center center no-repeat;
  background-size: cover;
  width: 11rem;
  height: 1.3rem;
  z-index: 0;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%) rotate(-90deg);
}
.member-card-h__picture {
  background-color: var(--color-secondary);
  border: 1.5rem solid var(--color-secondary);
  border-radius: 99rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 31rem;
  width: 31rem;
}
.member-card-h__title {
  margin-bottom: 3rem;
}
.member-card-h__title::after {
  left: 50%;
  transform: translateX(-50%);
}
.member-card-h__label {
  line-height: 1;
  margin-bottom: 2.5rem;
  text-align: center;
}
.member-card-h__label * {
  display: block;
}
.member-card-h__label *:last-child {
  color: var(--color-primary);
  font-size: 2.8rem;
  line-height: 1.2;
  font-weight: bold;
}
.member-card-h__label a:hover, .member-card-h__label a:focus-visible {
  color: var(--color-secondary);
}

.notification {
  border-radius: 1.5rem;
  gap: 2rem;
  padding: 2rem 2rem 2rem 7.5rem;
  position: relative;
  margin-bottom: 1rem;
}
.notification::before {
  color: currentColor;
  font-size: 3.5rem;
  opacity: 0.5;
  position: absolute;
  left: 2rem;
  top: 2rem;
}
.notification__text {
  color: currentColor;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.notification--danger {
  background-color: var(--color-danger);
  color: #fff;
}
.notification--secondary {
  background-color: var(--color-secondary);
  color: #fff;
}
.pagination {
  margin-top: 50px;
}
.pagination__list {
  flex-flow: row wrap;
  gap: 1rem;
}
.pagination__item {
  border: 2px solid var(--color-grey);
  border-radius: 4rem;
  color: var(--color-grey);
  cursor: pointer;
  font-weight: bold;
  height: 4rem;
  width: 4rem;
}
.pagination__item:hover {
  background-color: var(--color-grey);
  color: #fff;
}
.pagination__item--current {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  color: #fff !important;
  cursor: default;
}

.partners {
  grid-template-columns: repeat(auto-fit, minmax(31.4527777778rem, 1fr));
}
.partners__item {
  background-color: #fff;
  padding: 2rem;
  text-align: center;
}

.pellet, .event-card__tag {
  border-radius: 0.5rem;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 0.5rem 2rem;
  text-transform: uppercase;
}

.pellet-primary {
  background-color: var(--color-primary);
  color: #fff;
}

.pellet-secondary, .event-card__tag {
  background-color: var(--color-secondary);
  color: #fff;
}

.pellet-tertiary {
  background-color: var(--color-tertiary);
  color: #fff;
}

.pellet-feat {
  border-radius: 1rem;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1;
  padding: 1rem 5rem;
}

.photo-grid {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 3rem;
}
.photo-grid__item {
  width: 15rem;
}
.photo-grid__image {
  aspect-ratio: 1/1;
  background-color: var(--color-secondary);
  border: 0.7rem solid var(--color-secondary);
  border-radius: 12rem;
  flex-shrink: 0;
  overflow: hidden;
  margin-bottom: 1rem;
  width: 100%;
}
.photo-grid__content {
  text-align: center;
}
.photo-grid__title {
  color: var(--color-tertiary);
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
.photo-grid__sub {
  color: var(--color-primary);
  font-size: 1.6rem;
  line-height: 1.2;
}

.quill-editor--1 .ql-toolbar {
  border: 2px solid var(--color-primary-white-2);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.quill-editor--1 .quill-editor__editor {
  background-color: #fff;
  border: 2px solid var(--color-primary-white-2);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  min-height: 150px;
}
.quill-editor--1 .ql-editor {
  font-family: var(--font-family-1st);
}
.quill-editor--1 .ql-editor p {
  font-size: 1.7rem;
}
.quill-editor--1 .quill-editor__input {
  display: none;
}

.scroll-top {
  width: 6rem;
  height: 6rem;
  border-radius: 999rem;
  background-color: var(--color-primary);
  cursor: pointer;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 99;
  transition: 0.4s;
}
.scroll-top::before {
  color: #fff;
  font-size: 2rem;
}
.scroll-top:hover, .scroll-top:focus {
  background-color: var(--color-tertiary-3);
}
.socials {
  gap: 1.5rem;
}
.socials__item {
  background-color: var(--color-primary-white-2);
  border-radius: 99rem;
  color: var(--color-primary);
  cursor: pointer;
  height: 5.5rem;
  width: 5.5rem;
  transition: 0.4s;
}
.socials__item i {
  font-size: 2.5rem;
  transition: 0.4s;
}
.socials__item i::before {
  transform: translateY(3px);
}
.socials__item:hover, .socials__item:focus-visible {
  background-color: var(--color-primary);
}
.socials__item:hover i, .socials__item:focus-visible i {
  color: #fff;
}

.stable {
  margin-bottom: 2rem;
}
.stable__head {
  margin-bottom: 0;
}
.stable__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stable__item {
  color: var(--color-tertiary);
  padding: 1.5rem;
}
.stable__body > .stable__item:not(:last-child) {
  border-bottom: 3px dashed var(--color-tertiary-4);
}
.stable__label span:first-child {
  display: flex;
  align-items: center;
  font-size: 2.2rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1;
  position: relative;
  text-transform: uppercase;
}
.stable__label span:first-child::before {
  content: "►";
  color: var(--color-primary);
  display: inline-block;
  font-size: 1.7rem;
  margin-right: 1rem;
}
.stable__label span:last-child {
  font-style: italic;
  font-size: 2rem;
}
.stable__value {
  font-size: 3.5rem;
  font-weight: bold;
}
.stable--1 .stable__head {
  background-color: var(--color-primary);
}
.stable--1 .stable__label span:first-child::before,
.stable--1 .stable__item--feat {
  color: var(--color-primary);
}
.stable--1 .stable__body > .stable__item:not(:last-child) {
  border-color: var(--color-primary-white-2);
}
.stable--2 .stable__head {
  background-color: var(--color-secondary);
}
.stable--2 .stable__label span:first-child::before,
.stable--2 .stable__item--feat {
  color: var(--color-secondary);
}
.stable--2 .stable__body > .stable__item:not(:last-child) {
  border-color: var(--color-secondary-white-2);
}

.stable-label > .stable-label__item:not(:last-child) {
  border-bottom: 3px dashed var(--color-tertiary-4);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
.stable-label__item {
  flex-flow: row wrap;
}
.stable-label__item span {
  display: block;
}
.stable-label__item span:first-child {
  color: var(--color-tertiary);
  flex-shrink: 0;
  font-size: 1.8rem;
  font-weight: bold;
  min-width: 15rem;
}
.stable-label__item span:last-child {
  color: var(--color-primary);
  flex: 1 1 0;
  font-size: 2.2rem;
  font-weight: bold;
  text-align: right;
}

.tabulator-1 {
  background-color: transparent;
  border: none;
  font-family: var(--font-primary);
}

.tabulator-1 .tabulator-tableholder .tabulator-table {
  background-color: transparent;
}

.tabulator-1 .tabulator-header {
  background-color: var(--color-tertiary);
  border: none;
  border-radius: 0.5rem;
  font-weight: normal;
}

.tabulator-1 .tabulator-header .tabulator-col {
  background-color: transparent;
  border-right: none;
  color: #fff;
  font-size: 1.8rem;
}

.tabulator-1 .tabulator-header .tabulator-col.tabulator-sortable.tabulator-col-sorter-element:hover {
  background-color: var(--color-primary);
}

.tabulator-1 .tabulator-header .tabulator-col .tabulator-col-content {
  padding: 1rem 1.5rem;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort=none] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-bottom-color: #fff;
}

.tabulator-1 .tabulator-header .tabulator-col.tabulator-sortable:hover {
  background-color: var(--color-primary-light);
}

.tabulator-1 .tabulator-row {
  background-color: transparent;
}

.tabulator-1 .tabulator-row .tabulator-cell {
  border-right: 1px solid transparent;
  font-size: 1.8rem;
  padding: 1rem;
  white-space: normal;
}

.tabulator-1 .tabulator-table > .tabulator-row:not(:last-child) {
  border-bottom: 2px dashed var(--color-primary-white-2);
}

.tabulator-1 .tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  background: var(--color-primary);
  font-size: 2.3rem;
  height: 3rem;
  width: 3rem;
}

.tabulator-1 .tabulator-row .tabulator-responsive-collapse tr > td:first-child {
  color: var(--color-secondary);
}

.tabulator-1 .tabulator-row.tabulator-row-even {
  background-color: transparent;
}

.tabulator-1 .tabulator-row.tabulator-selectable:hover {
  background-color: var(--color-primary-white-2);
  cursor: default;
}

.tabulator-1 .tabulator-footer {
  background-color: transparent;
  border-top-color: transparent;
}

.tabulator-1 .tabulator-footer .tabulator-paginator {
  padding-top: 10px;
  text-align: center;
}

.tabulator-1 .tabulator-footer .tabulator-page {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  font-size: 1.6rem;
  padding: 0.8rem 2rem;
}

.tabulator-1 .tabulator-footer .tabulator-page:hover {
  background-color: var(--color-primary);
  color: #fff;
}

.tabulator-1 .tabulator-footer .tabulator-page:disabled {
  cursor: default;
}

.tabulator-1 .tabulator-footer .tabulator-page:disabled,
.tabulator-1 .tabulator-footer .tabulator-page:disabled:hover {
  background-color: transparent;
  border: 1px solid var(--color-secondary-white-2);
  color: var(--color-secondary-white-2);
}

.tabulator-1 .tabulator-footer .tabulator-pages .tabulator-page {
  border: 1px solid var(--color-primary);
  border-radius: 4rem;
  color: var(--color-primary);
  display: inline-flex;
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 4rem;
  width: 4rem;
}

.tabulator-1 .tabulator-footer .tabulator-pages .tabulator-page.active {
  background-color: var(--color-primary);
  color: #fff;
  font-weight: bold;
}

.tabulator-1 .tabulator-footer .tabulator-pages .tabulator-page:hover:not(.active),
.tabulator-1 .tabulator-footer .tabulator-pages .tabulator-page:focus:not(.active) {
  background-color: var(--color-secondary-white-2);
  border-color: var(--color-secondary-white-2);
  color: var(--color-primary);
}

.tabulator-event-cell {
  display: flex;
  align-items: center;
}

.tabulator-event-cell .image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 1.5rem;
  width: 12.5rem;
  height: 8rem;
}

.tabulator-event-cell .image a {
  display: block;
  height: 100%;
}

.tabulator-event-cell .image img {
  border-radius: 0.5rem;
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.tabulator-event-cell .content > span:first-child {
  color: var(--color-secondary);
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
}

.tabulator-event-cell .content > span:last-child {
  color: var(--color-tertiary);
  display: block;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.2;
}

.tabulator__loading {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  text-align: center;
}
.tabulator__loading svg {
  margin-bottom: 2rem;
  animation: spinning 1.5s linear backwards infinite;
}
.tabulator__loading svg * {
  fill: var(--color-primary-2);
}
.tabulator__loading__text {
  color: var(--color-primary-2);
  font-weight: 700;
}

.tabulator__empty {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  text-align: center;
}
.tabulator__empty svg * {
  fill: var(--color-primary-2);
}
.tabulator__empty__text {
  color: var(--color-primary-2);
  font-weight: 700;
}

.widget {
  margin-bottom: 2rem;
}
.widget--checkbox {
  display: block;
}
.widget--checkbox .widget__content {
  background-color: var(--color-grey);
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1.75rem 8rem 1.75rem 2.5rem;
  position: relative;
  transition: 0.4s;
}
.widget--checkbox .widget__content::after {
  color: #fff;
  font-size: 3rem;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
}
.widget--checkbox .widget__content > span {
  color: #fff;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: bold;
  margin-left: 1.5rem;
}
.widget--checkbox input:checked + .widget__content {
  background-color: var(--color-primary);
}
.widget--checkbox input:checked + .widget__content::after {
  transform: translateY(-50%);
}

.header {
  padding: 1.5rem 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}
.header .container {
  display: grid;
  grid-template-columns: minmax(2rem, 1fr) minmax(0, 170rem) minmax(2rem, 1fr);
}
.header .container > * {
  grid-column: 2/-2;
}
.header__logo {
  display: block;
  margin-right: 4rem;
  width: 22.5rem;
  transition: none;
}
.header__nav {
  padding-right: 2rem;
  flex-shrink: 0;
}
.header__menu li a {
  color: #fff;
  display: block;
  font-size: 1.7rem;
  font-weight: bold;
  padding: 1rem 2rem;
  text-transform: uppercase;
}
.header__menu li a:hover, .header__menu li a:focus {
  color: var(--color-primary);
}
.header__extra {
  justify-content: flex-end;
  flex-grow: 1;
  flex-shrink: 0;
}
.header__extra > *:not(:last-child) {
  margin-right: 2rem;
}
.header__button {
  padding: 1.4rem 3rem;
  margin-bottom: 0;
}
.header__link {
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.05em;
  position: relative;
}
.header__link i::before {
  transform: none;
}
.header__link::after {
  content: "";
  background-color: #fff;
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 1px;
  width: 100%;
  transition: 0.4s;
}
.header__link:hover, .header__link:focus {
  color: var(--color-primary);
}
.header__link:hover::after, .header__link:focus::after {
  background-color: var(--color-primary);
}
.header__link--2 {
  border: 2px solid #fff;
  border-radius: 99rem;
  font-size: 1.5rem;
  padding: 0.5rem 1.5rem;
}
.header__link--2::after {
  display: none;
}
.header__link--2:hover {
  border-color: var(--color-primary);
}

.header.is-sticky {
  background-color: var(--color-tertiary);
  padding: 1rem 0;
  position: fixed;
  animation: fadeBottomSmall 0.4s ease-in-out forwards;
}
.header.is-sticky .header__logo {
  width: 10rem;
}

.header__menu--mobile {
  display: none;
  margin-bottom: 0;
}

@media (max-width: 90.61em) {
  .header__nav {
    background-color: rgba(22, 38, 63, 0.6);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    visibility: hidden;
    z-index: 999;
    transition: opacity 0.4s, visibility 0.4s;
  }
  .header__menu, .header__menu--mobile {
    opacity: 0;
    visibility: hidden;
    background-color: var(--color-dark);
    width: 40rem;
    transform: translateX(-100%);
    transition: opacity 0.4s, transform 0.4s, visibility 0.4s;
  }
  .header__menu {
    flex-grow: 1;
    flex-flow: column;
    align-items: stretch;
    padding: 8rem 3rem 3rem 3rem;
    top: 0;
    margin-bottom: 0;
  }
  .header__menu li {
    margin-bottom: 1rem;
  }
  .header__menu li > a {
    display: flex;
    align-items: center;
    font-size: 2.2rem;
  }
  .header__menu li > a::before {
    content: "►";
    color: var(--color-primary);
    display: inline-block;
    font-size: 0.8em;
    margin-right: 1.5rem;
  }
  .header__mobile-open {
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 3rem;
    position: relative;
    height: 3rem;
    width: 4rem;
  }
  .header__mobile-open::before, .header__mobile-open > span, .header__mobile-open::after {
    background-color: #fff;
    border-radius: 99rem;
    display: inline-block;
    height: 3px;
    transition: 0.4s;
  }
  .header__mobile-open::before {
    content: "";
    width: 100%;
  }
  .header__mobile-open span {
    width: 75%;
  }
  .header__mobile-open::after {
    content: "";
    width: 50%;
  }
  .header__mobile-open:hover::before, .header__mobile-open:hover > span, .header__mobile-open:hover::after, .header__mobile-open:focus::before, .header__mobile-open:focus > span, .header__mobile-open:focus::after {
    background-color: var(--color-primary);
    width: 100%;
  }
  .header__mobile-close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 999rem;
    background-color: var(--color-tertiary-2);
    cursor: pointer;
    position: absolute;
    top: 2rem;
    left: 32rem;
    transform: translateX(-100%);
    transition: 0.4s;
  }
  .header__mobile-close::after {
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: uicons-regular-rounded !important;
    content: "\f27c";
    color: #fff;
  }
  .header__mobile-close:hover, .header__mobile-close:focus {
    background-color: var(--color-primary);
  }
  .header.is-open .header__nav {
    opacity: 1;
    visibility: visible;
  }
  .header.is-open .header__mobile-close, .header.is-open .header__menu, .header.is-open .header__menu--mobile {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}
@media (max-width: 49.99em) {
  .header__wrap {
    justify-content: space-between;
  }
  .header__extra {
    display: none;
  }
  .header__menu {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .header__menu::-webkit-scrollbar {
    display: none;
  }
  .header__menu--mobile {
    border-top: 2px solid var(--color-tertiary-2);
    display: block;
    padding: 3rem;
    bottom: 0;
  }
  .header__menu--mobile li {
    text-align: center;
  }
  .header__menu--mobile li:not(:last-child) {
    margin-bottom: 2.5rem;
  }
}
.footer {
  background-color: var(--color-tertiary);
  color: #fff;
  position: relative;
  z-index: 5;
}
.footer::after {
  content: "";
  background: url("/storage/arriere-plan/section-line-secondary.svg") center center no-repeat;
  background-size: cover;
  width: 15rem;
  height: 2.1rem;
  z-index: 0;
  position: absolute;
  top: 7rem;
  left: 50%;
  transform: translateX(-50%) rotate(90deg);
  z-index: 6;
}
.footer__main {
  display: grid;
  grid-gap: 6rem;
  grid-template-columns: repeat(auto-fit, minmax(32.7111111111rem, 1fr));
  background-color: var(--color-tertiary);
  position: relative;
}
.footer__main::before {
  width: 70rem;
  height: 70rem;
  top: -8rem;
  right: -26rem;
}
.footer__main::after {
  content: "";
  background-color: var(--color-tertiary-3);
  position: absolute;
  left: -50rem;
  bottom: -5rem;
  width: 60rem;
  height: 60rem;
  opacity: 0.1;
  border-radius: 60rem;
}
.footer__logo {
  display: block;
  margin: 0 auto 3rem auto;
  max-width: 35rem;
}
.footer__slogan {
  color: currentColor;
  font-weight: normal;
  margin-bottom: 3rem;
  text-align: center;
}
.footer__title {
  color: currentColor;
  margin-bottom: 3rem;
}
.footer__bottom {
  padding: 3.5rem 2rem;
  position: relative;
  text-align: center;
}
.footer__bottom::before {
  content: "";
  background-color: var(--color-tertiary-3);
  position: absolute;
  top: 0;
  left: 50%;
  height: 1px;
  width: 100vw;
  transform: translateX(-50%);
}
.footer__bottom p {
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  opacity: 0.7;
}
.footer__bottom p a {
  text-decoration: underline;
}

#wrapper {
  scroll-padding-top: 30rem;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  height: 100vh;
  width: 100%;
}

.container {
  margin: 0 auto;
  max-width: 192rem;
  width: 100%;
}

.row-2c {
  grid-template-columns: repeat(2, 1fr);
}

.row-3c {
  grid-template-columns: repeat(3, 1fr);
}

.row-3-9 {
  grid-template-columns: 3fr  9fr ;
}

@media (max-width: 49.99em) {
  .row-sm-12 {
    grid-template-columns: 1fr;
    grid-column-gap: 2rem;
  }
}
.row-2c-smart {
  grid-template-columns: repeat(auto-fit, minmax(51.3666666667rem, 1fr));
}

.section-title {
  margin-bottom: 3.5rem;
}
.section-title__sup {
  color: var(--color-primary);
  font-size: 2rem;
  letter-spacing: 0.1em;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}
.section-title__main {
  margin-bottom: 0;
  text-transform: uppercase;
}
.section-title--center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.section-title--center .section-title__main::after {
  left: 50%;
  transform: translateX(-50%);
}
.section-title--white .section-title__sup {
  color: #fff;
}
.section-title--white .section-title__main {
  color: #fff;
}
.section-title--1 {
  position: relative;
  z-index: 0;
}
.section-title--1::before {
  content: "";
  background: url("/storage/arriere-plan/section-line-grey-3.svg") center center no-repeat;
  background-size: cover;
  width: 100vw;
  height: 65rem;
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleX(-1);
}
.section-title--lineless .section-title__main::after {
  display: none;
}

.section-about-intro {
  background-color: var(--color-primary-white-2);
  padding: 7rem 0 14rem 0;
  position: relative;
  z-index: 6;
}
.section-about-intro::before {
  content: "";
  background: url("/storage/icones/bubble-left-primary.svg") left bottom no-repeat;
  background-size: 58rem 60rem;
  width: 78rem;
  height: 36rem;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translateX(-50%);
  opacity: 0.1;
  z-index: -1;
}
.section-about-intro__overlay {
  aspect-ratio: 1/1;
  border: 3rem solid var(--color-grey-3);
  border-radius: 99rem;
  overflow: hidden;
  position: absolute;
  top: calc(-7vw + 10rem);
  right: 100%;
  width: 50%;
  z-index: 10;
  transform: translateX(55%);
}
.section-about-intro__content {
  width: 65vw;
  max-width: 95rem;
  margin-left: auto;
}

.section-about-gallery {
  background-color: var(--color-grey-3);
  padding: 15rem 0 7rem 0;
}

.section-about-job {
  background-color: var(--color-grey-3);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 12rem));
  padding: 15rem 0;
}

.section-about-why {
  padding: 4rem 0 15rem 0;
}

.page-content-event {
  padding-bottom: 8rem;
}

.section-checkout {
  background-color: var(--color-grey-3);
  overflow: hidden;
  padding: 7rem 0 30rem 0;
}
.section-checkout::before, .section-checkout::after {
  width: 76rem;
  height: 76rem;
  border-radius: 999rem;
  content: "";
  position: absolute;
  opacity: 0.2;
  z-index: -1;
}
.section-checkout::before {
  background-color: var(--color-primary);
  top: -8rem;
  left: -15rem;
}
.section-checkout::after {
  background-color: var(--color-secondary);
  bottom: -8rem;
  right: -15rem;
}
.section-checkout .container {
  display: flex;
  position: relative;
}
.section-checkout__card {
  margin: 9rem auto 0 auto;
  position: relative;
  text-align: center;
  width: 60%;
  z-index: 1;
}
.section-checkout__image {
  border: 3rem solid #fff;
  border-radius: 999rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  height: 71.9rem;
  width: 71.9rem;
}
.section-checkout__table {
  max-width: 45rem;
}
.section-checkout__table__line {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 10px;
  justify-content: space-between;
}
.section-checkout__table__line span:first-child {
  color: var(--color-tertiary);
  font-weight: bold;
}
.section-checkout__table__line span:last-child {
  color: var(--color-primary);
  font-size: 4rem;
  line-height: 1.2;
  font-weight: bold;
}

.section-contact {
  padding: 7rem 0 14rem 0;
}
.section-contact .container {
  grid-template-columns: 45% 55%;
}
.section-contact .container .right {
  padding-left: 5rem;
  padding-right: 2rem;
}

.container-overview {
  grid-template-columns: 37% calc(63% - 2rem);
}
.container-overview .left {
  max-width: 55rem;
}

.section-dashboard {
  background-color: #e1cdba;
  display: grid;
  grid-gap: 0;
  grid-template-columns: minmax(37rem, 25%) 1fr;
  min-height: 80rem;
}

.dashboard-header {
  background-color: var(--color-tertiary);
  position: relative;
  overflow: hidden;
  height: 20rem;
}
.dashboard-header .banner__overlays::before {
  top: -11rem;
}

.dashboard-aside {
  background-color: #e1cdba;
  padding: 5rem 3rem 15rem 3rem;
}

.dashboard-content {
  background-color: #fbf8f5;
  overflow: hidden;
  padding: 5rem 6rem 15rem 6rem;
}
.dashboard-content::before {
  content: "";
  background: url("/storage/icones/bubble-left-primary.svg") center center no-repeat;
  background-size: cover;
  width: 48.5rem;
  height: 50rem;
  z-index: 0;
  position: absolute;
  top: -3rem;
  right: -3rem;
  opacity: 0.07;
  z-index: -1;
}

.dashboard-main-nav {
  margin-top: 1.5rem;
  padding-left: 2rem;
}
.dashboard-main-nav a {
  color: var(--color-tertiary);
  font-weight: bold;
  margin-bottom: 1rem;
  transition: 0.4s;
}
.dashboard-main-nav a i {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  color: var(--color-primary);
  font-size: 1.5rem;
  margin-right: 2rem;
  height: 4.2rem;
  width: 4.2rem;
  transform: none;
  transition: 0.4s;
}
.dashboard-main-nav a:hover i, .dashboard-main-nav a:focus-visible i {
  background-color: var(--color-primary);
  color: #fff;
}
.dashboard-main-nav .nav-item-alt i {
  background-color: var(--color-tertiary);
  color: #fff;
}

.dashboard-bottom-nav {
  padding-left: 1.5rem;
}
.dashboard-bottom-nav a {
  color: var(--color-tertiary);
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: underline;
}
.dashboard-bottom-nav a i {
  margin-right: 1rem;
}

.page-content-event {
  padding-bottom: 8rem;
}

.section-event-resume {
  background-color: var(--color-grey-3);
  overflow: hidden;
  padding: 7rem 0 30rem 0;
}
.section-event-resume::before, .section-event-resume::after {
  width: 76rem;
  height: 76rem;
  border-radius: 999rem;
  content: "";
  position: absolute;
  opacity: 0.2;
  z-index: -1;
}
.section-event-resume::before {
  background-color: var(--color-primary);
  top: -8rem;
  left: -15rem;
}
.section-event-resume::after {
  background-color: var(--color-secondary);
  bottom: -8rem;
  right: -15rem;
}
.section-event-resume .container {
  display: flex;
  position: relative;
}
.section-event-resume__card {
  margin-top: 9rem;
  position: relative;
  text-align: center;
  width: 60%;
  z-index: 1;
}
.section-event-resume__image {
  border: 3rem solid #fff;
  border-radius: 999rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  height: 71.9rem;
  width: 71.9rem;
}
.section-event-desc {
  margin-top: -9rem;
  padding-bottom: 10rem;
}
.section-event-desc::before {
  content: "";
  background-color: #fff;
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  position: absolute;
  top: -5rem;
  left: 50%;
  width: 140rem;
  height: 14rem;
  transform: translateX(-50%);
}
.section-event-desc .container {
  padding: 0 2rem;
  grid-template-columns: repeat(auto-fit, minmax(51.3666666667rem, 1fr));
}

.section-event-sub {
  background-color: var(--color-grey-3);
  padding: 7rem 0 15rem 0;
}
.section-event-sub__overlay {
  border: 3rem solid #fff;
  border-radius: 999rem;
  overflow: hidden;
  position: absolute;
  top: -6rem;
  left: -20rem;
  text-align: center;
  height: 95.6rem;
  width: 95.6rem;
  z-index: -1;
}
.section-event-sub__overlay img {
  display: inline-block;
}
.section-event-sub .container {
  grid-template-columns: 1fr calc(35vw + 42rem);
}
.section-event-sub__card {
  grid-column: 2/-1;
}

.event-participant__row {
  display: flex;
  gap: 0.5rem 1.5rem;
  align-items: center;
  margin-bottom: 1.5rem;
}
.event-participant__icon {
  background-color: var(--color-primary-white);
  border-radius: 5rem;
  flex-shrink: 0;
  height: 5rem;
  width: 5rem;
}
.event-participant__icon::before {
  color: var(--color-primary);
  font-size: 2rem;
  transform: translateY(-0.2rem);
}
.event-participant__name {
  flex: 0 1 33%;
  margin-bottom: 0;
  text-align: left;
}
.event-participant__option {
  flex-grow: 1;
  font-size: 1.7rem;
  line-height: 1.2;
}
.event-participant__option .option-label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  font-weight: bold;
}
.event-participant__option .option-label::before {
  font-size: 0.8em;
}
.event-participant__option--with .option-label {
  color: var(--color-secondary);
}
.event-participant__option--without .option-label {
  color: var(--color-danger);
}
.event-participant__price {
  color: var(--color-secondary);
  font-size: 2.5rem;
  font-weight: bold;
  flex: 0 1 25%;
  text-align: right;
}
.event-participant__total {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}
.event-participant__total *:first-child {
  color: var(--color-tertiary);
  font-size: 2rem;
  font-weight: bold;
}
.event-participant__total *:last-child {
  color: var(--color-primary);
  font-size: 3.5rem;
  font-weight: bold;
}

.events-container--grid {
  grid-template-columns: repeat(auto-fit, minmax(39.2888888889rem, 1fr));
}

.section-banner {
  position: relative;
  z-index: 5;
}

.section-goals {
  padding-top: 17.5rem;
}
.section-goals::before {
  content: "";
  background: url("/storage/double-exposure-businessman-working-on-digital-tablet-on-white-reversed-w1920-1.webp") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  opacity: 0.05;
}
.section-goals .container {
  grid-template-columns: 37% minmax(min-content, 90.72rem) 1fr;
  margin-bottom: 4rem;
}
.section-goals .left::before {
  -webkit-mask-image: url("/storage/icones/bubble-right.svg");
  mask-image: url("/storage/icones/bubble-right.svg");
  -webkit-mask-mask-position: right;
  mask-position: right;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
  content: "";
  background: url("/storage/pexels-proxyclick-visitor-management-system-2451567-w1000x1000-2.jpg") center center no-repeat;
  background-size: cover;
  width: 97rem;
  height: 100rem;
  z-index: 0;
  position: absolute;
  top: -12rem;
  left: auto;
  right: 0;
}

.section-become-member .section-title {
  margin-bottom: 10rem;
}

.section-join-us {
  background-color: var(--color-primary-white);
}
.section-join-us::before {
  content: "";
  background: url("/storage/pro-crowd-primary.svg") center center no-repeat;
  background-size: cover;
  width: 64.7rem;
  height: 38.3rem;
  z-index: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.08;
}
.section-join-us::after {
  z-index: 0;
}
.section-join-us .container {
  position: relative;
}
.section-join-us .container .section-icon {
  position: absolute;
  top: -13.5rem;
}
.section-join-us .section-content {
  width: 65%;
}
.section-join-us .section-overlay {
  border-radius: 70rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  height: 70rem;
  width: 70rem;
  z-index: 6;
}

.section-events {
  background-color: var(--color-grey-3);
}
.section-events .container {
  grid-template-columns: 35% 65%;
}
.section-events .left {
  z-index: 1;
}
.section-events .right {
  position: relative;
  width: 100%;
}

.events-carousel .event-card {
  width: 50rem;
}

.section-partners {
  background: url("/storage/businesswoman-leader-bg-section-w1920x900-1.webp") center center no-repeat;
  background-size: cover;
}

.section-login {
  position: relative;
  z-index: 5;
}
.section-login .container {
  position: relative;
  z-index: 1;
}

.card-login {
  background-color: var(--color-grey-3);
  max-width: 75rem;
}

.page-member .container {
  grid-template-columns: 50rem 1fr;
}
.page-member .left {
  justify-content: center;
}
.page-member .right .card {
  margin-top: 13rem;
}

.page-content {
  background-color: var(--color-grey-3);
  padding: 7rem 0 15rem 0;
}

.page-subscription {
  display: grid;
  grid-template-columns: minmax(2rem, 1fr) minmax(0, 170rem) minmax(2rem, 1fr);
  background-color: var(--color-grey-3);
  position: relative;
  z-index: 6;
}
.page-subscription > * {
  grid-column: 2/-2;
}
.page-subscription .container {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 52% 48%;
}
.page-subscription .left {
  padding: 7rem 5.5rem 15rem 0;
}
.page-subscription .right {
  background-color: var(--color-primary-white-2);
  padding: 37rem 0 15rem 5.5rem;
}
.page-subscription .right::before {
  content: "";
  background: url("/storage/icones/bubble-left-primary.svg") left bottom no-repeat;
  background-size: 58rem 60rem;
  width: 58rem;
  height: 42rem;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translateX(-50%);
  opacity: 0.1;
  z-index: -1;
}
.page-subscription .right::after {
  content: "";
  background-color: var(--color-primary-white-2);
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 999rem;
  z-index: -2;
}
.page-subscription__bubble {
  position: absolute;
  top: -8rem;
  left: -2rem;
}

@media (max-width: 87.49em) {
  .section-dashboard {
    display: block;
    position: relative;
  }
  .dashboard-aside {
    overflow: hidden;
    padding: 5rem 1rem 15rem 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 10rem;
    transition: 0.4s;
  }
  .dashboard-aside:hover {
    width: 32rem;
  }
  .dashboard-aside-wrap {
    width: 32rem;
  }
  .dashboard-content {
    margin-left: 10rem;
    padding: 5rem 4rem 15rem 4rem;
    min-height: 95rem;
  }
  .dashboard-main-nav {
    padding-left: 0;
  }
  .dashboard-main-nav a {
    margin-bottom: 1.5rem;
  }
  .dashboard-main-nav a i {
    font-size: 2rem;
    margin-right: 2rem;
    height: 6rem;
    width: 6rem;
  }
  .section-event-desc .container {
    padding: 0;
  }
}
@media (max-width: 61.99em) {
  .nice-select-dropdown .list .option {
    padding: 1.5rem;
  }
  .bubble-text {
    font-size: 3rem;
    height: 38rem;
    width: 38rem;
  }
  .event-chronology {
    grid-template-columns: repeat(1, 1fr);
  }
  .event-chronology__left {
    display: none;
  }
  .container-overview {
    display: block;
    width: 100%;
  }
  .section-goals {
    display: grid;
    grid-template-columns: minmax(2rem, 1fr) minmax(0, 140rem) minmax(2rem, 1fr);
  }
  .section-goals > * {
    grid-column: 2/-2;
  }
  .section-goals .container {
    display: block;
  }
  .section-goals .left {
    display: none;
  }
  .section-join-us .section-overlay {
    display: none;
  }
  .section-join-us .section-content {
    width: 100%;
  }
  .section-events {
    display: grid;
    grid-template-columns: minmax(2rem, 1fr) minmax(0, 140rem) minmax(2rem, 1fr);
  }
  .section-events > * {
    grid-column: 2/-2;
  }
  .section-events .container {
    display: block;
  }
  .section-event-sub__overlay {
    display: none;
  }
  .section-event-sub .container {
    display: block;
  }
  .section-event-resume,
.section-checkout {
    padding-bottom: 18rem;
  }
  .section-event-resume .container,
.section-checkout .container {
    display: block;
  }
  .section-event-resume__card,
.section-checkout__card {
    margin-top: 0;
    width: 100%;
  }
  .section-event-resume__image,
.section-checkout__image {
    aspect-ratio: 1/1;
    position: relative;
    margin: -4rem auto 0 auto;
    max-height: 71.9rem;
    max-width: 71.9rem;
    width: auto;
    height: auto;
    z-index: 1;
  }
  .page-member .container {
    grid-template-columns: repeat(1, 1fr);
  }
  .page-member .right .card {
    margin-top: 0;
  }
  .page-subscription .container {
    display: block;
  }
  .page-subscription .left, .page-subscription .right {
    padding-left: 0;
    padding-right: 0;
  }
  .page-subscription .right {
    padding-top: 38rem;
  }
  .page-subscription .right::after {
    left: 50%;
    transform: translateX(-50%);
  }
  .page-subscription__bubble {
    left: calc(50% - 19rem);
  }
}
@media (max-width: 49.99em) {
  .banner:not(.banner--pageHeader) {
    height: auto;
  }
  .banner .container {
    grid-template-columns: 100%;
  }
  .blocks-overlay__item {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .cards-content-2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .cards-content-2__overlay {
    display: none;
  }
  .cards-content-2 > .cards-content-2__item--large {
    grid-column: auto;
    grid-row: auto;
  }
  .card {
    padding: 3rem;
  }
  .cards-content {
    grid-template-columns: repeat(1, 1fr);
  }
  .cards-content__image {
    top: -2rem;
  }
  .cards-content__item:nth-child(odd) {
    text-align: left;
  }
  .cards-content__item:nth-child(odd) .cards-content__svg {
    right: 4rem;
    left: auto;
  }
  .event-card--2 {
    flex-flow: column nowrap;
  }
  .event-card--2 .event-card__image {
    position: static;
    height: 35rem;
    width: 100%;
  }
  .event-card--2 .event-card__content {
    width: 100%;
  }
  .filter__layout {
    display: none;
  }
  .notification {
    padding: 2rem;
  }
  .notification::before {
    display: none;
  }
  .row-2c-smart {
    grid-template-columns: repeat(1, 1fr);
  }
  .section-about-intro__overlay {
    display: none;
  }
  .section-about-intro__content {
    max-width: 100%;
    width: 100%;
  }
  .section-contact .container {
    grid-template-columns: 1fr;
  }
  .section-contact .container .right {
    padding: 0;
  }
  .section-event-sub__overlay {
    display: none;
  }
  .section-event-sub .container {
    display: block;
  }
  .section-event-desc .container {
    grid-template-columns: repeat(1, 1fr);
  }
  .section-become-member .section-title {
    margin-bottom: 12rem;
  }
  .section-login {
    display: grid;
    grid-template-columns: minmax(2rem, 1fr) minmax(0, 140rem) minmax(2rem, 1fr);
  }
  .section-login > * {
    grid-column: 2/-2;
  }
}
@media (max-width: 29.99em) {
  h1, .h1 {
    font-size: 4rem;
  }
  h2, .h2 {
    font-size: 2.5rem;
  }
  h3, .h3 {
    font-size: 2rem;
  }
  h4, .h4 {
    font-size: 1.8rem;
  }
  .input-length-1 {
    width: 100%;
  }
  .blocks-overlay__item {
    padding: 2rem 0;
  }
  .blocks-overlay__item::before {
    right: calc(100% + 1.5rem);
  }
  .blocks-overlay__item::after {
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .btn-wrap > * {
    flex-grow: 1;
    margin-right: 0;
    text-align: center;
  }
  .swiper.carousel-1 {
    transform: translateX(-2.5rem);
  }
  .swiper.carousel-1 .swiper-slide-active {
    width: 38rem !important;
  }
  .event-card__content {
    text-align: center;
  }
  .event-card__date {
    left: 50%;
    transform: translate(-50%, -60%);
  }
  .event-card__tag {
    float: none;
    margin-top: 8rem;
    margin-bottom: 1rem;
  }
  .event-card__title {
    margin-top: 0;
  }
  .event-card__desc {
    text-align: left;
  }
  .event-card__footer > * {
    width: 100%;
  }
  .event-card--2 .event-card__tag {
    margin-top: 0;
  }
  .event-card--2 .event-card__content, .event-card--2 .event-card__desc {
    text-align: center;
  }
  .filter__wrap {
    border-radius: 1.5rem;
    flex-grow: 1;
    padding: 1.5rem;
  }
  .stable-label__item {
    flex-flow: column wrap;
  }
  .stable-label__item span:first-child {
    min-width: 0;
  }
  .stable-label__item span:last-child {
    text-align: center;
  }
  .events-carousel .event-card {
    width: 38rem;
  }
  .tabulator-event-cell .image {
    display: none;
  }
  .event-participant__row {
    flex-direction: column;
  }
}
.u-color-primary {
  color: var(--color-primary) !important;
}

.u-color-secondary {
  color: var(--color-secondary) !important;
}

.u-color-tertiary {
  color: var(--color-tertiary) !important;
}

.u-color-white {
  color: #fff !important;
}

.u-inline-block {
  display: inline-block !important;
}

.u-align-items-end {
  align-items: flex-end !important;
}

.u-col-grid-full {
  grid-column: 1/-1 !important;
}

.u-text-bold {
  font-weight: bold !important;
}

.u-text-left {
  text-align: left !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-feat {
  font-size: 2rem !important;
}

.u-text-italic {
  font-style: italic !important;
}

.u-text-normal {
  font-weight: normal !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-uppercase {
  text-transform: uppercase !important;
}

.u-text-transform-none {
  text-transform: none !important;
}

.u-text-07 {
  font-size: 0.7em !important;
}

.u-text-09 {
  font-size: 0.9em !important;
}

.u-text-12 {
  font-size: 1.2em !important;
}

.u-text-sm {
  font-size: 1.6rem !important;
}

.u-margin-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-full-width {
  width: 100% !important;
}

.u-gap-icon-i {
  display: inline-flex !important;
  align-items: center !important;
  gap: 0.5rem 2rem !important;
}

/* -----------------------------------------------
 *  Auto generated helpers
 * -> You should not modify that part
 ------------------------------------------------- */