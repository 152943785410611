.widget {
    margin-bottom: 2rem;

    &--checkbox {
        display: block;
        .widget__content {
            background-color: var(--color-grey);
            border-radius: 1rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 1.75rem 8rem 1.75rem 2.5rem;
            position: relative;
            transition: $transition;

            &::after {
                @extend %fig-bell;
                color: #fff;
                font-size: 3rem;
                opacity: .5;
                position: absolute;
                top: 50%;
                right: 2rem;
                transform: translateY(-50%);
            }

            & > span {
                color: #fff;
                display: inline-block;
                font-size: 1.6rem;
                font-weight: bold;
                margin-left: 1.5rem;
            }
        }

        input:checked + .widget__content {
            background-color: var(--color-primary);

            &::after {
                @extend %fig-bell-ring;
                transform: translateY(-50%);
            }
        }
    }
}