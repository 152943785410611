.pellet { 
    border-radius: .5rem;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: .1em;
    padding: .5rem 2rem;
    text-transform: uppercase; 
}
.pellet-primary { 
    background-color: var(--color-primary);
    color: #fff;
}
.pellet-secondary { 
    background-color: var(--color-secondary);
    color: #fff;
}
.pellet-tertiary { 
    background-color: var(--color-tertiary);
    color: #fff;
}

.pellet-feat {
    border-radius: 1rem;
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1;
    padding: 1rem 5rem;
}