.collapse {

    &__tabs {
        border-bottom: 1px solid var(--color-tertiary-4);
        display: flex;
    }

    &__item {
        border-bottom: 2px solid transparent;
        color: $body-color;
        cursor: pointer;
        font-size: 2.2rem;
        line-height: 1;
        display: inline-block;
        padding: 1rem 2rem;

        &.active {
            border-color: var(--color-primary);
            color: var(--color-tertiary);
        }
    }

    &__content {
        @extend %is-hidden;
        height: 0;
        transition: none;

        &.active {
            @extend %is-visible;
            padding-top: 2rem;
            height: auto;
            transition: opacity $transition, visibility $transition;
        }
    }

}