.tabulator-1 {
    background-color: transparent;
    border: none;
    font-family: var(--font-primary);
}

.tabulator-1 .tabulator-tableholder .tabulator-table {
    background-color: transparent;
}

.tabulator-1 .tabulator-header {
    background-color: var(--color-tertiary);
    border: none;
    border-radius: .5rem;
    font-weight: normal;
}

.tabulator-1 .tabulator-header .tabulator-col {
    background-color: transparent;
    border-right: none;
    color: #fff;
    font-size: 1.8rem;
}
.tabulator-1 .tabulator-header .tabulator-col.tabulator-sortable.tabulator-col-sorter-element:hover {
    background-color: var(--color-primary);
}
.tabulator-1 .tabulator-header .tabulator-col .tabulator-col-content {
    padding: 1rem 1.5rem;
}
.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort=none] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
    border-bottom-color: #fff;
}
.tabulator-1 .tabulator-header .tabulator-col.tabulator-sortable:hover {
    background-color: var(--color-primary-light);
}

.tabulator-1 .tabulator-row {
    background-color: transparent;
}
.tabulator-1 .tabulator-row .tabulator-cell {
    border-right: 1px solid transparent;
    font-size: 1.8rem;
    padding: 1rem;
    white-space: normal;
}
.tabulator-1 .tabulator-table > .tabulator-row:not(:last-child) {
    border-bottom: 2px dashed var(--color-primary-white-2);
}

.tabulator-1 .tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
    background: var(--color-primary);
    font-size: 2.3rem;
    height: 3rem;
    width: 3rem;
}

.tabulator-1 .tabulator-row .tabulator-responsive-collapse tr > td:first-child {
    color: var(--color-secondary);
}

.tabulator-1 .tabulator-row.tabulator-row-even {
    background-color: transparent;
}

.tabulator-1 .tabulator-row.tabulator-selectable:hover {
    background-color: var(--color-primary-white-2);
    cursor: default;
}

.tabulator-1 .tabulator-footer {
    background-color: transparent;
    border-top-color: transparent;
}

.tabulator-1 .tabulator-footer .tabulator-paginator {
    padding-top: 10px;
    text-align: center;
}

.tabulator-1 .tabulator-footer .tabulator-page {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    font-size: 1.6rem;
    padding: .8rem 2rem;
}

.tabulator-1 .tabulator-footer .tabulator-page:hover {
    background-color: var(--color-primary);
    color: #fff;
}

.tabulator-1 .tabulator-footer .tabulator-page:disabled {
    cursor: default;
}

.tabulator-1 .tabulator-footer .tabulator-page:disabled,
.tabulator-1 .tabulator-footer .tabulator-page:disabled:hover {
    background-color: transparent;
    border: 1px solid var(--color-secondary-white-2);
    color: var(--color-secondary-white-2);
}

.tabulator-1 .tabulator-footer .tabulator-pages .tabulator-page {
    border: 1px solid var(--color-primary);
    border-radius: 4rem;
    color: var(--color-primary);
    display: inline-flex;
    font-size: 1.6rem;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 4rem;
    width: 4rem;
}

.tabulator-1 .tabulator-footer .tabulator-pages .tabulator-page.active {
    background-color: var(--color-primary);
    color: #fff;
    font-weight: bold;
}

.tabulator-1 .tabulator-footer .tabulator-pages .tabulator-page:hover:not(.active),
.tabulator-1 .tabulator-footer .tabulator-pages .tabulator-page:focus:not(.active) {
    background-color: var(--color-secondary-white-2);
    border-color: var(--color-secondary-white-2);
    color: var(--color-primary);
}

.tabulator-event-cell {
    display: flex;
    align-items: center;
}
.tabulator-event-cell .image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 1.5rem;
    width: 12.5rem;
    height: 8rem;
}
.tabulator-event-cell .image a {
    display: block;
    height: 100%;
}
.tabulator-event-cell .image img {
    border-radius: .5rem;
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.tabulator-event-cell .content > span:first-child {
    color: var(--color-secondary);
    display: block;
    font-size: 1.6rem;
    font-weight: bold;
}
.tabulator-event-cell .content > span:last-child {
    color: var(--color-tertiary);
    display: block;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.2;
}

.tabulator__loading {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    text-align: center;

    svg {
        margin-bottom: 2rem;
        animation: spinning 1.5s linear backwards infinite;
        * { fill: var(--color-primary-2); }
    }

    &__text {
        color: var(--color-primary-2);
        font-weight: 700;
    }
}

.tabulator__empty {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    text-align: center;

    svg {
        * { fill: var(--color-primary-2); }
    }

    &__text {
        color: var(--color-primary-2);
        font-weight: 700;
    }
}