.icon-text {
    display: flex;
    gap: 2rem;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 2rem;

    &__icon {
        @extend %flex-center-center;
        flex-shrink: 0;
        width: 9rem;
        height: 9rem;

        img {
            width: 60%;
        }
    }

    &__content {
        flex: 1 1 0;
        min-width: 30rem;
    }

    &__title {
        @extend %h4;
    }

    &--primary {
        .icon-text__icon { @extend %bubble-icon-primary-white; }
        .icon-text__title { color: var(--color-primary); }
    }
    &--tertiary3 {
        .icon-text__icon { @extend %bubble-icon-tertiary-white; }
        .icon-text__title { color: var(--color-tertiary-3); }
    }
    &--tertiary {
        .icon-text__icon { @extend %bubble-icon-tertiary-white; }
        .icon-text__title { color: var(--color-tertiary); }
    }
    &--secondary {
        .icon-text__icon { @extend %bubble-icon-secondary-white; }
        .icon-text__title { color: var(--color-secondary); }
    }
}