// Section banner
.section-banner {
    @extend %grid-base;
    @extend %section;
    position: relative;
    z-index: 5;
}

// Section goals
.section-goals {
    @extend %section;
    padding-top: 17.5rem;

    &::before {
        @include before-after-background($url: '/storage/double-exposure-businessman-working-on-digital-tablet-on-white-reversed-w1920-1.webp');
        opacity: .05;
    }

    & > * {
        @extend %zindex-1;
    }

    .container {
        @include create-grid-columns-left-full(37%);
        margin-bottom: 4rem;
    }

    .left {
        @extend %zindex-1;
        
        &::before {
            @include mask-image(
                $url: '/storage/icones/bubble-right.svg',
                $position: right,
            );
            @include before-after-background(
                $url: '/storage/pexels-proxyclick-visitor-management-system-2451567-w1000x1000-2.jpg',
                $top: -12rem,
                $left: auto,
                $width: 97rem,
                $height: 100rem,
            );
            right: 0;
        }
    }
}

// Section become member
.section-become-member {
    @extend %grid-base;
    @extend %section;
    @extend %pb-180;
    .section-title {
        margin-bottom: 10rem;
    }
}

// Section join us
.section-join-us {
    @extend %grid-base;
    @extend %section;
    @extend %line-section;
    @extend %zindex-1;
    background-color: var(--color-primary-white);

    &::before {
        @include before-after-background(
            $url: '/storage/pro-crowd-primary.svg',
            $bottom: 0,
            $left: 0,
            $width: 64.7rem,
            $height: 38.3rem,
        );
        opacity: .08;
    }

    &::after {
        z-index: 0;
    }
    
    .container {
        @extend %zindex-1;
        position: relative;
        .section-icon {
            position: absolute;
            top: -13.5rem;
        }
    }

    .section-content {
        width: 65%;
    }

    .section-overlay {
        @extend %background-cover-center;
        border-radius: 70rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%,-50%);
        height: 70rem;
        width: 70rem;
        z-index: 6;
    }
}

// Section events
.section-events {
    @extend %grid-base;
    @extend %section;
    @extend %pt-150;

    background-color: var(--color-grey-3);

    .container {
        @include create-grid-columns-custom(35% 65%);
    }
    .left {
        z-index: 1;
    }
    .right {
        position: relative;
        width: 100%;
    }
}
.events-carousel {
    .event-card {
        width: 50rem;
    }
}

// Section parnters
.section-partners {
    @extend %grid-base;
    @extend %section;
    @extend %pb-300;
    @include background-image($url: '/storage/businesswoman-leader-bg-section-w1920x900-1.webp');
}
