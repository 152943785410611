.button-primary {
    @extend %button;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: #fff;
    
    &:hover, &:focus {
        background-color: transparent;
        border-color: var(--color-primary);
        color: var(--color-primary);
    }
}
.button-primary-outline {
    @extend %button;
    background-color: transparent;
    border-color: var(--color-primary);
    color: var(--color-primary);
    
    &:hover, &:focus {
        background-color: var(--color-primary);
        color: #fff;
    }
}

.button-secondary-outline {
    @extend %button;
    background-color: transparent;
    border-color: var(--color-secondary);
    color: var(--color-secondary);
    
    &:hover, &:focus {
        background-color: var(--color-secondary);
        color: #fff;
    }
}

.button-neutral-outline {
    @extend %button;
    background-color: transparent;
    border-color: var(--color-grey);
    color: var(--color-grey);
    
    &:hover, &:focus {
        background-color: var(--color-grey);
        border-color: var(--color-grey);
        color: #fff;
    }
}

.button-white-outline {
    @extend %button;
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    
    &:hover, &:focus {
        background-color: #fff;
        color: var(--color-dark);
    }
}

.button-white-danger {
    @extend %button;
    background-color: #fff;
    border-color: #fff;
    color: var(--color-danger);
    
    &:hover, &:focus {
        background-color: transparent;
        color: #fff;
    }
}

.button-white-secondary {
    @extend %button;
    background-color: #fff;
    border-color: #fff;
    color: var(--color-secondary);
    
    &:hover, &:focus {
        background-color: transparent;
        color: #fff;
    }
}

.button--xs {
    font-size: 1.4rem;
    padding: .75rem 2rem;
}
.button--sm {
    font-size: 1.6rem;
    padding: 1rem 2.5rem;
}
.button--md {
    font-size: 1.7rem;
    padding: 1.5rem 3.5rem;
}


.button-icon-primary {
    @extend %button-icon;
    border-color: var(--color-primary);
    color: var(--color-primary);

    &:hover, &:focus {
        background-color: var(--color-primary);
        color: #fff;
    }
}
.button-icon--sm {
    border-radius: 4.5rem;
    height: 4.5rem;
    width: 4.5rem;
}


.btn-wrap {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 1rem 2rem;

    & > [class*="button-"] {
        min-width: 20rem;
    }

    &--center {
        justify-content: center;
    }
}