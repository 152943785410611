/* -----------------------------------------------
 *  Grid creation
 ------------------------------------------------- */

/// @mixin create-grid-base
/// 
/// Create a grid container with 3 columns :
/// 
/// - Left & right column = Blank spaces with a min width that is similar to 
///                         the padding left and right of the center column.
/// 
/// - Center column = Column that will, by default, contain all the content.
///                   That is what the nested selector tells, that all children will fit 
///                   into this column, and so will be centered into that grid.
/// 
/// @param $max-width - Center column maximum width
/// @param $grid-gap - Representation of the center column left & right padding 
///
@mixin create-grid-base($max-width: $grid-width-default, $grid-gap: $grid-gap-default) {
    display: grid;
    grid-template-columns: minmax($grid-gap, 1fr) minmax(0, $max-width) minmax($grid-gap, 1fr);

    & > * { 
        grid-column: 2 / -2; 
    }
}

/// @mixin create-grid-columns
/// 
/// Create a grid container with columns having the same width
/// 
/// @param $column-nbr - Number of grid columns
/// @param $grid-gap - Grid gap
///
@mixin create-grid-columns($column-nbr, $grid-gap: $grid-gap-default, $grid-gap-direction: 'xy') {
    @include grid-initialisation($grid-gap, $grid-gap-direction);
    grid-template-columns: repeat($column-nbr, 1fr);
}

/// @mixin create-grid-columns-custom
/// 
/// Create a grid container with regular grid-template-columns declaration
/// 
/// @param $columns - grid-template-columns regular declaration
/// @param $grid-gap - Grid gap
///
/// @example scss - Creating a grid with custom columns
///  @include create-grid-columns-custom(1fr 250px minmax(250px, 500px), 50px)
///
@mixin create-grid-columns-custom($columns: auto, $grid-gap: $grid-gap-default, $grid-gap-direction: 'xy') {
    @include grid-initialisation($grid-gap, $grid-gap-direction);
    grid-template-columns: $columns;
}

/// @mixin create-grid-columns-smart
/// 
/// Create a grid container with auto responsive columns, 
/// based on the grid column number and the grid width.
/// 
/// @param $column-nbr - Number of columns that the container has to have.
/// @param $grid-width - Actual container width
/// -> It's important to take the largest grid width as a reference for this mixin to work correctly.
/// @param $grid-gap - Grid gap
/// @param $break-sensitivity - Value that determines the sensitivity of columns to break when the grid get smaller
/// -> Recommendations & Tips :
///     1- Must be less than 1 ;
///     2- Should not be less than 0.7 - Below this value, the grid will probably have more columns than expected.  
///        But you can give it a try and see if below 0.7 the grid behaves correctly.  
///     3- You could use this variable at your advantage if, for example, the element have a lot of left and/or right padding.
///        In this case, the columns will break directly and you can have 3 columns instead of 4, which is the expected number that you choose.
///        So the trick is to use a value below than 0.7 to allow the columns to fit and match the expected result, 
///        but be careful not to lower the value too much.
///                
///
@mixin create-grid-columns-smart($column-nbr, $grid-width: $grid-width-default, $grid-gap: $grid-gap-default, $grid-gap-direction: 'xy', $break-sensitivity: .7) {
    @include grid-initialisation($grid-gap, $grid-gap-direction);

    $break-sensitivity-calc: 1;
    @if ($break-sensitivity < 1) {
        //$column-threeshold-calc: calc(($column-threeshold + ($column-nbr / 50)) + ($column-nbr / 50));
        $break-sensitivity-calc: calc($break-sensitivity + (($column-nbr / 90) * 2));
    } 

    // Calculate the min-width of each columns, depending on the columns number of the "$column-nbr" variable
    $column-min-width: calc((($grid-width - ($grid-gap * ($column-nbr - 1))) / $column-nbr) * $break-sensitivity-calc);
    grid-template-columns: repeat(auto-fit, minmax($column-min-width, 1fr));
}

@mixin create-grid-columns-left-full($left-width) {
    @extend %grid-init;
    
    $content-width-calc: ($grid-width-default + ($grid-gap-default * 2)) - (($grid-width-default + ($grid-gap-default * 2)) * (calc($left-width / 100%)));
    grid-template-columns: $left-width minmax(min-content, $content-width-calc) 1fr;
}

/* -----------------------------------------------
 *  Grid edit
 ------------------------------------------------- */

/// @mixin change-grid-columns
/// 
/// Update the grid columns of an element that is already displayed as a grid.
/// 
/// @param $column-nbr - Number of grid columns
/// @param $grid-gap - Grid gap
///
@mixin change-grid-columns($column-nbr, $grid-gap: 'null', $grid-gap-direction: 'xy') {
    grid-template-columns: repeat($column-nbr, 1fr);
    @if $grid-gap != 'null' and $grid-gap-direction == 'xy' {
        grid-gap: $grid-gap;
    } @else if $grid-gap != 'null' and $grid-gap-direction == 'x' {
        grid-column-gap: $grid-gap;
    }
}

/// @mixin change-grid-columns
/// 
/// Update the grid columns of an element that is already displayed as a grid.
/// 
/// @param $columns - grid-template-columns regular declaration
/// @param $grid-gap - Grid gap
///
@mixin change-grid-columns-custom($columns: auto, $grid-gap: 'null', $grid-gap-direction: 'xy') {
    grid-template-columns: $columns;
    @if $grid-gap != 'null' and $grid-gap-direction == 'xy' {
        grid-gap: $grid-gap;
    } @else if $grid-gap != 'null' and $grid-gap-direction == 'x' {
        grid-column-gap: $grid-gap;
    }
}

/// @mixin media-grid-columns
/// 
/// Update the grid columns of an element that is already displayed as a grid, at a certain breakpoint.
///
/// -> Note: This mixin uses the "media" mixin of the Include-media SASS vendor, 
///         that affects how to wright the "$breakpoint" variable.
///         Feel free to see how this vendor works by checking it's file in ../../vendor/_include-media.scss
/// 
/// @param $column-nbr - Number of grid columns
/// @param $breakpoint - Media breakpoint where the column has to change
/// -> Check how to wright a correct value for the breakpoint in this file : ../../vendor/_include-media.scss
/// @param $grid-gap - Grid gap
///
@mixin media-grid-columns($column-nbr, $breakpoint, $grid-gap: 'null') {
    @include media($breakpoint) {
        @include change-grid-columns($column-nbr, $grid-gap);
    }
}


/* -----------------------------------------------
 *  Grid helpers
 ------------------------------------------------- */

/// @mixin grid-initialisation
/// 
/// Initialize a grid container with extend functions, depending on the "$grid-gap" variable
/// 
@mixin grid-initialisation($grid-gap, $grid-gap-direction) {
    @if $grid-gap == $grid-gap-default and $grid-gap-direction == 'xy' {
        @extend %grid-init;
    } @else if $grid-gap == $grid-gap-default and $grid-gap-direction == 'x' {
        @extend %grid-init-x;
    } @else if $grid-gap == $grid-gap-rows and $grid-gap-direction == 'xy' {
        @extend %grid-row-init;
    } @else if $grid-gap == $grid-gap-rows and $grid-gap-direction == 'x' {
        @extend %grid-row-init-x;
    } @else {
        display: grid;
        grid-gap: $grid-gap;
    }
}


