.banner {
    @extend %line-section;
    background-color: var(--color-tertiary);
    min-height: 85rem;

    .container {
        padding-top: 125px;
        @include create-grid-columns-custom(55% 45%);
        position: relative;
        z-index: 1;

        .left {
            padding-top: 50px;
        } 
    }

    &__overlays {
        @extend %grid-item-full-important;
        background: url('/storage/pro-crowd.svg') -10% 155% no-repeat;
        background-size: 110rem;
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;

        &::before {
            @extend %bubble-overlay-1;
            opacity: .35;
        }
    }

    &__suptitle {
        @extend %h4;
        @extend %line-after;
        color: #fff;
        font-weight: normal;
        letter-spacing: .1em;
        text-transform: uppercase;
    }

    &__title {
        @extend %h1;
        @extend %letters-style;
        color: #fff;
        font-weight: normal;
        letter-spacing: .08em;
        text-transform: uppercase;
    }

    &__breadcrumb {
        .breadcrumb {
            @extend %flex-align-center;
            list-style: none;
            li {
                color: #fff;
            }
            li:not(:last-child) {
                position: relative;
                &::after {
                    content: "►";
                    color: var(--color-primary);
                    display: inline-block;
                    font-size: .8em;
                    padding: 0 1rem;
                }
            }
            li:last-child {
                opacity: .7;
            }
        }
    }
}

// Page header
.banner--pageHeader {
    height: 35rem;
    min-height: 0;

    &::after { display: none; }

    .container {
        padding-top: 11.5rem;
        display: block;
    }

    .banner__overlays {
        background: none;
    }

    .banner__title {
        margin-bottom: 1rem;
    }
}

// 404
.banner--page404 {
    .container {
        display: block;
    }

    .title-404 {
        @extend %line-after-secondary;
        position: relative;
        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}