.header {
    &__menu--mobile { 
        display: none; 
        margin-bottom: 0;
    }
}

@include media('<header') {
    .header {
        &__nav {
            background-color: rgba(#16263F, .6);
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            opacity: 0;
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100vw;
            visibility: hidden;
            z-index: 999;
            transition: opacity $transition, visibility $transition;
        }

        &__menu, &__menu--mobile {
            @include is-hidden;
            background-color: var(--color-dark);
            width: 40rem;
            transform: translateX(-100%);
            transition: opacity $transition, transform $transition, visibility $transition;
        }

        &__menu {
            flex-grow: 1;
            flex-flow: column;
            align-items: stretch;
            padding: 8rem 3rem 3rem 3rem;
            top: 0;
            margin-bottom: 0;

            li {
                margin-bottom: 1rem;
            }
            li > a {
                display: flex;
                align-items: center;
                font-size: 2.2rem;

                &::before {
                    content: "►";
                    color: var(--color-primary);
                    display: inline-block;
                    font-size: .8em;
                    margin-right: 1.5rem;
                }
            }
        }

        &__mobile-open {
            cursor: pointer;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            margin-left: 3rem;
            position: relative;
            height: 3rem;
            width: 4rem;

            &::before, & > span, &::after {
                background-color: #fff;
                border-radius: 99rem;
                display: inline-block;
                height: 3px;
                transition: $transition;
            }
            &::before {
                content: "";
                width: 100%;
            }
            span {
                width: 75%;
            }
            &::after {
                content: "";
                width: 50%;
            }

            &:hover, &:focus {
                &::before, & > span, &::after {
                    background-color: var(--color-primary);
                    width: 100%;
                }
            }
        }

        &__mobile-close {
            @include flex-full-center;
            @include shape-elt($width: 5rem, $rounded: 'round');
            background-color: var(--color-tertiary-2);   
            cursor: pointer;
            position: absolute;
            top: 2rem;
            left: 32rem;
            transform: translateX(-100%);
            transition: $transition;

            &::after {
                @include uicons-regular-rounded;
                content: "\f27c";
                color: #fff;
            }

            &:hover, &:focus {
                background-color: var(--color-primary);
            }
        }
    }

    .header.is-open {

        .header__nav {
            @include is-visible;
        }   
        .header__mobile-close, .header__menu, .header__menu--mobile {
            @include is-visible;
            transform: translateX(0);
        }  
    }
}

@include media('<sm') {
    .header {

        &__wrap {
            justify-content: space-between;
        }

        &__extra {
            display: none;
        }

        &__menu {
            overflow-y: scroll;
            -ms-overflow-style: none; 
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &__menu--mobile {
            border-top: 2px solid var(--color-tertiary-2);
            display: block;
            padding: 3rem;
            bottom: 0;

            li { 
                text-align: center;

                &:not(:last-child) {
                    margin-bottom: 2.5rem;
                }
            }
        }
    }
}