.filter {
    @extend %flex-center-end;
    margin-bottom: 3rem;

    &__wrap {
        @extend %new-stack;
        background-color: #fff;
        border-radius: 99rem;
        display: inline-block;
        padding: 1.5rem 3rem 1.5rem 6rem;
        z-index: 1;

        &::before {
            content: "";
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 50%;
            width: 999rem;
            height: 100%;
            z-index: -1;
        }
    }

    &__categories {
        &__select {
            min-width: 30rem;
        }
    }
}