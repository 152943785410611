.footer {
    @extend %grid-base;
    @extend %line-section-before-tertiary-3;
    @extend %pt-200;
    background-color: var(--color-tertiary);
    color: #fff;
    position: relative;
    z-index: 5;

    &::after {
        @include before-after-background(
            $url: '/storage/arriere-plan/section-line-secondary.svg',
            $width: 15rem,
            $height: 2.1rem,
            $top: 7rem,
            $left: 50%,
        );
        transform: translateX(-50%) rotate(90deg);
        z-index: 6;
    }

    &__main {
        @extend %pb-100;
        @include create-grid-columns-smart(
            $column-nbr: 3,
            $grid-gap: 6rem,
        );
        
        background-color: var(--color-tertiary);
        position: relative;

        & > * {
            @extend %zindex-1;
        }

        &::before {
            @extend %bubble-overlay-2;
            width: 70rem;
            height: 70rem;
            top: -8rem;
            right: -26rem;
        }

        &::after {
            @include before-after-background-shape(
                $color: var(--color-tertiary-3),
                $width: 60rem, 
                $bottom: -5rem,
                $left: -50rem,
                $opacity: .1,
                $rounded: 'true',
            );
        }
    }

    &__logo {
        display: block;
        margin: 0 auto 3rem auto;
        max-width: 35rem; 
    }

    &__slogan {
        @extend %h5;
        @extend %letters-style;
        color: currentColor;
        font-weight: normal;
        margin-bottom: 3rem;
        text-align: center;
    }

    &__title {
        @extend %h5;
        @extend %line-after-secondary;
        color: currentColor;
        margin-bottom: 3rem;
    }

    &__bottom {
        padding: 3.5rem 2rem;
        position: relative;
        text-align: center;

        &::before {
            content: "";
            background-color: var(--color-tertiary-3);
            position: absolute;
            top: 0;
            left: 50%;
            height: 1px;
            width: 100vw;
            transform: translateX(-50%);
        }
        
        p {
            font-size: 1.6rem;
            letter-spacing: .05em;
            opacity: .7;

            a { text-decoration: underline; }
        }
    }
}