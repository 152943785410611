// Stable 1
.stable {
    margin-bottom: 2rem;

    &__head {
        @extend .fieldset__legend;
        margin-bottom: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__item {
        color: $heading-color;
        padding: 1.5rem;
    }

    &__body {
        & > .stable__item:not(:last-child) {
            border-bottom: 3px dashed var(--color-tertiary-4);
        }
    }

    &__label {
        span:first-child {
            display: flex;
            align-items: center;
            font-size: 2.2rem;
            font-weight: bold;
            letter-spacing: .1em;
            line-height: 1;
            position: relative;
            text-transform: uppercase;

            &::before {
                content: "►";
                color: var(--color-primary);
                display: inline-block;
                font-size: 1.7rem;
                margin-right: 1rem;
            }
        }
        span:last-child { 
            font-style: italic; 
            font-size: 2rem;
        }
    }

    &__value {
        font-size: 3.5rem;
        font-weight: bold;
    }

    // Variant 1
    &--1 {
        .stable__head { background-color: var(--color-primary); }

        .stable__label span:first-child::before,
        .stable__item--feat { 
            color: var(--color-primary); 
        }

        .stable__body {
            & > .stable__item:not(:last-child) {
                border-color: var(--color-primary-white-2);
            }
        }
    }
    
    // Variant 2
    &--2 {
        .stable__head { background-color: var(--color-secondary); }

        .stable__label span:first-child::before,
        .stable__item--feat { 
            color: var(--color-secondary); 
        }

        .stable__body {
            & > .stable__item:not(:last-child) {
                border-color: var(--color-secondary-white-2);
            }
        }
    }
}

.stable-label {
    & > &__item:not(:last-child) {
        border-bottom: 3px dashed var(--color-tertiary-4);
        padding-bottom: 2rem;
        margin-bottom: 2rem;
    }

    &__item {
        @extend %flex-center-between;
        flex-flow: row wrap;
        span { 
            display: block;
        }
        span:first-child {
            color: var(--color-tertiary);
            flex-shrink: 0;
            font-size: 1.8rem;
            font-weight: bold;
            min-width: 15rem;
        }
        span:last-child {
            color: var(--color-primary);
            flex: 1 1 0;
            font-size: 2.2rem;
            font-weight: bold;
            text-align: right;
        }
    }
}