.section-icon {
    @extend %bubble-icon;
}

.section-grid {
    @extend %grid-base;
}

.section-title {
    margin-bottom: 3.5rem;

    &__sup {
        color: var(--color-primary);
        font-size: 2rem;
        letter-spacing: .1em;
        margin-bottom: .5rem;
        text-transform: uppercase;
    }

    &__main {
        @extend %h2;
        @extend %line-after-secondary;
        margin-bottom: 0;
        text-transform: uppercase;
    }

    &--center {
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        .section-title__main {
            &::after {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &--white {
        .section-title__sup {
            color: #fff;
        }
        .section-title__main {
            @extend %line-after-white;
            color: #fff;
        }
    }

    &--1 {
        position: relative;
        z-index: 0;

        &::before {
            @include before-after-background(
                $url: '/storage/arriere-plan/section-line-grey-3.svg',
                $left: 50%,
                $top: 50%,
                $height: 65rem,
                $width: 100vw,
            );
            transform: translate(-50%,-50%) scaleX(-1);
        }

        & > *, & + * { @extend %zindex-1; }
    }

    &--lineless {
        .section-title__main::after { display: none; }
    }
}