.quill-editor {

    // STYLE 1
    &--1 {
        
        .ql-toolbar {
            border: 2px solid var(--color-primary-white-2);
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        }
        .quill-editor__editor { 
            background-color: #fff;
            border: 2px solid var(--color-primary-white-2);
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            min-height: 150px; 
        }
        .ql-editor {
            font-family: var(--font-family-1st);
            p { font-size: 1.7rem; }
        }
        .quill-editor__input {
            display: none;
        }
    }

}