.block-quote {
    margin: 0 auto;
    position: relative;
    isolation: isolate;
    text-align: center;
    max-width: 110rem;

    &::before {
        content: '';
        background:url('/storage/icones/bubble-right-quote-primary.svg') center center;
        background-size: cover;
        opacity: .1;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 150px;
        width: 150px;
        transform: translate(-50%,-50%);
        z-index: -1;
    }

    p {
        font-size: 2.2rem;
        font-style: italic;
        margin-bottom: 1rem;
    }
}

.profile-image {
    background-color: #fff;
    border-radius: 999rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 20rem;
    height: 20rem;

    img { 
        //transform: translate(-50%,-50%);
        width: 100%;
    }

    .mt-overlay {
        @extend %is-hidden;
        background-color: rgba(22, 38, 63, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: $transition;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            i { 
                color: #fff; 
                font-size: 2.5rem;
            }
        }
    }

    &:hover {
        .mt-overlay {
            @extend %is-visible;
        }
    }
}

.dynacontent {
    & > *:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    &--md { font-size: 2rem; }
}

.flex-center-between-w { @extend %flex-center-between-w; }

.hr-1 {
    border: none;
    border-top: 3px dashed var(--color-tertiary-4);
    margin: 2rem 0;
}

.modal {
    .btn-primary { @extend .button-primary; }
    .btn-secondary { @extend .button-neutral-outline; }
}

.mt-card-avatar { 
    display: flex;
    align-items: center;
    justify-content: center;
    mask-image: none !important;
    overflow: visible;
    height: 100%;
    width: 100%;
}

.picture-update {
    border-radius: 1.5rem;
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;
    overflow: hidden;

    &__image {
        border-radius: 999rem;
        display: block;
        margin-bottom: 1.5rem;
        max-width: 20rem;
    }
    
    &__input { display: none; }
}

.row-separator {
    border-top: 3px dashed var(--color-tertiary-4);
    padding-top: 2rem;
}

.text-danger { color: var(--color-danger); font-weight: bold; }
.text-success { color: var(--color-success); font-weight: bold; }