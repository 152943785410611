/* -----------------------------------------------
 *  Google fonts
 ------------------------------------------------- */
 @if (length($google-fonts) != 0) {
    @import url('https://fonts.googleapis.com/css2?family=#{get-google-fonts-import()}');
}


/* -----------------------------------------------
 *  Uicons fonts

    -> If you want to use an icon, you have to copy and paste this one in the appropriate SASS array
        You can then extend this font with its name.
        ° Examples: "add-folder" is a "General Icon" and can be used as "@extend %fig-add-folder" and also "<i class="fig-add-folder"></i>"
                    "facebook" is a "Brand Icon" and can be used as "@extend %fib-facebook" and also "<i class="fib-facebook"></i>"


    >>> GENERAL ICONS ($uicons-fonts)
    "a":"\f101",
    "ad":"\f102",
    "add-document":"\f103",
    "add-folder":"\f104",
    "add":"\f105",
    "address-book":"\f106",
    "air-conditioner":"\f107",
    "air-freshener":"\f108",
    "alarm-clock":"\f109",
    "alarm-exclamation":"\f10a",
    "alarm-plus":"\f10b",
    "alarm-snooze":"\f10c",
    "align-justify":"\f10d",
    "align-left":"\f10e",
    "ambulance":"\f10f",
    "angle-circle-down":"\f110",
    "angle-circle-left":"\f111",
    "angle-circle-right":"\f112",
    "angle-circle-up":"\f113",
    "angle-double-left":"\f114",
    "angle-double-right":"\f115",
    "angle-double-small-down":"\f116",
    "angle-double-small-left":"\f117",
    "angle-double-small-right":"\f118",
    "angle-double-small-up":"\f119",
    "angle-down":"\f11a",
    "angle-left":"\f11b",
    "angle-right":"\f11c",
    "angle-small-down":"\f11d",
    "angle-small-left":"\f11e",
    "angle-small-right":"\f11f",
    "angle-small-up":"\f120",
    "angle-square-down":"\f121",
    "angle-square-left":"\f122",
    "angle-square-right":"\f123",
    "angle-square-up":"\f124",
    "angle-up":"\f125",
    "angry":"\f126",
    "apple-whole":"\f127",
    "apps-add":"\f128",
    "apps-delete":"\f129",
    "apps-sort":"\f12a",
    "apps":"\f12b",
    "archive":"\f12c",
    "arrow-alt-circle-down":"\f12d",
    "arrow-alt-circle-left":"\f12e",
    "arrow-alt-circle-right":"\f12f",
    "arrow-alt-circle-up":"\f130",
    "arrow-alt-down":"\f131",
    "arrow-alt-from-bottom":"\f132",
    "arrow-alt-from-left":"\f133",
    "arrow-alt-from-right":"\f134",
    "arrow-alt-from-top":"\f135",
    "arrow-alt-left":"\f136",
    "arrow-alt-right":"\f137",
    "arrow-alt-square-down":"\f138",
    "arrow-alt-square-left":"\f139",
    "arrow-alt-square-right":"\f13a",
    "arrow-alt-square-up":"\f13b",
    "arrow-alt-to-bottom":"\f13c",
    "arrow-alt-to-left":"\f13d",
    "arrow-alt-to-right":"\f13e",
    "arrow-alt-to-top":"\f13f",
    "arrow-alt-up":"\f140",
    "arrow-circle-down":"\f141",
    "arrow-circle-left":"\f142",
    "arrow-circle-right":"\f143",
    "arrow-circle-up":"\f144",
    "arrow-down":"\f145",
    "arrow-from-bottom":"\f146",
    "arrow-from-left":"\f147",
    "arrow-from-right":"\f148",
    "arrow-from-top":"\f149",
    "arrow-left":"\f14a",
    "arrow-right":"\f14b",
    "arrow-small-down":"\f14c",
    "arrow-small-left":"\f14d",
    "arrow-small-right":"\f14e",
    "arrow-small-up":"\f14f",
    "arrow-square-down":"\f150",
    "arrow-square-left":"\f151",
    "arrow-square-right":"\f152",
    "arrow-square-up":"\f153",
    "arrow-to-bottom":"\f154",
    "arrow-to-left":"\f155",
    "arrow-to-right":"\f156",
    "arrow-to-top":"\f157",
    "arrow-up":"\f158",
    "arrows-alt-h":"\f159",
    "arrows-alt-v":"\f15a",
    "arrows-alt":"\f15b",
    "arrows-h-copy":"\f15c",
    "arrows-h":"\f15d",
    "arrows":"\f15e",
    "assept-document":"\f15f",
    "assistive-listening-systems":"\f160",
    "asterik":"\f161",
    "at":"\f162",
    "aubergine":"\f163",
    "avocado":"\f164",
    "b":"\f165",
    "baby-carriage":"\f166",
    "backpack":"\f167",
    "bacon":"\f168",
    "badge-sheriff":"\f169",
    "badge-check":"\f16a",
    "badge-dollar":"\f16b",
    "badge-percent":"\f16c",
    "badge":"\f16d",
    "bags-shopping":"\f16e",
    "bahai":"\f16f",
    "balance-scale-left":"\f170",
    "balance-scale-right":"\f171",
    "ball":"\f172",
    "balloons":"\f173",
    "ballot":"\f174",
    "ban":"\f175",
    "band-aid":"\f176",
    "bank":"\f177",
    "barber-shop":"\f178",
    "bars-progress":"\f179",
    "baseball-alt":"\f17a",
    "baseball":"\f17b",
    "basket":"\f17c",
    "basketball":"\f17d",
    "bed-alt":"\f17e",
    "bed":"\f17f",
    "beer":"\f180",
    "bell-ring":"\f181",
    "bell-school":"\f182",
    "bell":"\f183",
    "bike":"\f184",
    "biking-mountain":"\f185",
    "biking":"\f186",
    "billiard":"\f187",
    "blinds-open":"\f188",
    "blinds-raised":"\f189",
    "blinds":"\f18a",
    "bold":"\f18b",
    "bolt":"\f18c",
    "book-alt":"\f18d",
    "book-arrow-right":"\f18e",
    "book-arrow-up":"\f18f",
    "book-bookmark":"\f190",
    "book-open-cover":"\f191",
    "book-open-reader":"\f192",
    "book":"\f193",
    "bookmark":"\f194",
    "books":"\f195",
    "bottle":"\f196",
    "bow-arrow":"\f197",
    "bowling-ball":"\f198",
    "bowling-pins":"\f199",
    "bowling":"\f19a",
    "box-check":"\f19b",
    "box-tissue":"\f19c",
    "box-alt":"\f19d",
    "box-ballot":"\f19e",
    "box":"\f19f",
    "boxes":"\f1a0",
    "boxing-glove":"\f1a1",
    "braille":"\f1a2",
    "bread-slice":"\f1a3",
    "bread":"\f1a4",
    "briefcase":"\f1a5",
    "broccoli":"\f1a6",
    "broom":"\f1a7",
    "browser":"\f1a8",
    "brush":"\f1a9",
    "bug":"\f1aa",
    "building":"\f1ab",
    "bulb":"\f1ac",
    "bullseye-pointer":"\f1ad",
    "bullseye":"\f1ae",
    "burrito":"\f1af",
    "bus-alt":"\f1b0",
    "bus":"\f1b1",
    "business-time":"\f1b2",
    "butterfly":"\f1b3",
    "cake-birthday":"\f1b4",
    "cake-wedding":"\f1b5",
    "calculator":"\f1b6",
    "calendar-check":"\f1b7",
    "calendar-clock":"\f1b8",
    "calendar-exclamation":"\f1b9",
    "calendar-lines-pen":"\f1ba",
    "calendar-lines":"\f1bb",
    "calendar-minus":"\f1bc",
    "calendar-pen":"\f1bd",
    "calendar-plus":"\f1be",
    "calendar":"\f1bf",
    "calendars":"\f1c0",
    "call-history":"\f1c1",
    "call-incoming":"\f1c2",
    "call-missed":"\f1c3",
    "call-outgoing":"\f1c4",
    "camcorder":"\f1c5",
    "camera":"\f1c6",
    "camping":"\f1c7",
    "candy-alt":"\f1c8",
    "candy":"\f1c9",
    "canned-food":"\f1ca",
    "car-bolt":"\f1cb",
    "car-circle-bolt":"\f1cc",
    "car-side-bolt":"\f1cd",
    "car-alt":"\f1ce",
    "car-battery":"\f1cf",
    "car-building":"\f1d0",
    "car-bump":"\f1d1",
    "car-bus":"\f1d2",
    "car-crash":"\f1d3",
    "car-garage":"\f1d4",
    "car-mechanic":"\f1d5",
    "car-side":"\f1d6",
    "car-tilt":"\f1d7",
    "car-wash":"\f1d8",
    "car":"\f1d9",
    "caravan-alt":"\f1da",
    "caravan":"\f1db",
    "caret-circle-down":"\f1dc",
    "caret-circle-right":"\f1dd",
    "caret-circle-up":"\f1de",
    "caret-down":"\f1df",
    "caret-left":"\f1e0",
    "caret-quare-up":"\f1e1",
    "caret-right":"\f1e2",
    "caret-square-down":"\f1e3",
    "caret-square-left_1":"\f1e4",
    "caret-square-left":"\f1e5",
    "caret-square-right":"\f1e6",
    "caret-up":"\f1e7",
    "carrot":"\f1e8",
    "cars":"\f1e9",
    "cash-register":"\f1ea",
    "chair-office":"\f1eb",
    "chair":"\f1ec",
    "charging-station":"\f1ed",
    "chart-area":"\f1ee",
    "chart-connected":"\f1ef",
    "chart-histogram":"\f1f0",
    "chart-line-up":"\f1f1",
    "chart-network":"\f1f2",
    "chart-pie-alt":"\f1f3",
    "chart-pie":"\f1f4",
    "chart-pyramid":"\f1f5",
    "chart-scatter":"\f1f6",
    "chart-set-theory":"\f1f7",
    "chart-tree":"\f1f8",
    "chat-arrow-down":"\f1f9",
    "chat-arrow-grow":"\f1fa",
    "check":"\f1fb",
    "checkbox":"\f1fc",
    "cheese":"\f1fd",
    "cherry":"\f1fe",
    "chess-piece":"\f1ff",
    "chess-bishop":"\f200",
    "chess-board":"\f201",
    "chess-clock-alt":"\f202",
    "chess-clock":"\f203",
    "chess-king-alt":"\f204",
    "chess-king":"\f205",
    "chess-knight-alt":"\f206",
    "chess-knight":"\f207",
    "chess-pawn-alt":"\f208",
    "chess-queen-alt":"\f209",
    "chess-queen":"\f20a",
    "chess-rook-alt":"\f20b",
    "chess-rook":"\f20c",
    "chess":"\f20d",
    "chevron-double-down":"\f20e",
    "chevron-double-up":"\f20f",
    "child-head":"\f210",
    "chocolate":"\f211",
    "circle-phone":"\f212",
    "circle-envelope":"\f213",
    "circle-phone-flip":"\f214",
    "circle-phone-hangup":"\f215",
    "circle-small":"\f216",
    "circle":"\f217",
    "clip":"\f218",
    "clipboard-list":"\f219",
    "clipboard-list-check":"\f21a",
    "clock-eight-thirty":"\f21b",
    "clock-eleven-thirty":"\f21c",
    "clock-eleven":"\f21d",
    "clock-five-thirty":"\f21e",
    "clock-five":"\f21f",
    "clock-four-thirty":"\f220",
    "clock-nine-thirty":"\f221",
    "clock-nine":"\f222",
    "clock-one-thirty":"\f223",
    "clock-one":"\f224",
    "clock-seven-thirty":"\f225",
    "clock-seven":"\f226",
    "clock-six-thirty":"\f227",
    "clock-six":"\f228",
    "clock-ten-thirty":"\f229",
    "clock-ten":"\f22a",
    "clock-three-thirty":"\f22b",
    "clock-three":"\f22c",
    "clock-twelve-thirty":"\f22d",
    "clock-twelve":"\f22e",
    "clock-two-thirty":"\f22f",
    "clock-two":"\f230",
    "clock":"\f231",
    "cloud-upload":"\f232",
    "cloud-check":"\f233",
    "cloud-disabled":"\f234",
    "cloud-download-alt":"\f235",
    "cloud-download":"\f236",
    "cloud-drizzle":"\f237",
    "cloud-hail-mixed":"\f238",
    "cloud-hail":"\f239",
    "cloud-moon-rain":"\f23a",
    "cloud-moon":"\f23b",
    "cloud-rain":"\f23c",
    "cloud-rainbow":"\f23d",
    "cloud-share":"\f23e",
    "cloud-showers-heavy":"\f23f",
    "cloud-showers":"\f240",
    "cloud-sleet":"\f241",
    "cloud-snow":"\f242",
    "cloud-sun-rain":"\f243",
    "cloud-sun":"\f244",
    "cloud-upload-alt":"\f245",
    "cloud":"\f246",
    "clouds-moon":"\f247",
    "clouds-sun":"\f248",
    "clouds":"\f249",
    "club":"\f24a",
    "cocktail-alt":"\f24b",
    "cocktail":"\f24c",
    "coffee-pot":"\f24d",
    "coffee":"\f24e",
    "coin":"\f24f",
    "coins":"\f250",
    "comet":"\f251",
    "comment-alt-middle-top":"\f252",
    "comment-alt-middle":"\f253",
    "comment-alt":"\f254",
    "comment-arrow-down":"\f255",
    "comment-arrow-up-right":"\f256",
    "comment-arrow-up":"\f257",
    "comment-check":"\f258",
    "comment-code":"\f259",
    "comment-dollar":"\f25a",
    "comment-exclamation":"\f25b",
    "comment-heart":"\f25c",
    "comment-image":"\f25d",
    "comment-info":"\f25e",
    "comment-pen":"\f25f",
    "comment-question":"\f260",
    "comment-quote":"\f261",
    "comment-slash":"\f262",
    "comment-sms":"\f263",
    "comment-text":"\f264",
    "comment-user":"\f265",
    "comment-xmark":"\f266",
    "comment":"\f267",
    "comments-dollar":"\f268",
    "comments-question-check":"\f269",
    "comments-question":"\f26a",
    "comments":"\f26b",
    "compress-alt":"\f26c",
    "compress":"\f26d",
    "computer":"\f26e",
    "confetti":"\f26f",
    "cookie":"\f270",
    "copy-alt":"\f271",
    "copy":"\f272",
    "copyright":"\f273",
    "corn":"\f274",
    "cow":"\f275",
    "cream":"\f276",
    "credit-card":"\f277",
    "cricket":"\f278",
    "croissant":"\f279",
    "cross-circle":"\f27a",
    "cross-small":"\f27b",
    "cross":"\f27c",
    "crown":"\f27d",
    "crystal-ball":"\f27e",
    "cube":"\f27f",
    "cupcake":"\f280",
    "curling":"\f281",
    "cursor-finger":"\f282",
    "cursor-plus":"\f283",
    "cursor-text-alt":"\f284",
    "cursor-text":"\f285",
    "cursor":"\f286",
    "d":"\f287",
    "dart":"\f288",
    "dashboard":"\f289",
    "data-transfer":"\f28a",
    "database":"\f28b",
    "delete-document":"\f28c",
    "delete-user":"\f28d",
    "delete":"\f28e",
    "democrat":"\f28f",
    "dewpoint":"\f290",
    "diamond":"\f291",
    "dice-alt":"\f292",
    "dice-d10":"\f293",
    "dice-d12":"\f294",
    "dice-d20":"\f295",
    "dice-d4":"\f296",
    "dice-d6":"\f297",
    "dice-d8":"\f298",
    "dice-four":"\f299",
    "dice-one":"\f29a",
    "dice-six":"\f29b",
    "dice-three":"\f29c",
    "dice-two":"\f29d",
    "dice":"\f29e",
    "diploma":"\f29f",
    "disco-ball":"\f2a0",
    "disk":"\f2a1",
    "dizzy":"\f2a2",
    "doctor":"\f2a3",
    "document-signed":"\f2a4",
    "document":"\f2a5",
    "dollar":"\f2a6",
    "donate":"\f2a7",
    "down-left-and-up-right-to-center":"\f2a8",
    "download":"\f2a9",
    "drafting-compass":"\f2aa",
    "dreidel":"\f2ab",
    "drink-alt":"\f2ac",
    "drumstick":"\f2ad",
    "duplicate":"\f2ae",
    "e-learning":"\f2af",
    "e":"\f2b0",
    "earnings":"\f2b1",
    "eclipse-alt":"\f2b2",
    "eclipse":"\f2b3",
    "edit-alt":"\f2b4",
    "edit":"\f2b5",
    "egg-fried":"\f2b6",
    "egg":"\f2b7",
    "engine-warning":"\f2b8",
    "enter":"\f2b9",
    "envelope-ban":"\f2ba",
    "envelope-bulk":"\f2bb",
    "envelope-download":"\f2bc",
    "envelope-marker":"\f2bd",
    "envelope-open-dollar":"\f2be",
    "envelope-open-text":"\f2bf",
    "envelope-open":"\f2c0",
    "envelope-plus":"\f2c1",
    "envelope":"\f2c2",
    "equality":"\f2c3",
    "euro":"\f2c4",
    "exchange-alt":"\f2c5",
    "exchange":"\f2c6",
    "exclamation":"\f2c7",
    "exit":"\f2c8",
    "expand-arrows-alt":"\f2c9",
    "expand-arrows":"\f2ca",
    "expand":"\f2cb",
    "eye-crossed":"\f2cc",
    "eye-dropper":"\f2cd",
    "eye":"\f2ce",
    "f":"\f2cf",
    "feather":"\f2d0",
    "ferris-wheel":"\f2d1",
    "fighter-jet":"\f2d2",
    "file-invoice-dollar":"\f2d3",
    "file-invoice":"\f2d4",
    "file-ai":"\f2d5",
    "file-chart-line":"\f2d6",
    "file-chart-pie":"\f2d7",
    "file-eps":"\f2d8",
    "file-psd":"\f2d9",
    "file-spreadsheet":"\f2da",
    "file-video":"\f2db",
    "file":"\f2dc",
    "fill":"\f2dd",
    "film-slash":"\f2de",
    "film":"\f2df",
    "filter-slash":"\f2e0",
    "filter":"\f2e1",
    "filters":"\f2e2",
    "fingerprint":"\f2e3",
    "fish":"\f2e4",
    "flag-alt":"\f2e5",
    "flag-usa":"\f2e6",
    "flag":"\f2e7",
    "flame":"\f2e8",
    "flip-horizontal":"\f2e9",
    "flower-bouquet":"\f2ea",
    "flower-tulip":"\f2eb",
    "flower":"\f2ec",
    "flushed":"\f2ed",
    "fog":"\f2ee",
    "folder-download":"\f2ef",
    "folder-minus":"\f2f0",
    "folder-times":"\f2f1",
    "folder-tree":"\f2f2",
    "folder-upload":"\f2f3",
    "folder":"\f2f4",
    "folders":"\f2f5",
    "following":"\f2f6",
    "football":"\f2f7",
    "fork":"\f2f8",
    "form":"\f2f9",
    "forward":"\f2fa",
    "fox":"\f2fb",
    "french-fries":"\f2fc",
    "frown":"\f2fd",
    "ftp":"\f2fe",
    "funnel-dollar":"\f2ff",
    "g":"\f300",
    "gallery":"\f301",
    "game-board-alt":"\f302",
    "gamepad":"\f303",
    "garage-car":"\f304",
    "garage-open":"\f305",
    "garage":"\f306",
    "garlic":"\f307",
    "gas-pump-alt":"\f308",
    "gas-pump-slash":"\f309",
    "gas-pump":"\f30a",
    "gem":"\f30b",
    "gif":"\f30c",
    "gift-card":"\f30d",
    "gift":"\f30e",
    "gifts":"\f30f",
    "gingerbread-man":"\f310",
    "glass-cheers":"\f311",
    "glass":"\f312",
    "glasses":"\f313",
    "globe-alt":"\f314",
    "globe":"\f315",
    "golf-ball":"\f316",
    "golf":"\f317",
    "graduation-cap":"\f318",
    "grape":"\f319",
    "graphic-tablet":"\f31a",
    "grid-alt":"\f31b",
    "grid":"\f31c",
    "grill":"\f31d",
    "grimace":"\f31e",
    "grin-alt":"\f31f",
    "grin-beam-sweat":"\f320",
    "grin-beam":"\f321",
    "grin-hearts":"\f322",
    "grin-squint-tears":"\f323",
    "grin-squint":"\f324",
    "grin-stars":"\f325",
    "grin-tears":"\f326",
    "grin-tongue-squint":"\f327",
    "grin-tongue-wink":"\f328",
    "grin-tongue":"\f329",
    "grin-wink":"\f32a",
    "grin":"\f32b",
    "guitar":"\f32c",
    "gym":"\f32d",
    "h":"\f32e",
    "hamburger-soda":"\f32f",
    "hamburger":"\f330",
    "hand-holding-box":"\f331",
    "hand-holding-heart":"\f332",
    "hand-holding-seeding":"\f333",
    "hand":"\f334",
    "handshake":"\f335",
    "hastag":"\f336",
    "hat-birthday":"\f337",
    "hat-chef":"\f338",
    "head-side-thinking":"\f339",
    "headphones":"\f33a",
    "headset":"\f33b",
    "heart-arrow":"\f33c",
    "heart":"\f33d",
    "heat":"\f33e",
    "helicopter-side":"\f33f",
    "highlighter":"\f340",
    "hiking":"\f341",
    "hockey-puck":"\f342",
    "hockey-sticks":"\f343",
    "home-location-alt":"\f344",
    "home-location":"\f345",
    "home":"\f346",
    "hotdog":"\f347",
    "hourglass-end":"\f348",
    "hourglass":"\f349",
    "house-flood":"\f34a",
    "hryvnia":"\f34b",
    "humidity":"\f34c",
    "hurricane":"\f34d",
    "i":"\f34e",
    "ice-cream":"\f34f",
    "ice-skate":"\f350",
    "id-badge":"\f351",
    "inbox-in":"\f352",
    "inbox-out":"\f353",
    "inbox":"\f354",
    "incognito":"\f355",
    "indent":"\f356",
    "infinity":"\f357",
    "info":"\f358",
    "interactive":"\f359",
    "interlining":"\f35a",
    "interrogation":"\f35b",
    "italic":"\f35c",
    "j":"\f35d",
    "jam":"\f35e",
    "jpg":"\f35f",
    "k":"\f360",
    "kerning":"\f361",
    "key":"\f362",
    "keyboard":"\f363",
    "keynote":"\f364",
    "kiss-beam":"\f365",
    "kiss-wink-heart":"\f366",
    "kiss":"\f367",
    "kite":"\f368",
    "knife":"\f369",
    "l":"\f36a",
    "label":"\f36b",
    "laptop":"\f36c",
    "lasso":"\f36d",
    "laugh-beam":"\f36e",
    "laugh-squint":"\f36f",
    "laugh-wink":"\f370",
    "laugh":"\f371",
    "layer-minus":"\f372",
    "layer-plus":"\f373",
    "layers":"\f374",
    "layout-fluid":"\f375",
    "leaf":"\f376",
    "lemon":"\f377",
    "letter-case":"\f378",
    "lettuce":"\f379",
    "level-down-alt":"\f37a",
    "level-down":"\f37b",
    "level-up-alt":"\f37c",
    "level-up":"\f37d",
    "life-ring":"\f37e",
    "lightbulb-dollar":"\f37f",
    "line-width":"\f380",
    "link-alt":"\f381",
    "link-slash-alt":"\f382",
    "link-slash":"\f383",
    "link":"\f384",
    "lipstick":"\f385",
    "lira-sign":"\f386",
    "list-check":"\f387",
    "list":"\f388",
    "loading":"\f389",
    "location-alt":"\f38a",
    "lock-alt":"\f38b",
    "lock":"\f38c",
    "luchador":"\f38d",
    "luggage-rolling":"\f38e",
    "m":"\f38f",
    "magic-wand":"\f390",
    "mailbox":"\f391",
    "makeup-brush":"\f392",
    "man-head":"\f393",
    "map-marker-cross":"\f394",
    "map-marker-home":"\f395",
    "map-marker-minus":"\f396",
    "map-marker-plus":"\f397",
    "map-marker":"\f398",
    "map":"\f399",
    "marker-time":"\f39a",
    "marker":"\f39b",
    "mars-double":"\f39c",
    "mars":"\f39d",
    "mask-carnival":"\f39e",
    "mask":"\f39f",
    "medicine":"\f3a0",
    "megaphone":"\f3a1",
    "meh-blank":"\f3a2",
    "meh-rolling-eyes":"\f3a3",
    "meh":"\f3a4",
    "melon":"\f3a5",
    "menu-burger":"\f3a6",
    "menu-dots-vertical":"\f3a7",
    "menu-dots":"\f3a8",
    "meteor":"\f3a9",
    "microphone-alt":"\f3aa",
    "microphone":"\f3ab",
    "mind-share":"\f3ac",
    "minus-small":"\f3ad",
    "minus":"\f3ae",
    "mobile-button":"\f3af",
    "mobile-notch":"\f3b0",
    "mobile":"\f3b1",
    "mode-landscape":"\f3b2",
    "mode-portrait":"\f3b3",
    "money-bill-wave-alt":"\f3b4",
    "money-bill-wave":"\f3b5",
    "money-check":"\f3b6",
    "money-check-edit-alt":"\f3b7",
    "money-check-edit":"\f3b8",
    "money":"\f3b9",
    "moon-stars":"\f3ba",
    "moon":"\f3bb",
    "motorcycle":"\f3bc",
    "mountains":"\f3bd",
    "mouse":"\f3be",
    "mug-alt":"\f3bf",
    "mug-hot-alt":"\f3c0",
    "mug-hot":"\f3c1",
    "mug-tea":"\f3c2",
    "mug":"\f3c3",
    "mushroom":"\f3c4",
    "music-file":"\f3c5",
    "music-alt":"\f3c6",
    "music":"\f3c7",
    "n":"\f3c8",
    "navigation":"\f3c9",
    "network-cloud":"\f3ca",
    "network":"\f3cb",
    "noodles":"\f3cc",
    "notebook":"\f3cd",
    "o":"\f3ce",
    "oil-can":"\f3cf",
    "oil-temp":"\f3d0",
    "olive-oil":"\f3d1",
    "olives":"\f3d2",
    "onion":"\f3d3",
    "opacity":"\f3d4",
    "overline":"\f3d5",
    "p":"\f3d6",
    "package":"\f3d7",
    "page-break":"\f3d8",
    "paint-roller":"\f3d9",
    "paint-brush":"\f3da",
    "palette":"\f3db",
    "pan":"\f3dc",
    "paper-plane":"\f3dd",
    "password":"\f3de",
    "pause":"\f3df",
    "paw":"\f3e0",
    "peach":"\f3e1",
    "pencil-ruler":"\f3e2",
    "pencil":"\f3e3",
    "pennant":"\f3e4",
    "people-poll":"\f3e5",
    "pepper-hot":"\f3e6",
    "pepper":"\f3e7",
    "percentage":"\f3e8",
    "pharmacy":"\f3e9",
    "phone-office":"\f3ea",
    "phone-call":"\f3eb",
    "phone-cross":"\f3ec",
    "phone-pause":"\f3ed",
    "phone-slash":"\f3ee",
    "photo-film-music":"\f3ef",
    "photo-video":"\f3f0",
    "physics":"\f3f1",
    "picnic":"\f3f2",
    "picture":"\f3f3",
    "pie":"\f3f4",
    "piece":"\f3f5",
    "piggy-bank":"\f3f6",
    "pineapple":"\f3f7",
    "ping-pong":"\f3f8",
    "pizza-slice":"\f3f9",
    "plane-alt":"\f3fa",
    "plane":"\f3fb",
    "plate":"\f3fc",
    "play-alt":"\f3fd",
    "play-pause":"\f3fe",
    "play":"\f3ff",
    "playing-cards":"\f400",
    "plus-small":"\f401",
    "plus":"\f402",
    "podium-star":"\f403",
    "podium":"\f404",
    "poker-chip":"\f405",
    "poo":"\f406",
    "popcorn":"\f407",
    "portrait":"\f408",
    "pot":"\f409",
    "pound":"\f40a",
    "power":"\f40b",
    "presentation":"\f40c",
    "print":"\f40d",
    "protractor":"\f40e",
    "pulse":"\f40f",
    "pumpkin":"\f410",
    "puzzle":"\f411",
    "pyramid":"\f412",
    "q":"\f413",
    "question-square":"\f414",
    "question":"\f415",
    "quote-right":"\f416",
    "r":"\f417",
    "racquet":"\f418",
    "radish":"\f419",
    "rainbow":"\f41a",
    "raindrops":"\f41b",
    "rec":"\f41c",
    "receipt":"\f41d",
    "record-vinyl":"\f41e",
    "rectabgle-vertical":"\f41f",
    "rectangle-horizontal":"\f420",
    "rectangle-panoramic":"\f421",
    "recycle":"\f422",
    "redo-alt":"\f423",
    "redo":"\f424",
    "reflect":"\f425",
    "refresh":"\f426",
    "registered":"\f427",
    "remove-user":"\f428",
    "reply-all":"\f429",
    "republican":"\f42a",
    "resize":"\f42b",
    "resources":"\f42c",
    "restaurant":"\f42d",
    "rewind":"\f42e",
    "rhombus":"\f42f",
    "rings-wedding":"\f430",
    "road":"\f431",
    "rocket-lunch":"\f432",
    "rocket":"\f433",
    "room-service":"\f434",
    "rotate-left":"\f435",
    "rotate-right":"\f436",
    "ruble-sign":"\f437",
    "rugby":"\f438",
    "ruler-combined":"\f439",
    "ruler-horizontal":"\f43a",
    "ruler-triangle":"\f43b",
    "ruler-vertical":"\f43c",
    "running":"\f43d",
    "rupee-sign":"\f43e",
    "rv":"\f43f",
    "s":"\f440",
    "sack-dollar":"\f441",
    "sack":"\f442",
    "sad-cry":"\f443",
    "sad-tear":"\f444",
    "sad":"\f445",
    "salad":"\f446",
    "salt-pepper":"\f447",
    "sandwich":"\f448",
    "sauce":"\f449",
    "sausage":"\f44a",
    "scale":"\f44b",
    "school-bus":"\f44c",
    "school":"\f44d",
    "scissors":"\f44e",
    "screen":"\f44f",
    "search-dollar":"\f450",
    "search-location":"\f451",
    "search-alt":"\f452",
    "search-heart":"\f453",
    "search":"\f454",
    "security":"\f455",
    "sensor-alert":"\f456",
    "sensor-fire":"\f457",
    "sensor-on":"\f458",
    "sensor-smoke":"\f459",
    "sensor":"\f45a",
    "settings-sliders":"\f45b",
    "settings":"\f45c",
    "share":"\f45d",
    "shekel-sign":"\f45e",
    "shield-check":"\f45f",
    "shield-exclamation":"\f460",
    "shield-interrogation":"\f461",
    "shield-plus":"\f462",
    "shield":"\f463",
    "ship-side":"\f464",
    "ship":"\f465",
    "shop":"\f466",
    "shopping-basket":"\f467",
    "shopping-bag-add":"\f468",
    "shopping-bag":"\f469",
    "shopping-cart-add":"\f46a",
    "shopping-cart-check":"\f46b",
    "shopping-cart":"\f46c",
    "shrimp":"\f46d",
    "shuffle":"\f46e",
    "shuttle-van":"\f46f",
    "shuttlecock":"\f470",
    "sign-in-alt":"\f471",
    "sign-out-alt":"\f472",
    "signal-alt-1":"\f473",
    "signal-alt-2":"\f474",
    "signal-alt":"\f475",
    "skateboard":"\f476",
    "skating":"\f477",
    "skewer":"\f478",
    "ski-jump":"\f479",
    "ski-lift":"\f47a",
    "skiing-nordic":"\f47b",
    "skiing":"\f47c",
    "sledding":"\f47d",
    "sleigh":"\f47e",
    "smartphone":"\f47f",
    "smile-beam":"\f480",
    "smile-wink":"\f481",
    "smile":"\f482",
    "smog":"\f483",
    "smoke":"\f484",
    "snow-blowing":"\f485",
    "snowboarding":"\f486",
    "snowflake":"\f487",
    "snowflakes":"\f488",
    "snowmobile":"\f489",
    "snowplow":"\f48a",
    "soap":"\f48b",
    "social-network":"\f48c",
    "sort-alpha-down-alt":"\f48d",
    "sort-alpha-down":"\f48e",
    "sort-alpha-up-alt":"\f48f",
    "sort-alpha-up":"\f490",
    "sort-alt":"\f491",
    "sort-amount-down-alt":"\f492",
    "sort-amount-down":"\f493",
    "sort-amount-up-alt":"\f494",
    "sort-amount-up":"\f495",
    "sort-down":"\f496",
    "sort-numeric-down-alt":"\f497",
    "sort-numeric-down":"\f498",
    "sort":"\f499",
    "soup":"\f49a",
    "spa":"\f49b",
    "space-shuttle":"\f49c",
    "spade":"\f49d",
    "sparkles":"\f49e",
    "speaker":"\f49f",
    "sphere":"\f4a0",
    "spinner":"\f4a1",
    "spoon":"\f4a2",
    "sport":"\f4a3",
    "square-root":"\f4a4",
    "square":"\f4a5",
    "stamp":"\f4a6",
    "star-octogram":"\f4a7",
    "star":"\f4a8",
    "starfighter":"\f4a9",
    "stars":"\f4aa",
    "stats":"\f4ab",
    "steak":"\f4ac",
    "steering-wheel":"\f4ad",
    "stethoscope":"\f4ae",
    "sticker":"\f4af",
    "stop":"\f4b0",
    "stopwatch":"\f4b1",
    "store-alt":"\f4b2",
    "strawberry":"\f4b3",
    "subtitles":"\f4b4",
    "subway":"\f4b5",
    "suitcase-alt":"\f4b6",
    "summer":"\f4b7",
    "sun":"\f4b8",
    "sunrise-alt":"\f4b9",
    "sunrise":"\f4ba",
    "sunset":"\f4bb",
    "surfing":"\f4bc",
    "surprise":"\f4bd",
    "sushi":"\f4be",
    "swimmer":"\f4bf",
    "sword":"\f4c0",
    "symbol":"\f4c1",
    "syringe":"\f4c2",
    "t":"\f4c3",
    "tablet":"\f4c4",
    "tachometer-alt-average":"\f4c5",
    "tachometer-alt-fastest":"\f4c6",
    "tachometer-alt-slow":"\f4c7",
    "tachometer-alt-slowest":"\f4c8",
    "tachometer-average":"\f4c9",
    "tachometer-fast":"\f4ca",
    "tachometer-fastest":"\f4cb",
    "tachometer-slow":"\f4cc",
    "tachometer-slowest":"\f4cd",
    "tachometer":"\f4ce",
    "taco":"\f4cf",
    "tags":"\f4d0",
    "tally":"\f4d1",
    "target":"\f4d2",
    "taxi":"\f4d3",
    "temperature-down":"\f4d4",
    "temperature-frigid":"\f4d5",
    "temperature-high":"\f4d6",
    "temperature-low":"\f4d7",
    "temperature-up":"\f4d8",
    "tenge":"\f4d9",
    "tennis":"\f4da",
    "terrace":"\f4db",
    "test-tube":"\f4dc",
    "test":"\f4dd",
    "text-check":"\f4de",
    "text-slash":"\f4df",
    "text":"\f4e0",
    "thermometer-half":"\f4e1",
    "thumbtack":"\f4e2",
    "thunderstorm-moon":"\f4e3",
    "thunderstorm-sun":"\f4e4",
    "thunderstorm":"\f4e5",
    "ticket":"\f4e6",
    "time-quarter-past":"\f4e7",
    "time-add":"\f4e8",
    "time-check":"\f4e9",
    "time-delete":"\f4ea",
    "time-fast":"\f4eb",
    "time-forward-sixty":"\f4ec",
    "time-forward-ten":"\f4ed",
    "time-forward":"\f4ee",
    "time-half-past":"\f4ef",
    "time-oclock":"\f4f0",
    "time-past":"\f4f1",
    "time-quarter-to":"\f4f2",
    "time-twenty-four":"\f4f3",
    "tire-flat":"\f4f4",
    "tire-pressure-warning":"\f4f5",
    "tire-rugged":"\f4f6",
    "tire":"\f4f7",
    "tired":"\f4f8",
    "tomato":"\f4f9",
    "tool-box":"\f4fa",
    "tool-crop":"\f4fb",
    "tool-marquee":"\f4fc",
    "tooth":"\f4fd",
    "tornado":"\f4fe",
    "tractor":"\f4ff",
    "trailer":"\f500",
    "train-side":"\f501",
    "train":"\f502",
    "tram":"\f503",
    "transform":"\f504",
    "trash":"\f505",
    "treasure-chest":"\f506",
    "treatment":"\f507",
    "tree-christmas":"\f508",
    "tree":"\f509",
    "triangle":"\f50a",
    "trophy":"\f50b",
    "truck-container":"\f50c",
    "truck-couch":"\f50d",
    "truck-loading":"\f50e",
    "truck-monster":"\f50f",
    "truck-moving":"\f510",
    "truck-pickup":"\f511",
    "truck-plow":"\f512",
    "truck-ramp":"\f513",
    "truck-side":"\f514",
    "tty":"\f515",
    "turkey":"\f516",
    "tv-music":"\f517",
    "typewriter":"\f518",
    "u":"\f519",
    "umbrella":"\f51a",
    "underline":"\f51b",
    "undo-alt":"\f51c",
    "undo":"\f51d",
    "unlock":"\f51e",
    "upload":"\f51f",
    "usb-pendrive":"\f520",
    "usd-circle":"\f521",
    "usd-square":"\f522",
    "user-add":"\f523",
    "user-time":"\f524",
    "user":"\f525",
    "users-alt":"\f526",
    "users":"\f527",
    "utensils":"\f528",
    "v":"\f529",
    "vector-alt":"\f52a",
    "vector":"\f52b",
    "venus-double":"\f52c",
    "venus-mars":"\f52d",
    "venus":"\f52e",
    "video-arrow-down-left":"\f52f",
    "video-arrow-up-right":"\f530",
    "video-camera-alt":"\f531",
    "video-camera":"\f532",
    "video-plus":"\f533",
    "video-slash":"\f534",
    "volcano":"\f535",
    "volleyball":"\f536",
    "volume":"\f537",
    "w":"\f538",
    "wagon-covered":"\f539",
    "wallet":"\f53a",
    "water-bottle":"\f53b",
    "water-lower":"\f53c",
    "water-rise":"\f53d",
    "water":"\f53e",
    "watermelon":"\f53f",
    "wheat":"\f540",
    "wheelchair":"\f541",
    "whistle":"\f542",
    "wifi-alt":"\f543",
    "wind-warning":"\f544",
    "wind":"\f545",
    "windsock":"\f546",
    "woman-head":"\f547",
    "world":"\f548",
    "x":"\f549",
    "y":"\f54a",
    "yen":"\f54b",
    "z":"\f54c",
    "zoom-in":"\f54d",
    "zoom-out":"\f54e",


    >>> BRANDS ICONS ($uicons-brands-fonts)
    "3m":"\f101",
    "500px":"\f102",
    "abbot-laboratories":"\f103",
    "accusoft":"\f104",
    "acrobat":"\f105",
    "adobe":"\f106",
    "aecom":"\f107",
    "aero":"\f108",
    "after-effects":"\f109",
    "airbnb":"\f10a",
    "algolia":"\f10b",
    "amd":"\f10c",
    "american-express":"\f10d",
    "android":"\f10e",
    "animate":"\f10f",
    "app-store-ios":"\f110",
    "apple-pay":"\f111",
    "apple":"\f112",
    "artstation":"\f113",
    "astrazeneca":"\f114",
    "asus":"\f115",
    "atandt":"\f116",
    "atlassian":"\f117",
    "atom":"\f118",
    "audition":"\f119",
    "behance":"\f11a",
    "bitcoin":"\f11b",
    "blackberry":"\f11c",
    "blogger":"\f11d",
    "bluetooth":"\f11e",
    "bootstrap":"\f11f",
    "bridgestone":"\f120",
    "burger-king":"\f121",
    "c":"\f122",
    "capture":"\f123",
    "cc-apple-pay":"\f124",
    "cc-diners-club":"\f125",
    "cc-visa":"\f126",
    "cc-amazon-pay":"\f127",
    "centos":"\f128",
    "character":"\f129",
    "chromecast":"\f12a",
    "cloudflare":"\f12b",
    "confluence":"\f12c",
    "creative-commons-by":"\f12d",
    "creative-commons-nc-eu":"\f12e",
    "creative-commons-nc-jp":"\f12f",
    "creative-commons-nc":"\f130",
    "creative-commons-nd":"\f131",
    "creative-commons-pd-alt":"\f132",
    "creative-commons-pd":"\f133",
    "creative-commons-remix":"\f134",
    "creative-commons-sa":"\f135",
    "creative-commons-sampling-plus":"\f136",
    "creative-commons-sampling":"\f137",
    "creative-commons-share":"\f138",
    "creative-commons-zero":"\f139",
    "creative-commons":"\f13a",
    "css3-alt":"\f13b",
    "css3":"\f13c",
    "dailymotion":"\f13d",
    "deezer":"\f13e",
    "delphi":"\f13f",
    "dev":"\f140",
    "devianart":"\f141",
    "digg":"\f142",
    "dimension":"\f143",
    "discord":"\f144",
    "docker":"\f145",
    "dribbble":"\f146",
    "dropbox":"\f147",
    "drupal":"\f148",
    "ebay":"\f149",
    "elementor":"\f14a",
    "ethereum":"\f14b",
    "etsy":"\f14c",
    "evernote":"\f14d",
    "facebook-messenger":"\f14e",
    "facebook":"\f14f",
    "fedex":"\f150",
    "figma":"\f151",
    "firefox-browser":"\f152",
    "firefox":"\f153",
    "flickr":"\f154",
    "flipboard":"\f155",
    "fonts":"\f156",
    "foursquare":"\f157",
    "fresco":"\f158",
    "github":"\f159",
    "gitlab":"\f15a",
    "goodreads":"\f15b",
    "google":"\f15c",
    "haskell":"\f15d",
    "hbo":"\f15e",
    "hotjar":"\f15f",
    "html5":"\f160",
    "huawei":"\f161",
    "hubspot":"\f162",
    "ibm":"\f163",
    "illustrator-draw":"\f164",
    "illustrator":"\f165",
    "imdb":"\f166",
    "incopy":"\f167",
    "indesign":"\f168",
    "instagram":"\f169",
    "intel":"\f16a",
    "invision":"\f16b",
    "itunes":"\f16c",
    "janseen":"\f16d",
    "java":"\f16e",
    "jcb":"\f16f",
    "jira":"\f170",
    "johnson-and-johnson":"\f171",
    "joomla":"\f172",
    "js":"\f173",
    "kickstarter":"\f174",
    "line":"\f175",
    "linkedin":"\f176",
    "lisp":"\f177",
    "mailchimp":"\f178",
    "marriott-international":"\f179",
    "mcdonalds":"\f17a",
    "media-encoder":"\f17b",
    "medium":"\f17c",
    "meta":"\f17d",
    "microsoft-edge":"\f17e",
    "microsoft-explorer":"\f17f",
    "microsoft":"\f180",
    "mysql":"\f181",
    "napster":"\f182",
    "nestle":"\f183",
    "netflix":"\f184",
    "node-js":"\f185",
    "nvidia":"\f186",
    "oracle":"\f187",
    "patreon":"\f188",
    "paypal":"\f189",
    "pfizer":"\f18a",
    "photoshop-camera":"\f18b",
    "photoshop-express":"\f18c",
    "photoshop-lightroom-classic":"\f18d",
    "photoshop-lightroom":"\f18e",
    "photoshop":"\f18f",
    "php":"\f190",
    "pinterest":"\f191",
    "postgre":"\f192",
    "premiere-rush":"\f193",
    "premiere":"\f194",
    "product-hunt":"\f195",
    "python":"\f196",
    "raspberry-pi":"\f197",
    "reddit":"\f198",
    "samsung":"\f199",
    "sap":"\f19a",
    "sass":"\f19b",
    "shopify":"\f19c",
    "siemens":"\f19d",
    "sketch":"\f19e",
    "skype":"\f19f",
    "slack":"\f1a0",
    "snapchat":"\f1a1",
    "sony":"\f1a2",
    "soundcloud":"\f1a3",
    "spark":"\f1a4",
    "spotify":"\f1a5",
    "starbucks":"\f1a6",
    "stock":"\f1a7",
    "stripe":"\f1a8",
    "substance-3d-designer":"\f1a9",
    "substance-3d-painter":"\f1aa",
    "substance-3d-sampler":"\f1ab",
    "substance-3d-stager":"\f1ac",
    "swift":"\f1ad",
    "t-mobile":"\f1ae",
    "telegram":"\f1af",
    "tencent":"\f1b0",
    "the-home-depot":"\f1b1",
    "tik-tok":"\f1b2",
    "trello":"\f1b3",
    "tripadvisor":"\f1b4",
    "tumblr":"\f1b5",
    "twitch":"\f1b6",
    "twitter":"\f1b7",
    "typescript":"\f1b8",
    "uber":"\f1b9",
    "ubuntu":"\f1ba",
    "unilever":"\f1bb",
    "unity":"\f1bc",
    "unsplash":"\f1bd",
    "ups":"\f1be",
    "usaa":"\f1bf",
    "verizon":"\f1c0",
    "vimeo":"\f1c1",
    "visa":"\f1c2",
    "visual-basic":"\f1c3",
    "vk":"\f1c4",
    "walmart":"\f1c5",
    "whatsapp":"\f1c6",
    "wikipedia":"\f1c7",
    "windows":"\f1c8",
    "wix":"\f1c9",
    "wordpress":"\f1ca",
    "xd":"\f1cb",
    "xing":"\f1cc",
    "yahoo":"\f1cd",
    "yandex":"\f1ce",
    "yelp":"\f1cf",
    "youtube":"\f1d0",
    "zoom":"\f1d1",
*/



/* >>> GENERAL UICONS 
------------------------------ */
@if ($uicons-type) {
    // Call the appropriate general uicons font file
    @font-face {
        font-family: "uicons-#{$uicons-type}";
        src: url("#{$themePath}/fonts/uicons-#{$uicons-type}.woff2") format("woff2"),
            url("#{$themePath}/fonts/uicons-#{$uicons-type}.woff") format("woff");
        font-display: swap;
    }
    // Generate the HTML class for each general icon that will be used in the template
    @each $name, $code in $uicons-fonts {
        %fig-#{$name} {
            @extend %uicons-general;
            content: $code;
        }
        .fig-#{$name}::before {
            @extend %fig-#{$name};
        }
    }
}


/* >>> BRANDS UICONS 
------------------------------ */
@if ($uicons-brands == 'true') {
    // Call the appropriate brands uicons font file
    @font-face {
        font-family: "uicons-brands";
        src: url("#{$themePath}/fonts/uicons-brands.woff2") format("woff2"),
            url("#{$themePath}/fonts/uicons-brands.woff") format("woff");
        font-display: swap;
    }
    // Generate the HTML class for each brand icon that will be used in the template
    @each $name, $code in $uicons-brands-fonts {
        %fib-#{$name} {
            @extend %uicons-brands;
            content: $code;
        }
        .fib-#{$name}::before {
            @extend %fib-#{$name};
        }
    }
}