.event-card {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;

    &__image {
        display: block;
        position: relative;
        height: 27.5rem;
        width: 100%;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &__location {
            @extend %flex-align-center;
            display: inline-flex;
            background-color: #fff;
            color: var(--color-secondary); 
            font-size: 1.8rem;
            font-weight: bold;
            padding: .5rem 1.5rem;
    
            &::before { 
                @extend %fig-map-marker;
                margin-right: .75rem; 
                transform: none;
            }
        }

    // &__status {
    //     @extend %flex-align-center;
    //     background-color: #fff;
    //     font-size: 1.8rem;
    //     font-weight: bold;
    //     padding: .5rem 1.5rem;

    //     &::before { 
    //         margin-right: .75rem; 
    //         transform: none;
    //     }

    //     &--open { 
    //         color: var(--color-secondary); 
    //         &::before { @extend %fig-calendar-check; }
    //     }
    //     &--close { 
    //         color: var(--color-danger); 
    //         &::before { @extend %fig-calendar-minus; }
    //     }
    // }

    &__content {
        position: relative;
    }

    &__date {
        @extend %bubble-icon-primary;
        border: 1rem solid #fff;
        flex-flow: column nowrap;
        width: 17rem;
        height: 17rem;
        position: absolute;
        left: 1.5rem;
        transform: translateY(-60%);

        & > span { 
            color: #fff;
            display: inline-block; 
            line-height: 1;

            // &:nth-child(1) {
            //     font-size: 2rem;
            // }
            &:nth-child(1) {
                font-size: 6rem;
                font-weight: bold;
            }
            &:nth-child(2) {
                font-size: 2.3rem;
                margin-bottom: .5rem;
            }
            &:nth-child(3) {
                font-size: 1.8rem;
            }
        }

        &--2 {
            border: none;
            position: relative;
            left: auto;
            transform: none;
        }
    }

    &__date-text {
        color: var(--color-primary);
        font-size: 2rem;
        font-weight: bold;

        i { margin-right: .5rem; }
    }

    &__content {
        padding: 3rem;
    }

    &__tag {
        @extend .pellet;
        @extend .pellet-secondary;
        float: right;
    }

    &__title {
        @extend %h4;
        @extend %hover-color-primary;
        display: block;
        margin-top: 8rem;
    }

    &__desc {
        margin-bottom: 1.5rem;
    }

    &__footer {
        @extend %flex-align-center;
        gap: 1rem;
        flex-flow: row wrap;

        & > * { flex-grow: 1; }
    }


    // IS FINISHED
    &--isFinished {
        // .event-card__image {
        //     img { filter: grayscale(.7); }
        // }
        .event-card__date,
        .event-card__date::after {
            background-color: var(--color-grey-5);
        }
		
    }


    // FEAT
    &--feat {
        overflow: visible;
        position: relative;
        margin: 0 auto;
        min-width: 0;
        max-width: 50rem;
        width: 100%;

        .event-card__image {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            overflow: hidden;
        }

        .event-card__hour {
            background-color: var(--color-secondary);
            border-radius: 5rem;
            color: #fff;
            font-size: 2.3rem;
            font-weight: bold;
            display: inline-flex;
            align-items: center;
            padding: .5rem 2rem;
            position: absolute;
            bottom: 2rem;
            right: 2rem;
            z-index: 5;

            &::before {
                @extend %fig-clock;
                font-size: .9em;
                margin-right: 1rem;
            }
        }

        .event-card__top {
            background-color: var(--color-primary);
            border-radius: 5rem;
            color: #fff;
            font-size: 2.5rem;
            font-weight: bold;
            padding: .5rem 3rem;
            position: absolute;
            top: 2rem;
            left: -4rem;
            text-transform: uppercase;
            z-index: 1;
        }

        .event-card__tag {
            background-color: transparent;
            border: 2px solid var(--color-secondary);
            color: var(--color-secondary);
        }
    }


    // STYLE 2
    &--2 {
        display: flex;
        justify-content: flex-end;
        position: relative;
        max-width: 113rem;

        .event-card__image {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 40rem;
            flex-shrink: 0;
            & > img { @extend %image-cover; }
        }
        .event-card__content {
            width: calc(100% - 40rem);
        }
        .event-card__tag {
            float: none;
        }
        .event-card__title {
            margin: 1.5rem 0 .5rem 0;
        }
        .event-card__desc {
            margin: 1.5rem 0 2rem 0;
        }
    }
}