.container-overview {
    @include create-grid-columns-custom($columns: 37% calc(63% - $grid-gap-default));

    .left {
        max-width: 55rem;
    }
}

.section-dashboard {
    background-color: #e1cdba;
    @include create-grid-columns-custom($columns: minmax(37rem, 25%) 1fr, $grid-gap: 0);
    min-height: 80rem;
}


.dashboard-header {
    background-color: var(--color-tertiary);
    position: relative;
    overflow: hidden;
    height: 20rem;

    .banner__overlays {
        &::before { top: -11rem; }
    }
}

.dashboard-aside {
    background-color: #e1cdba;
    padding: 5rem 3rem 15rem 3rem;
}
.dashboard-content {
    @extend %new-stack;
    background-color: #fbf8f5;
    overflow: hidden;
    padding: 5rem 6rem 15rem 6rem;

    &::before {
        @include before-after-background(
            $url: '/storage/icones/bubble-left-primary.svg', 
            $top: -3rem,
            $right: -3rem,
            $width: 48.5rem,
            $height: 50rem,
        );
        opacity: .07;
        z-index: -1;
    }
}

.dashboard-main-nav {
    margin-top: 1.5rem;
    padding-left: 2rem;
    a {
        @extend %flex-align-center;
        color: var(--color-tertiary);
        font-weight: bold;
        margin-bottom: 1rem;
        transition: $transition;

        i {
            @extend %flex-center-center;
            background-color: rgba(#fff, .3);
            border-radius: 1rem;
            color: var(--color-primary);
            font-size: 1.5rem;
            margin-right: 2rem;
            height: 4.2rem;
            width: 4.2rem;
            transform: none;
            transition: $transition;
        }

        &:hover, &:focus-visible {
            i { background-color: var(--color-primary); color: #fff; }
        }
    }

    .nav-item-alt {
        i { background-color: var(--color-tertiary); color: #fff; }
    }
}

.dashboard-bottom-nav {
    padding-left: 1.5rem;
    a { 
        @extend %flex-align-center;
        color: var(--color-tertiary); 
        font-size: 1.6rem;
        font-weight: bold;
        text-decoration: underline;
        i { margin-right: 1rem; }
    }
}



