h1, .h1 { @extend %h1; }
h2, .h2 { @extend %h2; }
h3, .h3 { 
    @extend %h3; 
    height: auto;
}
h4, .h4 { @extend %h4; }
h5, .h5 { @extend %h5; }
h6, .h6 { @extend %h6; }

.link {
    @extend %link;
}

.link-feat-secondary {
    @extend %link;
    @extend %hover-color-secondary;
    color: var(--color-secondary);
    font-size: 2rem;
    letter-spacing: .05em;
    &:hover::after, &:focus::after { background-color: var(--color-secondary); }
}

.para {
    & > p:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}

.text-informative {
    color: var(--color-primary);
    font-size: 2rem;
    font-style: italic;
}
.text-note {
    color: var(--color-grey-6);
    font-size: 2rem;
    font-style: italic;
}