/* -----------------------------------------------
 *  Background mixin extend
 ------------------------------------------------- */
@mixin background-cover-center {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}


/* -----------------------------------------------
 *  Button mixin extend
 ------------------------------------------------- */
@mixin button {
    border-radius: 99rem;
    border: 2px solid transparent;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: bold;
    display: inline-block;
    letter-spacing: .1em;
    margin-bottom: 1.5rem;
    padding: 2rem 5.5rem;
    text-align: center;
    text-transform: uppercase;
    transition: $transition;
}
@mixin button-icon {
    @include flex-full-center;
    border: 2px solid transparent;
    border-radius: 5.8rem;
    cursor: pointer;
    width: 5.8rem;
    height: 5.8rem;
    transition: $transition;
}


/* -----------------------------------------------
 *  Font icon mixin extend
 ------------------------------------------------- */
@mixin uicons {
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@mixin uicons-bold-rounded {
    @include uicons;
    font-family: uicons-bold-rounded !important;
}
@mixin uicons-bold-straight {
    @include uicons;
    font-family: uicons-bold-straight !important;
}
@mixin uicons-regular-rounded {
    @include uicons;
    font-family: uicons-regular-rounded !important;
}
@mixin uicons-regular-straight {
    @include uicons;
    font-family: uicons-regular-straight !important;
}
@mixin uicons-solid-rounded {
    @include uicons;
    font-family: uicons-solid-rounded !important;
}
@mixin uicons-solid-straight {
    @include uicons;
    font-family: uicons-solid-straight !important;
}
@mixin uicons-brands {
    @include uicons;
    font-family: uicons-brands !important;
}


/* -----------------------------------------------
 *  Flex mixin extend
 ------------------------------------------------- */
@mixin flex-align-center {
    display: flex;
    align-items: center;
}
@mixin flex-full-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin flex-inline-full-center {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
@mixin flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/* -----------------------------------------------
 *  Grid mixin extend
 ------------------------------------------------- */
@mixin grid-base {
    @include create-grid-base($grid-width-default);
}
@mixin grid-item-full {
    grid-column: 1/-1;
}


/* -----------------------------------------------
 *  Hover mixin extend
 ------------------------------------------------- */
@mixin hover-color-primary {
    &:hover, &:focus { color: var(--color-primary); }
}
@mixin hover-color-secondary {
    &:hover, &:focus { color: var(--color-secondary); }
}


/* -----------------------------------------------
 *  Icon mixin extend
 ------------------------------------------------- */
@mixin icon-fix {
    display: inline-block;
    transform: translateY(1px);
}


/* -----------------------------------------------
 *  Image mixin extend
 ------------------------------------------------- */
@mixin image-cover {
    object-fit: cover;
    height: 100%;
    width: 100%;
}


/* -----------------------------------------------
 *  Pellet mixin extend
 ------------------------------------------------- */
@mixin pellet {
    border-radius: 999rem;
    display: inline-block;
    font-size: 1.7rem;
    font-weight: bold;
    letter-spacing: .1em;
    padding: .5rem 2.5rem;
    text-transform: uppercase;
}
@mixin pellet-primary {
    @include pellet;
    background-color: var(--color-primary);
    color: #fff;
}
@mixin pellet-secondary {
    @include pellet;
    background-color: var(--color-secondary);
    color: #fff;
}

/* -----------------------------------------------
 *  Section mixin extend
 ------------------------------------------------- */
@mixin section {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
    position: relative;
}


/* -----------------------------------------------
 *  Title mixin extend
 ------------------------------------------------- */
@mixin title {
    color: $heading-color;
    font-family: $heading-font;
    font-weight: bold;
    text-transform: uppercase;
}
@mixin h1 {
    @include title;
    font-size: 6rem; 
    letter-spacing: .08em;
    line-height: 1.2;
    margin-bottom: 4rem;
}
@mixin h2 {
    @include title;
    font-size: 4rem; 
    letter-spacing: .08em;
    line-height: 1.3; 
    margin-bottom: 3rem;
}
@mixin h3 {
    @include title;
    font-size: 3rem; 
    letter-spacing: .05em;
    line-height: 1.3;
    margin-bottom: 2.5rem;
}
@mixin h4 {
    @include title;
    font-size: 2.5rem; 
    letter-spacing: .05em;
    line-height: 1.4;
    margin-bottom: 1rem;
}
@mixin h5 {
    @include title;
    font-size: 2rem; 
    letter-spacing: .05em;
    line-height: 1.5;
    margin-bottom: 1rem;
}
@mixin h6 {
    @include title;
    font-size: 1.8rem; 
    letter-spacing: .05em;
    line-height: 1.6;
    margin-bottom: 1rem;
}


/* -----------------------------------------------
 *  Visibility mixin extend
 ------------------------------------------------- */
@mixin is-hidden {
    opacity: 0;
    visibility: hidden;
}
@mixin is-visible {
    opacity: 1;
    visibility: visible;
}


/* -----------------------------------------------
 *  Z-index mixin extend
 ------------------------------------------------- */
@mixin zindex-1 {
    position: relative;
    z-index: 1;
}


/* -----------------------------------------------
 *  CUSTOM mixin extend
 ------------------------------------------------- */
@mixin bubble-icon {
    background-color: var(--color-secondary);
    border-radius: 11rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 10.5rem;
    width: 10.5rem;

    & > * {
        @include zindex-1;
    }

    & > svg { 
        width: 7.2rem; 
        height: 7.2rem; 
    }

    &::after {
        content: "";
        background-color: var(--color-secondary);
        position: absolute;
        bottom: 5%;
        right: 5%;
        height: 5rem;
        width: 5rem;
        transform: skewX(20deg) rotate(7deg);
        z-index: 0;
    }
}
@mixin bubble-icon-primary {
    @include bubble-icon;
    background-color: var(--color-primary);
    &::after { background-color: var(--color-primary); }
}
@mixin bubble-overlay-1 {
    @include mask-image(
        $url: '/storage/icones/bubble-left.svg',
        $size: auto,
    );
    content: "";
    background: url('/storage/businesspeople-celebrating-success-color-secondary-w1650x1100.jpg') center center no-repeat;
    background-size: cover;
    opacity: .25;
    position: absolute;
    top: 0;
    right: 0;
    height: 100rem;
    width: 100rem;
    z-index: 0;
    transform: translate(14.5%, -17%);
}
@mixin letters-style {
    span { color: var(--color-secondary); font-weight: bold; }
}
@mixin link {
    display: inline-block;
    font-weight: bold;
    position: relative;

    &::after {
        @include is-hidden;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 0;
        transition: $transition;
    }

    &:hover, &:focus {
        &::after { 
            @include is-visible;
            width: 100%; 
        }
    }
}
@mixin line-after {
    padding-bottom: 2rem;
    position: relative;

    &::after {
        @include before-after-background(
            $url: '/storage/arriere-plan/section-line-primary.svg',
            $bottom: 0,
            $left: 0,
            $height: 1.4rem,
            $width: 10rem,
        );
    }
}
@mixin line-after-secondary {
    @include line-after;
    &::after { 
        background-image: url('/storage/arriere-plan/section-line-secondary.svg')
    }
}
@mixin line-after-secondary-center {
    @include line-after-secondary;

    &::after {
        left: 50%;
        transform: translateX(-50%);
    }
}
@mixin line-after-white {
    @include line-after;
    &::after { 
        background-image: url('/storage/arriere-plan/section-line-white.svg')
    }
}

@mixin line-section {
    padding-bottom: 15rem;
    position: relative;

    &::after {
        @include before-after-background(
            $url: '/storage/arriere-plan/section-line-secondary.svg',
            $bottom: 0,
            $left: 0,
            $height: 21rem,
        );
        transform: translateY(50%); 
        z-index: 5;
    }
}
@mixin line-section-before {
    padding-top: 15rem;
    position: relative;

    &::before {
        @include before-after-background(
            $url: '/storage/arriere-plan/section-line-secondary.svg',
            $top: 0,
            $left: 0,
            $height: 21rem,
        );
        transform: translateY(-50%); 
        z-index: 5;
    }
}
@mixin line-section-before-tertiary-3 {
    padding-top: 15rem;
    position: relative;

    &::before {
        @include before-after-background(
            $url: '/storage/arriere-plan/section-line-tertiary-3.svg',
            $top: 0,
            $left: 0,
            $height: 21rem,
        );
        transform: translateY(-50%); 
        z-index: 5;
    }
}