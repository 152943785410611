*, 
*::before, 
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;

    @include media("<lg") {
        font-size: 50%;
    }
}

body {
    color: $body-color;
    font-family: $body-font;
    font-size: $body-size;
    line-height: $body-line-height;

    @include media("<lg") {
        font-size: 2rem;
    }

    &.preload * {
        transition: none !important;
    }
}

a {
    color: currentColor;
    text-decoration: none;
    transition: $transition;

    &:hover, &:focus {
        color: var(--color-primary);
    }
}

img {
    display: inline-block;
    max-width: 100%;
}

ul {
    list-style: none;
}

::selection {
    background-color: var(--color-primary);
    color: #fff;
}