@use "sass:string";

@if ($grid-rows) {
    $widthRefs: "";

    // For each columns group that are registered
    @each $widths, $breakpoints in $grid-rows {

        $widthRefsCalc: null;
        @if (str-index($widths, "-")) {
            $widthRefs: str-split($widths, "-");
            @each $width in $widthRefs {
                @if (notempty($width)) {
                    $widthRefsCalc: append($widthRefsCalc, #{number($width)}fr + ' ');
                }
            }
        } @else {
            @if (str-index($widths, 'c')) {
                $columns: number(nth(str-split($widths, 'c'), 1));
                $widthRefsCalc: repeat($columns, 1fr);
            } @else {
                $widthRefsCalc: 1fr;
            }
        }

        

        @each $breakpoint in $breakpoints {
            @if ($breakpoint == "g") {
                .row-#{$widths} {
                    @include create-grid-columns-custom($widthRefsCalc, $grid-gap-rows, 'x');
                }
            } @else {
                @include media('<#{$breakpoint}') {
                    .row-#{$breakpoint}-#{$widths} {
                        @include change-grid-columns-custom($widthRefsCalc, $grid-gap-rows, 'x');
                    }
                }
            }
        }
    }
}

.row-2c-smart {
    @include create-grid-columns-smart(2);
}