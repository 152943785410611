/* -----------------------------------------------
 *  Background extend
 ------------------------------------------------- */
%background-cover-center {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}


/* -----------------------------------------------
 *  Button extend
 ------------------------------------------------- */
%button {
    border-radius: 99rem;
    border: 2px solid transparent;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    gap: 1rem;
    font-family: inherit;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: .1em;
    margin-bottom: 1.5rem;
    padding: 2rem 5.5rem;
    text-align: center;
    text-transform: uppercase;
    transition: $transition;
}
%button-icon {
    @extend %flex-center-center;
    border: 2px solid transparent;
    border-radius: 5.8rem;
    cursor: pointer;
    width: 5.8rem;
    height: 5.8rem;
    transition: $transition;
}


/* -----------------------------------------------
 *  Font icon extend
 ------------------------------------------------- */
 %uicons-general {
    display: inline-block;
    font-family: uicons-#{$uicons-type} !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translateY(.09em);
}
%uicons-brands {
    display: inline-block;
    font-family: uicons-brands !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translateY(1px);
}


/* -----------------------------------------------
 *  Flex extend
 ------------------------------------------------- */
%flex-align-start {
    display: flex;
    align-items: flex-start;
}
%flex-align-center {
    display: flex;
    align-items: center;
}
%flex-align-end {
    display: flex;
    align-items: flex-end;
}
%flex-justify-start {
    display: flex;
    justify-content: flex-start;
}
%flex-justify-center {
    display: flex;
    justify-content: center;
}
%flex-justify-end {
    display: flex;
    justify-content: flex-end;
}
%flex-justify-around {
    display: flex;
    justify-content: space-around;
}
%flex-justify-between {
    display: flex;
    justify-content: space-between;
}
%flex-start-center {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
%flex-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
%flex-end-center {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
%flex-center-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
%flex-center-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
%flex-center-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
%flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

%flexi-align-start {
    display: inline-flex;
    align-items: flex-start;
}
%flexi-align-center {
    display: inline-flex;
    align-items: center;
}
%flexi-align-end {
    display: inline-flex;
    align-items: flex-end;
}
%flexi-justify-start {
    display: inline-flex;
    justify-content: flex-start;
}
%flexi-justify-center {
    display: inline-flex;
    justify-content: center;
}
%flexi-justify-end {
    display: inline-flex;
    justify-content: flex-end;
}
%flexi-justify-around {
    display: inline-flex;
    justify-content: space-around;
}
%flexi-justify-between {
    display: inline-flex;
    justify-content: space-between;
}
%flexi-start-center {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
}
%flexi-center-center {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
%flexi-end-center {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
}
%flexi-center-start {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
}
%flexi-center-end {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
}
%flexi-center-around {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
}
%flexi-center-between {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

%flex-align-start-w {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
%flex-align-center-w {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
%flex-align-end-w {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}
%flex-justify-start-w {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
%flex-justify-center-w {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
%flex-justify-end-w {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}
%flex-justify-around-w {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
%flex-justify-between-w {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
%flex-start-center-w {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}
%flex-center-center-w {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
%flex-end-center-w {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
}
%flex-center-start-w {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
%flex-center-end-w {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}
%flex-center-around-w {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}
%flex-center-between-w {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

%flexi-align-start-w {
    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
%flexi-align-center-w {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
%flexi-align-end-w {
    display: inline-flex;
    align-items: flex-end;
    flex-wrap: wrap;
}
%flexi-justify-start-w {
    display: inline-flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
%flexi-justify-center-w {
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
}
%flexi-justify-end-w {
    display: inline-flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}
%flexi-justify-around-w {
    display: inline-flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
%flexi-justify-between-w {
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
%flexi-start-center-w {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}
%flexi-center-center-w {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
%flexi-end-center-w {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
}
%flexi-center-start-w {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
%flexi-center-end-w {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}
%flexi-center-around-w {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}
%flexi-center-between-w {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}


/* -----------------------------------------------
 *  Grid extend
 ------------------------------------------------- */
%grid-base {
    @include create-grid-base($grid-width-default);
}
%grid-init {
    display: grid;
    grid-gap: $grid-gap-default;
}
%grid-init-x {
    display: grid;
    grid-column-gap: $grid-gap-default;
}
%grid-row-init {
    display: grid;
    grid-gap: $grid-gap-rows;
}
%grid-row-init-x {
    display: grid;
    grid-column-gap: $grid-gap-rows;
}
%grid-item-full {
    grid-column: 1/-1;
}
%grid-item-full-important {
    grid-column: 1/-1 !important;
}
@for $i from 1 through 12 {
    %grid-columns-#{$i} { @include create-grid-columns($i); }
}


/* -----------------------------------------------
 *  Hover extend
 ------------------------------------------------- */
%hover-color-primary {
    &:hover, &:focus { color: var(--color-primary); }
}
%hover-color-secondary {
    &:hover, &:focus { color: var(--color-secondary); }
}


/* -----------------------------------------------
 *  Icon extend
 ------------------------------------------------- */
%icon-fix {
    display: inline-block;
    transform: translateY(1px);
}


/* -----------------------------------------------
 *  Image extend
 ------------------------------------------------- */
%image-cover {
    object-fit: cover;
    height: 100%;
    width: 100%;
}


/* -----------------------------------------------
 *  Section extend
 ------------------------------------------------- */
%section {
    @extend %py-80;
    position: relative;
}


/* -----------------------------------------------
 *  Stacking extend
 ------------------------------------------------- */
%new-stack {
    position: relative;
    isolation: isolate;
}


/* -----------------------------------------------
 *  Title extend
 ------------------------------------------------- */
%title {
    color: $heading-color;
    font-family: $heading-font;
    font-weight: bold;
    text-transform: uppercase;
}
%h1 {
    @extend %title;
    font-size: 4rem; 
    letter-spacing: .08em;
    line-height: 1.2;
    margin-bottom: 4rem;
}
%h2 {
    @extend %title;
    font-size: 3rem; 
    letter-spacing: .08em;
    line-height: 1.3; 
    margin-bottom: 3rem;
}
%h3 {
    @extend %title;
    font-size: 2.5rem; 
    letter-spacing: .05em;
    line-height: 1.3;
    margin-bottom: 1.5rem;
}
%h4 {
    @extend %title;
    font-size: 2rem; 
    letter-spacing: .05em;
    line-height: 1.4;
    margin-bottom: 1rem;
}
%h5 {
    @extend %title;
    font-size: 1.8rem; 
    letter-spacing: .05em;
    line-height: 1.5;
    margin-bottom: 1rem;
}
%h6 {
    @extend %title;
    font-size: 1.6rem; 
    letter-spacing: .05em;
    line-height: 1.6;
    margin-bottom: 1rem;
}


/* -----------------------------------------------
 *  Visibility extend
 ------------------------------------------------- */
%is-hidden {
    opacity: 0;
    visibility: hidden;
}
%is-visible {
    opacity: 1;
    visibility: visible;
}


/* -----------------------------------------------
 *  Z-index extend
 ------------------------------------------------- */
%zindex-1 {
    position: relative;
    z-index: 1;
}


/* -----------------------------------------------
 *  CUSTOM extend
 ------------------------------------------------- */
%bubble-icon {
    background-color: var(--color-secondary);
    border-radius: 999rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 10.5rem;
    width: 10.5rem;

    & > * {
        @extend %zindex-1;
    }

    & > svg { 
        width: 7.2rem; 
        height: 7.2rem; 
    }

    &::after {
        content: "";
        background-color: var(--color-secondary);
        position: absolute;
        bottom: 5%;
        right: 5%;
        height: 33%;
        width: 33%;
        transform: skewX(20deg) rotate(7deg);
        z-index: 0;
    }
}
%bubble-icon-primary {
    @extend %bubble-icon;
    background-color: var(--color-primary);
    &::after { background-color: var(--color-primary); }
}
%bubble-icon-primary-white {
    @extend %bubble-icon;
    background-color: var(--color-primary-white);
    &::after { background-color: var(--color-primary-white); }
}
%bubble-icon-tertiary-white {
    @extend %bubble-icon;
    background-color: var(--color-grey-3);
    &::after { background-color: var(--color-grey-3); }
}
%bubble-icon-secondary-white {
    @extend %bubble-icon;
    background-color: var(--color-secondary-white);
    &::after { background-color: var(--color-secondary-white); }
}
%bubble-overlay-1 {
    @include mask-image(
        $url: '/storage/icones/bubble-left.svg',
        $size: auto,
    );
    content: "";
    background: url('/storage/businesspeople-celebrating-success-overlay-tertiary-w1000.webp') center center no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    height: 100rem;
    width: 100rem;
    z-index: 0;
    transform: translate(14.5%, -17%);
}
%bubble-overlay-2 {
    @include mask-image(
        $url: '/storage/icones/bubble-left.svg',
        $size: auto,
    );
    content: "";
    background: url('/storage/image-color-tertiaty-overlay-produc-meaux-w1000x1000t.jpg') center center no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    height: 100rem;
    width: 100rem;
    z-index: 0;
    transform: translate(14.5%, -17%);
}
%letters-style {
    span { color: var(--color-secondary); font-weight: bold; }
}
%link {
    color: var(--color-primary);
    display: inline-block;
    font-size: inherit;
    font-weight: bold;
    position: relative;

    &::after {
        @extend %is-hidden;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 0;
        transition: $transition;
    }

    &:hover, &:focus {
        &::after { 
            @extend %is-visible;
            background-color: var(--color-primary);
            width: 100%; 
        }
    }
}
%line-after {
    padding-bottom: 2rem;
    position: relative;

    &::after {
        @include before-after-background(
            $url: '/storage/arriere-plan/section-line-primary.svg',
            $bottom: 0,
            $left: 0,
            $height: 1.4rem,
            $width: 10rem,
        );
    }
}
%line-after-secondary {
    @extend %line-after;
    &::after { 
        background-image: url('/storage/arriere-plan/section-line-secondary.svg')
    }
}
%line-after-secondary-center {
    @extend %line-after-secondary;

    &::after {
        left: 50%;
        transform: translateX(-50%);
    }
}
%line-after-white {
    @extend %line-after;
    &::after { 
        background-image: url('/storage/arriere-plan/section-line-white.svg')
    }
}

%line-section {
    @extend %pb-150;
    position: relative;

    &::after {
        @include before-after-background(
            $url: '/storage/arriere-plan/section-line-secondary.svg',
            $bottom: 0,
            $left: 0,
            $height: 24rem,
        );
        transform: translateY(50%); 
        z-index: 5;
    }
}
%line-section-before {
    @extend %pt-150;
    position: relative;

    &::before {
        @include before-after-background(
            $url: '/storage/arriere-plan/section-line-secondary.svg',
            $top: 0,
            $left: 0,
            $height: 24rem,
        );
        transform: translateY(-50%); 
        z-index: 5;
    }
}
%line-section-before-tertiary-3 {
    @extend %pt-150;
    position: relative;

    &::before {
        @include before-after-background(
            $url: '/storage/arriere-plan/section-line-tertiary-3.svg',
            $top: 0,
            $left: 0,
            $height: 24rem,
        );
        transform: translateY(-50%); 
        z-index: 5;
    }
}



/* -----------------------------------------------
 *  Auto generated extends
 * -> You should not modify that part
 ------------------------------------------------- */
 @each $value, $abbreviations in $spacings {
    @each $abbreviation in $abbreviations {
        
        // Check the calculation to do depending on the type of the "$value" variable
        $calculation: "";
        @if ($value == '0') {
            $calculation: 0;
        } @else if (type-of($value) == 'string') {
            $calculation: calc(#{$value}rem/10);
        } @else {
            $calculation: #{calc($value/10)}rem;
        }

        // Regular spacing
        %#{$abbreviation}-#{$value} {
            
            // Margins
            @if ($abbreviation == "m") {
                margin: $calculation;
            } @else if ($abbreviation == 'mx') {
                margin-left: $calculation;
                margin-right: $calculation;
            } @else if ($abbreviation == 'my') {
                margin-top: $calculation;
                margin-bottom: $calculation;
            } @else if ($abbreviation == 'mt') {
                margin-top: $calculation;
            } @else if ($abbreviation == 'mr') {
                margin-right: $calculation;
            } @else if ($abbreviation == 'mb') {
                margin-bottom: $calculation;
            } @else if ($abbreviation == 'ml') {
                margin-left: $calculation;
            }
            // Paddings
            @else if ($abbreviation == "p") {
                padding: $calculation;
            } @else if ($abbreviation == 'px') {
                padding-left: $calculation;
                padding-right: $calculation;
            } @else if ($abbreviation == 'py') {
                padding-top: $calculation;
                padding-bottom: $calculation;
            } @else if ($abbreviation == 'pt') {
                padding-top: $calculation;
            } @else if ($abbreviation == 'pr') {
                padding-right: $calculation;
            } @else if ($abbreviation == 'pb') {
                padding-bottom: $calculation;
            } @else if ($abbreviation == 'pl') {
                padding-left: $calculation;
            }
        }

        // Important spacing
        %#{$abbreviation}-#{$value}-important {

            // Margins
            @if ($abbreviation == "m") {
                margin: $calculation !important;
            } @else if ($abbreviation == 'mx') {
                margin-left: $calculation !important;
                margin-right: $calculation !important;
            } @else if ($abbreviation == 'my') {
                margin-top: $calculation !important;
                margin-bottom: $calculation !important;
            } @else if ($abbreviation == 'mt') {
                margin-top: $calculation !important;
            } @else if ($abbreviation == 'mr') {
                margin-right: $calculation !important;
            } @else if ($abbreviation == 'mb') {
                margin-bottom: $calculation !important;
            } @else if ($abbreviation == 'ml') {
                margin-left: $calculation !important;
            } 
    
            // Paddings
            @else if ($abbreviation == "p") {
                padding: $calculation !important;
            } @else if ($abbreviation == 'px') {
                padding-left: $calculation !important;
                padding-right: $calculation !important;
            } @else if ($abbreviation == 'py') {
                padding-top: $calculation !important;
                padding-bottom: $calculation !important;
            } @else if ($abbreviation == 'pt') {
                padding-top: $calculation !important;
            } @else if ($abbreviation == 'pr') {
                padding-right: $calculation !important;
            } @else if ($abbreviation == 'pb') {
                padding-bottom: $calculation !important;
            } @else if ($abbreviation == 'pl') {
                padding-left: $calculation !important;
            }
            
        }
    }
}