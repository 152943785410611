.card {
    background-color: #fff;
    border-radius: 1.5rem;
    padding: 4rem 5rem;

    &--bubble1 {
        @extend %new-stack;
        &::before {
            @include before-after-background(
                $url: '/storage/icones/bubble-outline-grey-3.svg',
                $position: left bottom,
                $size: 23rem 23rem,
                $right: 0,
                $top: 0,
                $width: 18rem,
                $height: 18rem,
            );
            z-index: -1;
        }
    }
}


.card-avatar {
    //background-color: rgba(#fff, .3);
    border-radius: 999rem;
    display: flex;
    align-items: center;
    padding: 1rem;

    &__images {
        position: relative;
        margin-right: 2.5rem;
        width: 8rem;
        height: 8rem;
    }

    &__image-main {
        border-radius: 999rem;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    &__image-sub {
        background-color: #fff;
        border-radius: 99rem;
        overflow: hidden;
        padding: .25rem;
        position: absolute;
        bottom: -.25rem;
        right: -.25rem;
        height: 4rem;
        width: 4rem;
    }

    &__name {
        color: var(--color-tertiary);
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
    }

    &__sub {
        font-size: 1.6rem;
    }
}






.card-feat {
    border: 4px dashed var(--color-secondary-white-2);
    border-radius: 2rem;
    color: var(--color-secondary);
    padding: 3rem;
    text-align: center;

    &__icon {
        background-color: var(--color-secondary-white-2);
        border-radius: 9rem;
        display: inline-flex;
        align-items: center;
        font-size: 2.5rem;
        justify-content: center;
        height: 5.2rem;
        width: 5.2rem;
    }

    &__title {
        font-size: 2.5rem;
        font-weight: bold;
    }

    &__desc {
        font-size: 2.2rem;
    }
}