.accordion {

    &__item {
        margin-bottom: 2rem;
    }

    &__header {
        background-color: var(--color-tertiary);
        border-radius: 50rem;
        color: #fff;
        cursor: pointer;
        padding: 2rem 2.5rem 2rem 2.5rem;
        position: relative;

        &::before {
            @extend %fig-angle-down;
            line-height: 1;
            position: absolute;
            top: 50%;
            right: 3.5rem;
            transform: translateY(-50%);
        }
    }

    &__title {
        @extend %flex-align-center;
        font-weight: bold;
        letter-spacing: .05em;
        text-transform: uppercase;
        i {
            @extend %flex-center-center;
            background-color: var(--color-tertiary-3);
            border-radius: 99rem;
            color: var(--color-tertiary-4);
            font-size: 1.8rem;
            line-height: 1;
            margin-right: 1.5rem;
            text-align: center;
            height: 4rem;
            width: 4rem;
        }
    }

    &__content {
        padding: 0;
        overflow-y: hidden;
        height: 0;
    }

    &__item.active {
        .accordion__header {
            background-color: var(--color-primary);
            &::before {
                transform: translateY(-50%) rotate(-180deg);
            }
        }
        .accordion__title {
            i {
                background-color: var(--color-primary-white-2);
                color: var(--color-primary);
            }
        }
        .accordion__content {
            padding: 3rem;
            height: auto;
        }
    }

}