.events-chronology {
    & > *:last-child {
        .event-chronology__left::before {
            display: none;
        }
    }
}

.event-chronology {
    @include create-grid-columns-custom(25rem 1fr);
    margin-bottom: 3rem;

    &__left {
        @extend %flex-justify-center;
        @extend %new-stack;

        &::before {
            content: "";
            background-color: var(--color-grey);
            opacity: .1;
            position: absolute;
            top: 1rem;
            left: 50%;
            transform: translateX(-50%);
            width: .6rem;
            height: calc(100% + 5rem);
            z-index: -1;
        }
    }

    &--isFinished {
        .event-card__date,
        .event-card__date::after {
            background-color: var(--color-grey-5);
        }
    }
}