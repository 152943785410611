.photo-grid {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 3rem;

    &__item {
        width: 15rem;
    }

    &__image {
        aspect-ratio: 1/1;
        background-color: var(--color-secondary);
        border: .7rem solid var(--color-secondary);
        border-radius: 12rem;
        flex-shrink: 0;
        overflow: hidden;
        margin-bottom: 1rem;
        width: 100%;

        img {
            @extend %image-cover;
        }
    }

    &__content { 
        text-align: center;
    }
    &__title {
        color: var(--color-tertiary);
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: .5rem;
    }
    &__sub {
        color: var(--color-primary);
        font-size: 1.6rem;
        line-height: 1.2;
    }
}