.section-login {
    @extend %grid-base;
    @extend %section;
    position: relative;
    z-index: 5;

    .container {
        @extend %pt-180;
        position: relative;
        z-index: 1;
    }
}

.card-login {
    background-color: var(--color-grey-3);
    max-width: 75rem;
}