.notification {
    border-radius: 1.5rem;
    gap: 2rem;
    padding: 2rem 2rem 2rem 7.5rem;
    position: relative;
    margin-bottom: 1rem;

    &::before {
        color: currentColor;
        font-size: 3.5rem;
        opacity: .5;
        position: absolute;
        left: 2rem;
        top: 2rem;
    }

    &__text {
        color: currentColor;
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
    }

    &--danger {
        background-color: var(--color-danger);
        color: #fff;

        &::before { @extend %fig-calendar-exclamation; }
    }

    &--secondary {
        background-color: var(--color-secondary);
        color: #fff;

        &::before { @extend %fig-bell-ring; }
    }
}