.condition-step {

    & > *.accepted,
    & > *:first-child ~ *:not(.active) {
        @extend %is-hidden;
        height: 0;
    }

    & > *.active {
        @extend %is-visible;
        height: auto;
        transition: opacity 1s, visibility 1s;
    }
}