.carousel-controllers {
    @extend %flex-align-center;
    margin-bottom: 1rem;

    & > *:not(:last-child) {
        margin-right: 2rem;
    }

    .prev, .next {
        @extend .button-icon-primary;
        &::before { 
            @extend %uicons-general;
            font-size: 1.8rem;
        }
        svg { display: none; }
    }
    .prev::before { content: "\f14a"; }
    .next::before { content: "\f14b"; }
}

.swiper.carousel-1 {
    margin-left: 0;
    overflow: visible;
    position: relative;
    z-index: 0;
    width: 42rem;

    &::before {
        content: "";
        background-color: #fff;
        position: absolute;
        top: 0;
        right: 100%;
        height: 100%;
        width: 999rem;
        z-index: 2;
    }

    &--grey-3 {
        &::before { background-color: var(--color-grey-3); }
    }

    .swiper-slide {
        transition: $transition;
    }
    .swiper-slide-active {
        width: 50rem !important;
    }
    .swiper-slide:not(.swiper-slide-active) {
        opacity: .5;
        transform-origin: top left;
        transform: scale(.85);

        .event-card {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
            }
        }
    }
}