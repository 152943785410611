@include media('<xl') {
    
    // Page -> DASHBOARD
    .section-dashboard {
        display: block;
        position: relative;
    }
    .dashboard-aside {
        overflow: hidden;
        padding: 5rem 1rem 15rem 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 10rem;
        transition: $transition;

        &:hover {
            width: 32rem;
        }
    }
    .dashboard-aside-wrap {
        width: 32rem;
    }
    .dashboard-content {
        margin-left: 10rem;
        padding: 5rem 4rem 15rem 4rem;
        min-height: 95rem;
    }
    .dashboard-main-nav {
        padding-left: 0;
        a {
            margin-bottom: 1.5rem;
    
            i {
                font-size: 2rem;
                margin-right: 2rem;
                height: 6rem;
                width: 6rem;
            }
        }
    }

    // Page -> EVENT
    .section-event-desc {
        .container {
            padding: 0;
        }
    }
}

@include media('<md') {

    // Base -> FORM
    .nice-select {
        &-dropdown {
            .list .option {
                padding: 1.5rem;
            }
        }
    }

    // Components -> BUBBLE TEXT
    .bubble-text {
        font-size: 3rem;
        height: 38rem;
        width: 38rem;
    }

    // Components -> EVENT-CHRONOLOGY
    .event-chronology {
        @include change-grid-columns(1);
        &__left { display: none; }
    }

    // Page -> DASHBOARD
    .container-overview {
        display: block;
        width: 100%;
    }

    // Page -> HOME
    .section-goals {
        @include create-grid-base();
        .container { display: block; }
        .left { display: none; }
    }
    .section-join-us {
        .section-overlay { display: none; }
        .section-content { width: 100%; }
    }
    .section-events {
        @include create-grid-base();
        .container { display: block; }
    }

    // Page -> EVENT
    .section-event-sub {
        &__overlay { display: none; }
        .container {
            display: block;
        }
    }
    .section-event-resume,
    .section-checkout {
        padding-bottom: 18rem;
        .container {
            display: block;
        }
        &__card { 
            margin-top: 0;
            width: 100%; 
        }
        &__image {
            aspect-ratio: 1 / 1;
            position: relative;
            margin: -4rem auto 0 auto;
            max-height: 71.9rem;
            max-width: 71.9rem;
            width: auto;
            height: auto;
            z-index: 1;
        }
    }

    // Page -> MEMBER
    .page-member {
        .container {
            @include change-grid-columns(1);
        }
        .right {
            .card {
                margin-top: 0;
            }
        }
    }

    // Page -> REGISTER
    .page-subscription {
        .container {
            display: block;
        }

        .left, .right {
            padding-left: 0;
            padding-right: 0;
        }
        .right {
            padding-top: 38rem;
            &::after {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__bubble {
            left: calc(50% - 19rem);
        }
    }

    
}


@include media('<sm') {

    // Components -> BANNER
    .banner:not(.banner--pageHeader) {
        height: auto;
    }
    .banner {
        .container {
            grid-template-columns: 100%;
        }
    }

    // Components -> BLOCKS-OVERLAY
    .blocks-overlay {
        &__item {
            border-top-left-radius: 1.5rem;
            border-bottom-left-radius: 1.5rem;
        }
    }

    
    // Components -> CARDS-CONTENT
    .cards-content-2 {
        @include change-grid-columns(1);

        &__overlay { display: none; }

        & > &__item--large {
            grid-column: auto;
            grid-row: auto;
        }
    }

    // Components -> CARDS-OVERLAY
    .card {
        padding: 3rem;
    }
    .cards-content {
        @include change-grid-columns(1);

        &__image {
            top: -2rem;
        }

        &__item {
            &:nth-child(odd) {
                text-align: left;
                .cards-content__svg { right: 4rem; left: auto; }
            }
        }
    }

    // Components -> EVENT-CARD
    .event-card {

        &--2 {
            flex-flow: column nowrap;
            .event-card__image {
                position: static;
                height: 35rem;
                width: 100%;
            }
            .event-card__content {
                width: 100%;
            }
        }
    }

    // Components -> FILTER
    .filter {
        &__layout { display: none; }
    }

    // Components -> NOTIFICATIONS
    .notification {
        padding: 2rem;
        &::before { display: none; }
    }

    // Layout -> ROW
    .row-2c-smart {
        @include change-grid-columns(1);
    }

    // Page -> ABOUT
    .section-about-intro {
        &__overlay { display: none; }
        &__content { max-width: 100%; width: 100%; }
    }

    // Page -> CONTACT
    .section-contact {
        .container {
            grid-template-columns: 1fr;
            .right { padding: 0; }
        }
    }

    // Page -> EVENT
    .section-event-sub {
        &__overlay { display: none; }
        .container {
            display: block;
        }
    }
    .section-event-desc {
        .container {
            @include change-grid-columns(1);
        }
    }

    // Page -> HOME
    .section-become-member {
        .section-title { margin-bottom: 12rem; }
    }

    // Page -> LOGIN
    .section-login {
        @include create-grid-base($grid-gap: 2rem);
    }

}


@include media('<xs') {

    // Base -> TYPOGRAPHY
    h1, .h1 { font-size: 4rem; }
    h2, .h2 { font-size: 2.5rem; }
    h3, .h3 { font-size: 2rem; }
    h4, .h4 { font-size: 1.8rem; }

    // Base -> FORM
    .input-length-1 {
        width: 100%;
    }

    // Components -> BLOCKS-OVERLAY
    .blocks-overlay {
        &__item {
            padding: 2rem 0;

            &::before {
                right: calc(100% + 1.5rem);
            }

            &::after {
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;
            }
        }
    }

    // Components -> BUTTONS
    .btn-wrap {
        & > * {
            flex-grow: 1;
            margin-right: 0;
            text-align: center;
        }
    }

    // Components -> CAROUSEL
    .swiper.carousel-1 {
        transform: translateX(-2.5rem);

        .swiper-slide-active {
            width: 38rem !important;
        }
    }
    
    // Components -> EVENT-CARD
    .event-card {

        &__content {
            text-align: center;
        }

        &__date {
            left: 50%;
            transform: translate(-50%,-60%);
        }

        &__tag {
            float: none;
            margin-top: 8rem;
            margin-bottom: 1rem;
        }

        &__title {
            margin-top: 0;
        }

        &__desc {
            text-align: left;
        }

        &__footer {
            & > * { width: 100% }
        }

        &--2 {
            .event-card__tag { margin-top: 0; }
            .event-card__content, .event-card__desc { text-align: center; }
        }
    }

    // Components -> STABLE
    .filter__wrap {
        border-radius: 1.5rem;
        flex-grow: 1;
        padding: 1.5rem;
    }

    // Components -> STABLE
    .stable-label {
    
        &__item {
            flex-flow: column wrap;
            span:first-child { min-width: 0; }
            span:last-child { text-align: center; }
        }
    }

    // Page -> HOME
    .events-carousel {
        .event-card {
            width: 38rem;
        }
    }

    .tabulator-event-cell .image {
        display: none;
    }

    .event-participant__row {
        flex-direction: column;
    }
}