.gallery {
    @include create-grid-columns-smart(
        $column-nbr: 3,
        $break-sensitivity: .8,
    );

    &__item {
        aspect-ratio: 16 / 11;
        border-radius: 1.5rem;
        display: block;
        overflow: hidden;
        position: relative;

        &::after {
            content: "";
            background-color: var(--color-primary);
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: $transition;
        }
        
        &:hover {
            &::after {
                opacity: .6;
            }

            img {
                transform: scale(1.1);
            }
        }

        img { 
            @extend %image-cover; 
            transition: $transition;
        }
    }
}

.gallery-grid {
    @include create-grid-columns-custom(2fr repeat(2, 1fr));
    grid-auto-rows: 50%;
    position: relative;

    &::before, &::after {
        content: "";
        aspect-ratio: 1/1;
        border-radius: 999rem;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
    }
    &::before {
        background-color: var(--color-grey-3);
        right: 14.5%;
        width: 10.5%;
    }
    &::after {
        background-color: var(--color-primary);
        right: 17%;
        width: 8%;
    }

    &__item {
        border-radius: 1.5rem;
        display: block;
        overflow: hidden;
        position: relative;

        img { 
            @extend %image-cover; 
            transition: $transition;
        }
    }

    & > &__item:first-child { grid-column: span 1; grid-row: span 2 }
}