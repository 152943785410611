#wrapper {
    scroll-padding-top: 30rem;
    scroll-behavior: smooth;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    height: 100vh;
    width: 100%;
}

.container {
    margin: 0 auto;
    max-width: 192rem;
    width: 100%;
}