.page-member {
    .container {
        @include create-grid-columns-custom(50rem 1fr);
    }
    .left {
        justify-content: center;
    }
    .right {
        .card {
            margin-top: 13rem;
        }
    }
}