.members-list {
    @include create-grid-columns-smart(
        $column-nbr: 4,
        $break-sensitivity: .8,
    );
}

// Member card
.member-card {
    @extend %new-stack;
    background-color: #fff;
    border-radius: 1.5rem;
    margin-top: 13rem;
    padding: 14rem 2rem 5rem 2rem;
    position: relative;
    text-align: center;
    max-width: 37.5rem;

    &::before {
        @include before-after-background(
            $url: '/storage/icones/bubble-left-primary.svg',
            $position: left bottom,
            $size: 17rem 17rem,
            $right: 0,
            $top: 0,
            $width: 11rem,
            $height: 13rem,
        );
        border-top-right-radius: 1.5rem;
        opacity: .2;
        z-index: -1;
    }

    &__picture {
        background-color: var(--color-secondary);
        border: 1rem solid var(--color-secondary);
        border-radius: 99rem;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 21.6rem;
        width: 21.6rem;
        img { @extend %image-cover; }
    }

    &__title {
        @extend %h5;
        margin-bottom: 0;
    }

    &__sub {
        color: var(--color-primary);
    }

    &__actions {
        @extend %flex-center-center;
        gap: 1rem;
        margin-top: 1rem;
    }

    &__action {
        @extend %flex-center-center;
        background-color: var(--color-primary-white-2);
        border-radius: 99rem;
        color: var(--color-primary);
        cursor: pointer;
        height: 4.6rem;
        width: 4.6rem;
        transition: $transition;

        i {
            font-size: 2.5rem;
            transition: $transition;
            &::before { transform: translateY(3px); }
        }

        &--feat {
            background-color: var(--color-primary);
            color: #fff;
        }

        &:hover, &:focus-visible {
            background-color: var(--color-secondary);
            i { color: #fff; }
        }
    }

    &__verified {
        @extend %flex-center-center;
        background-color: var(--color-secondary);
        border: 1rem solid var(--color-grey-3);
        border-radius: 99rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 5.8rem;
        width: 5.8rem;
        transform: translate(-50%,50%);

        &::after {
            @extend %fig-shield-check;
            font-size: 2.2rem;
            color: #fff;
        }
    }
}

// Member card horizontal
.member-card-h {
    @extend %new-stack;
    background-color: #fff;
    border-radius: 1.5rem;
    margin: 13rem auto 5rem auto;
    padding: 20rem 2rem 7rem 2rem;
    position: relative;
    text-align: center;

    &::before {
        @include before-after-background(
            $url: '/storage/icones/bubble-left-primary.svg',
            $position: left bottom,
            $size: 23rem 23rem,
            $right: 0,
            $top: 0,
            $width: 13rem,
            $height: 18rem,
        );
        border-top-right-radius: 1.5rem;
        opacity: .2;
        z-index: -1;
    }

    &::after {
        @include before-after-background(
            $url: '/storage/arriere-plan/section-line-secondary.svg',
            $width: 11rem,
            $height: 1.3rem,
            $bottom: 0,
            $left: 50%,
        );
        transform: translate(-50%,50%) rotate(-90deg);
    }

    &__picture {
        background-color: var(--color-secondary);
        border: 1.5rem solid var(--color-secondary);
        border-radius: 99rem;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 31rem;
        width: 31rem;
        img { @extend %image-cover; }
    }

    &__title {
        @extend %h3;
        @extend %line-after-secondary;
        margin-bottom: 3rem;

        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__label {
        line-height: 1;
        margin-bottom: 2.5rem;
        text-align: center;
        * { display: block; }
        *:last-child {
            color: var(--color-primary);
            font-size: 2.8rem;
            line-height: 1.2;
            font-weight: bold;
        }
        a:hover, a:focus-visible { color: var(--color-secondary); }
    }
}