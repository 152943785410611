.blocks-overlay {

    &__item {
        background-color: var(--color-grey-3);
        border-top-left-radius: 99rem;
        border-bottom-left-radius: 99rem;
        padding: 2rem 2rem 2rem 7rem;
        position: relative;
        margin-bottom: 1.5rem;

        &::before {
            content: "";
            background-color: var(--color-primary);
            position: absolute;
            top: 3.75rem;
            right: calc(100% - 2.5rem);
            height: 2px;
            width: 999rem;
        }

        &::after {
            content: "";
            background-color: var(--color-grey-3);
            position: absolute;
            left: 100%;
            top: 0;
            height: 100%;
            width: 999rem;
        }
    }

    &__title {
        @extend %h4;
        color: var(--color-primary);
        margin-bottom: 0;
    }

    // Item variant 1
    &__item--primary {
        background-color: var(--color-primary);
        &::before { display: none; }
        &::after { background-color: var(--color-primary); }

        & > * { color: #fff; }

        p { opacity: .8; }
    }
}