.page-subscription {
    @include create-grid-base(170rem);
    background-color: var(--color-grey-3);
    position: relative;
    z-index: 6;

    .container {
        @include create-grid-columns-custom(52% 48%, 0);
    }

    .left {
        padding: 7rem 5.5rem 15rem 0;
    }

    .right {
        @extend %new-stack;
        background-color: var(--color-primary-white-2);
        padding: 37rem 0 15rem 5.5rem;

        &::before {
            @include before-after-background(
                $url: '/storage/icones/bubble-left-primary.svg',
                $position: left bottom,
                $size: 58rem 60rem,
                $left: 100%,
                $top: 0,
                $width: 58rem,
                $height: 42rem,
                $self-centered: 'X',
            );
            opacity: .1;
            z-index: -1;
        }

        &::after {
            content: "";
            background-color: var(--color-primary-white-2);
            position: absolute;
            top: 0;
            left: 100%;
            height: 100%;
            width: 999rem;
            z-index: -2;
        }
    }

    &__bubble {
        position: absolute;
        top: -8rem;
        left: -2rem;
    }
}