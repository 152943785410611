.cropperjs-modal {
    border: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 65rem;

    &::backdrop {
        background: rgba(#16263F, .6);
    }

    &__title {
        background-color: var(--color-primary);
        color: #fff;
        font-weight: bold;
        padding: 1.5rem 3rem;
    }

    &__content {
        padding: 2rem;
    }

    &__imageWrap {
        height: 40rem;
        width: 100%;
    }

    &__footer {
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: flex-end;
        padding: 2rem;

        & > * { margin: 0; }
    }
}