.header {
    padding: 1.5rem 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;

    .container {
        @include create-grid-base(170rem);
    }

    &__wrap {
        @extend %flex-align-center;
    }

    &__logo {
        display: block;
        margin-right: 4rem;
        width: 22.5rem;
        transition: none;
    }

    &__nav {
        @extend %flex-align-center;
        padding-right: 2rem;
        flex-shrink: 0;
    }

    &__menu {
        @extend %flex-align-center;

        li {
            a {
                color: #fff;
                display: block;
                font-size: 1.7rem;
                font-weight: bold;
                padding: 1rem 2rem;
                text-transform: uppercase;

                &:hover, &:focus { color: var(--color-primary); }
            }
        }
    }

    &__extra {
        @extend %flex-align-center;
        justify-content: flex-end;
        flex-grow: 1;
        flex-shrink: 0;

        & > *:not(:last-child) {
            margin-right: 2rem;
        }
    }

    &__button {
        @extend .button-primary;
        padding: 1.4rem 3rem;
        margin-bottom: 0;
    }

    &__link {
        color: #fff;
        font-weight: bold;
        letter-spacing: .05em;
        position: relative;

        i::before { transform: none; }

        &::after {
            content: "";
            background-color: #fff;
            position: absolute;
            bottom: -3px;
            left: 0;
            height: 1px;
            width: 100%;
            transition: $transition;
        }

        &:hover, &:focus {
            color: var(--color-primary);
            &::after { background-color: var(--color-primary); }
        }
    }
    &__link--2 {
        border: 2px solid #fff;
        border-radius: 99rem;
        font-size: 1.5rem;
        padding: .5rem 1.5rem;

        &::after {
            display: none;
        }

        &:hover {
            border-color: var(--color-primary);
        }
    }
}

// Header sticky
.header.is-sticky {
    background-color: var(--color-tertiary);
    padding: 1rem 0;
    position: fixed;
    animation: fadeBottomSmall $transition ease-in-out forwards;

    .header__logo {
        width: 10rem;
    }
}