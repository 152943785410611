.cards-content {
    @include create-grid-columns(2);
    padding-top: 5rem;
    position: relative;

    &__image {
        border: 1.5rem solid #fff;
        border-radius: 22rem;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 20rem;
        width: 20rem;
        z-index: 1;
    }

    &__rightStyle {
        @extend %new-stack;

        &::before {
            content: "";
            background-color: #fff;
            border-radius: 1.5rem;
            position: absolute;
            left: -1rem;
            bottom: -1rem;
            z-index: -2;
            width: 999rem;
            height: 100%;
        }

        &::after {
            content: "";
            background-color: var(--color-primary);
            position: absolute;
            left: calc(100% - 3rem);
            bottom: 0;
            z-index: -1;
            width: 999rem;
            height: 100%;
        }
    }

    &__item {
        border-radius: 1.5rem;
        padding: 4rem;
        position: relative;

        & > * { @extend %zindex-1; }

        &--1 { background-color: var(--color-primary); }
        &--2 { background-color: var(--color-tertiary-3); }
        &--3 { background-color: var(--color-tertiary); }
        &--4 { background-color: var(--color-secondary); }

        p {
            color: #fff;
            opacity: .8;
        }
    }
    
    & > .cards-content__item:nth-child(odd) {
        text-align: right;
        .cards-content__svg { right: auto; left: 4rem; }
    }

    &__svg {
        display: inline-block;
        opacity: .08;
        position: absolute;
        top: 50%;
        right: 4rem;
        height: 16.1rem;
        width: 16.1rem;
        transform: translateY(-50%);
        z-index: 0;
    }

    &__titleSup {
        color: #fff;
        font-size: 2.2rem;
        margin-bottom: 0;
        text-transform: uppercase;
    }

    &__title {
        @extend %h3;
        color: #fff;
        font-size: 2.1rem;
    }
}

.cards-content-2 {
    @include create-grid-columns(2);
    margin-bottom: $grid-gap-default;
    position: relative;

    &__overlay {
        border: 1.5rem solid var(--color-grey-3);
        border-radius: 22rem;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 15rem;
        width: 15rem;
        z-index: 1;
    }

    &__item {
        @extend %flex-align-center;
        background-color: #fff;
        border-radius: 1.5rem;
        gap: 2rem;
        flex-flow: row wrap;
        padding: 2rem 3rem;
        position: relative;

        &::before {
            content: "";
            background: url(/storage/icones/bubble-left-primary.svg) center center no-repeat;
            background-size: cover;
            flex-shrink: 0;
            width: 9rem;
            height: 9.25rem;
            z-index: 0;
            margin-right: 3rem;
            transform: scaleX(-1);
        }

        // Variant primary
        &--primary {
            .cards-content-2__title { color: var(--color-primary); }
        }

        // Variant secondary
        &--secondary {
            &::before { background-image: url('/storage/icones/bubble-left-secondary.svg'); }
            .cards-content-2__title { color: var(--color-secondary); }
        }

        // Variant large
        &--large {
            justify-content: center;
            flex-flow: column nowrap;
            padding: 4rem 6rem;
            text-align: center;

            &::before { 
                background-image: url('/storage/icones/bubble-left-white.svg'); 
                opacity: .2; 
                position: relative;
                top: auto;
                left: auto;
            }
            .cards-content-2__title { color: #fff; margin-bottom: 0; }
            .cards-content-2__desc { color: #fff; opacity: .7; }

            &.cards-content-2__item--primary { 
                background-color: var(--color-primary); 
                .cards-content-2__top { color: var(--color-primary); }
            }
            &.cards-content-2__item--secondary { 
                background-color: var(--color-secondary); 
                .cards-content-2__top { color: var(--color-secondary); }
            }
        }
    }

    &__itemWrap {
        flex: 1 1 0;
        min-width: 35rem;
    }

    &__title {
        @extend %h4;
    }

    &__top {
        background-color: #fff;
        border-radius: 1rem;
        display: inline-block;
        font-weight: bold;
        padding: .5rem 1.5rem;
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        text-transform: uppercase;
    }

    & > &__item--large {
        grid-column: 2 / span 1;
        grid-row: 1 / span 2;
    }
}