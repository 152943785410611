.pagination {

    margin-top: 50px;

    &__list {
        @extend %flex-center-center;
        flex-flow: row wrap;
        gap: 1rem;
    }

    &__item {
        @extend %flex-center-center;
        border: 2px solid var(--color-grey);
        border-radius: 4rem;
        color: var(--color-grey);
        cursor: pointer;
        font-weight: bold;
        height: 4rem;
        width: 4rem;

        &:hover {
            background-color: var(--color-grey);
            color: #fff;
        }

        &--current {
            background-color: var(--color-primary) !important;
            border-color: var(--color-primary) !important;
            color: #fff !important;
            cursor: default;
        }
    }
}