.alert-container {
    position: fixed;
    top: 20%;
    right: 5rem;
    z-index: 9999999999;
    max-width: 50rem;
    width: 100%;

    .alert {
        @extend %flex-align-center;
        gap: 2rem;
        margin-bottom: 1.5rem;
        padding: 2rem;
        position: relative;
        width: 100%;

        &::after {
            @include before-after-background(
                $url: '/storage/svg/african-line-overlay-1-white.svg',
                $bottom: .5rem,
                $left: 50%,
                $width: 100%,
                $height: 3rem,
                $self-centered: 'X',
            );
            opacity: .2;
        }

        &--new {
            animation: fadeInUp $transition ease-in-out forwards;
        }

        &--danger, &--error {
            background-color: var(--color-danger);
            color: #fff;
        }
        &--success {
            background-color: var(--color-success);
            color: #fff;
        }

        &__close {
            cursor: pointer;
            font-size: 2rem;
            position: absolute;
            top: 1rem;
            right: 1rem;

            &::before {
                @extend %fig-cross;
            }
        }

        &__icon {
            font-size: 4rem;
        }

        &__message {
            font-size: 2rem;
        }

        &__progress {
            background-color: rgba(#fff, .5);
            position: absolute;
            bottom: 0;
            left: 0;
            height: 5px;
            width: 0%;
            animation-name: progress;
            animation-timing-function: linear;
        }
    }
}