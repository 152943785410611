.page-about {
    
}

.section-about-intro {
    @extend %new-stack;
    @extend %line-section;
    background-color: var(--color-primary-white-2);
    padding: 7rem 0 14rem 0;
    position: relative;
    z-index: 6;

    &::before {
        content: "";
        background: url('/storage/icones/bubble-left-primary.svg') left bottom no-repeat;
        background-size: 58rem 60rem;
        width: 78rem;
        height: 36rem;
        z-index: 0;
        position: absolute;
        top: 0;
        left: 100%;
        transform: translateX(-50%);
        opacity: 0.1;
        z-index: -1;
    }

    &__overlay {
        aspect-ratio: 1/1;
        border: 3rem solid var(--color-grey-3);
        border-radius: 99rem;
        overflow: hidden;
        position: absolute;
        top: calc(-7vw + 10rem);
        right: 100%;
        width: 50%;
        //max-width: 71.9rem;
        z-index: 10;
        transform: translateX(55%);
        img { @extend %image-cover; }
    }

    &__content {
        width: 65vw;
        max-width: 95rem;
        margin-left: auto;
    }
}

.section-about-gallery {
    background-color: var(--color-grey-3);
    padding: 15rem 0 7rem 0;
}

.section-about-job {
    background-color: var(--color-grey-3);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 12rem));
    //padding: 7rem 0 15rem 0;
    padding: 15rem 0;
}

.section-about-why {
    padding: 4rem 0 15rem 0;
}