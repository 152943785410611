.page-content-event {
    padding-bottom: 8rem;
}

.section-event-resume {
    @extend %new-stack;
    background-color: var(--color-grey-3);
    overflow: hidden;
    padding: 7rem 0 30rem 0;

    &::before, &::after {
        @include shape-elt(
            $width: 76rem, 
            $rounded: 'round',
        );
        content: "";
        position: absolute;
        opacity: .2;
        z-index: -1;
    }

    &::before {
        background-color: var(--color-primary);
        top: -8rem;
        left: -15rem;
    }

    &::after {
        background-color: var(--color-secondary);
        bottom: -8rem;
        right: -15rem;
    }

    .container {
        display: flex;
        position: relative;
    }

    &__card {
        margin-top: 9rem;
        position: relative;
        text-align: center;
        width: 60%;
        z-index: 1;
    }

    &__image {
        border: 3rem solid #fff;
        border-radius: 999rem;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        height: 71.9rem;
        width: 71.9rem;
        img { @extend %image-cover;  }
    }
}

.section-event-desc {
    @extend %new-stack;
    margin-top: -9rem;
    padding-bottom: 10rem;

    &::before {
        content: "";
        background-color: #fff;
        border-top-left-radius: 2.5rem;
        border-top-right-radius: 2.5rem;
        position: absolute;
        top: -5rem;
        left: 50%;
        width: $grid-width-default;
        height: 14rem;
        transform: translateX(-50%);
    }

    .container {
        padding: 0 $grid-gap-default;
        @include create-grid-columns-smart(2);
    }
}

.section-event-sub {
    @extend %new-stack;
    background-color: var(--color-grey-3);
    padding: 7rem 0 15rem 0;

    &__overlay {
        @extend %grid-item-full;
        border: 3rem solid #fff;
        border-radius: 999rem;
        overflow: hidden;
        position: absolute;
        top: -6rem;
        left: -20rem;
        text-align: center;
        height: 95.6rem;
        width: 95.6rem;
        z-index: -1;
        img {
            @extend %image-cover;
            display: inline-block;
        }
    }

    .container {
        @include create-grid-columns-custom(1fr calc(35vw + 42rem));
    }

    &__card {
        grid-column: 2 / -1;
    }
}

.event-participant {

    &__row {
        display: flex;
        gap: .5rem 1.5rem;
        align-items: center;
        margin-bottom: 1.5rem;
    }

    &__icon {
        @extend %flex-center-center;
        background-color: var(--color-primary-white);
        border-radius: 5rem;
        flex-shrink: 0;
        height: 5rem;
        width: 5rem;
        &::before {
            @extend %fig-user;
            color: var(--color-primary);
            font-size: 2rem;
            transform: translateY(-.2rem);
        }
    }

    &__name {
        @extend %h5;
        flex: 0 1 33%;
        margin-bottom: 0;
        text-align: left;
    }

    &__option {
        flex-grow: 1;
        font-size: 1.7rem;
        line-height: 1.2;

        .option-label {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .75rem;
            font-weight: bold;
            &::before { font-size: .8em; }
        }
    }
    &__option--with {
        .option-label {
            color: var(--color-secondary);
            &::before { @extend %fig-check; }
        }
    }
    &__option--without {
        .option-label {
            color: var(--color-danger);
            &::before { @extend %fig-cross; }
        }
    }

    &__price {
        color: var(--color-secondary);
        font-size: 2.5rem;
        font-weight: bold;
        flex: 0 1 25%;
        text-align: right;
    }

    &__total {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;

        *:first-child {
            color: var(--color-tertiary);
            font-size: 2rem;
            font-weight: bold;
        }

        *:last-child {
            color: var(--color-primary);
            font-size: 3.5rem;
            font-weight: bold;
        }
    }

}