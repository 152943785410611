.page-content-event {
    padding-bottom: 8rem;
}

.section-checkout {
    @extend %new-stack;
    background-color: var(--color-grey-3);
    overflow: hidden;
    padding: 7rem 0 30rem 0;

    &::before, &::after {
        @include shape-elt(
            $width: 76rem, 
            $rounded: 'round',
        );
        content: "";
        position: absolute;
        opacity: .2;
        z-index: -1;
    }

    &::before {
        background-color: var(--color-primary);
        top: -8rem;
        left: -15rem;
    }

    &::after {
        background-color: var(--color-secondary);
        bottom: -8rem;
        right: -15rem;
    }

    .container {
        display: flex;
        position: relative;
    }

    &__card {
        margin: 9rem auto 0 auto;
        position: relative;
        text-align: center;
        width: 60%;
        z-index: 1;
    }

    &__image {
        border: 3rem solid #fff;
        border-radius: 999rem;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        height: 71.9rem;
        width: 71.9rem;
        img { @extend %image-cover;  }
    }

    &__table {
        max-width: 45rem;
        &__line {
            display: flex;
            align-items: center;
            flex-flow: row wrap;
            gap: 10px;
            justify-content: space-between;

            span:first-child {
                color: var(--color-tertiary);
                font-weight: bold;
            }

            span:last-child {
                color: var(--color-primary);
                font-size: 4rem;
                line-height: 1.2;
                font-weight: bold;
            }
        }
    }
}