/**
 * ------------------------------------------------
 * CSS Variables
 * ------------------------------------------------
 */

:root {
    --color-primary: #BC9168;
    --color-primary-2: #CCAB8B;
    --color-primary-white: #F8F4EF;
    --color-primary-white-2: #EBDED2;
    --color-secondary: #528881;
    --color-secondary-white: #f3f7f7;
    --color-secondary-white-2: #E8F1F0;
    --color-tertiary: #16263F;
    --color-tertiary-2: #1B2C46;
    --color-tertiary-3: #365481;
    --color-tertiary-4: #d8e1ef;
    --color-tertiary-5: #1F3558;
    --color-dark: #0d1726;
    --color-grey: #6E7F99;
    --color-grey-2: #888;
    --color-grey-3: #F5F5F5;
    --color-grey-4: #eeeeee;
    --color-grey-5: #ccc;
    --color-grey-6: #aaa;
    --color-white: #fff;
    --color-danger: #fc4453;
    // --color-infos: #3498db;
    --color-success: #28b133;
    // --color-warning: #f39c12;
    --color-facebook: #1877f2;
    --color-instagram: #c32aa3;
    --color-linkedin: #0a66c2;
    --color-twitter: #1da1f2;
    --font-family-1st: 'Lato', sans-serif; 
    --font-family-2nd: 'Lato', sans-serif; 
}

/**
 * ------------------------------------------------
 * SASS Config Variables
 * ------------------------------------------------
 */

/// Body config
$body-color: var(--color-grey);
$body-font: var(--font-family-1st);
$body-size: 1.8rem;
$body-line-height: 1.6;

/// Heading config
$heading-color: var(--color-tertiary);
$heading-font: var(--font-family-1st);

/// Breakpoints
$breakpoints: (
    'tn': 20em,         // 320px
    'xs': 30em,         // 480px
    'sm': 50em,         // 800px
    'md': 62em,         // 992px
    'lg': 75em,         // 1200px
    'xl': 87.5em,       // 1400px
    'header': 90.62em   // 1450px
);

/// Google fonts config
///
/// List here all the google fonts that you want to use, following this pattern inside the $google-fonts array: 
///     -----------------------------------------------------------------
///     -> '[font-name]': ([weight-1], [weight-2], ...)
///     -----------------------------------------------------------------
///     -> [weight] is a number ===> Regular font
///     -> [weight] is a string ===> Italic font
///
/// !Important -> All font weight must be grouped and can't be mixed. 
///                 Regular fonts must be first and Italic must be last.
///                 Example: 'Poppins': (300, 400, 700, '400', '600')
///
$google-fonts: (
    'Lato': (300, 400, 700, '400'),
);

/// Spacings
///
/// Add here all the spacing that you want to use as and extend or with class helpers.
/// To add a spacing, follow this pattern inside the $spacings array::after
///     -----------------------------------------------------------------
///     -> [number]: ([css-abbreviation-1], [css-abbreviation-2], etc)
///     -----------------------------------------------------------------
///     [number]: The value that will be in the CSS property in "rem".
///     [css-abbreviation]: The abbreviation that will be use for the value.
///         -> Available abbreviations: 
///             ° Margin: 'm', 'mx', 'my', 'mt', 'mr', 'mb', 'ml'
///             ° Padding: 'p', 'px', 'py', 'pt', 'pr', 'pb', 'pl'
///     -----------------------------------------------------------------
///     -> Examples: 
///
///        ° 150: ('py', 'pb', 'ml')
///             In the example above, the [number] is a type of "Number", so we would be able to use:
///                 -> @extend %py-150 /// @extend %pb-150 /// @extend %ml-150
///
///        ° '150': ('py', 'pb', 'ml')
///             In the example above, the [number] is a type of "String", so we would be able to use:
///                 -> @extend %py-150 /// @extend %pb-150 /// @extend %ml-150
///                 -> .u-py-150 /// .u-pb-150 /// .u-ml-150
///
///     -----------------------------------------------------------------
///     
/// 
$spacings: (
    '0'     : ('mb','ml','pl','pb'),
    '5'     : ('mr','mb'),
    '10'    : ('mt','mb','my'),
    '20'    : ('mt','mb'),
    '30'    : ('mt','mb'),
    '40'    : ('mt'),
    '50'    : ('mt','my','mb'),
    '70'    : ('mb'),
    80      : ('py'),
    100     : ('pb'),
    150     : ('pt', 'pb'),
    180     : ('pt', 'pb'),
    200     : ('pt'),
    300     : ('pb'),
);

// Section spacing
$section-pad: 15rem 0;
$section-pad-sm: 5rem 0;

/// Other config
$transition: .4s;

/// Grid config
///
/// Note: These variables down below are set to be the default grid configuration.
/// You can feel free to generate an other grid with different setting with the appropriate mixin.
///
$grid-width-default: 140rem;  // 1400px
$grid-gap-default: 2rem;
$grid-gap-rows: 2rem;

$grid-rows: (
    "2c": "g",
    "3c": "g",
    "3-9": "g",
    "12": 'sm',
);

/// Uicons fonts config
///
/// $uicons-type: Choose the style of the icon that you want to use (Uicons: https://www.flaticon.com/uicons)
/// -> Available choices : 'bold-rounded', 'regular-rounded', 'regular-straight', 'solid-rounded', 'solid-straight'
/// -> Leave empty if you don't want to use uicons
///
/// $uicons-fonts: Add a list of all icons that you want to use directly in the HTML template
/// -> Tips: To see the list of all available icons, go to the ../base/_fonts.scss file and 
///     check the big commented line which list all the available icons.
///     When you find the icon that you want, just copy the line and paste this one into the SASS array "$uicons-fonts".
///     ° Example: I want to use an angle-down icon in the template. I juste have to copy the line "angle-down":"\f11a" and paste it in the sass array.
///                 So now in my SASS files, I can use the icon by adding an extend as below -> "@extend %fig-angle-down".
///                 Or in the template, I can use the icon by adding this element "<i class="fig-angle-down"></i>".
///
/// $uicons-brands: If you want to use the brands icons.
/// -> Leave empty if you don't want to use uicons-brands
///
/// $uicons-brands-html: Same as the $uicons-html but for the brands icons, 
///     except that the icons should begin with "fib" instead of "fig"
/// 

/// All general icons that can be used like below:
/// -> Extend: @extend fig-[icon-name]
/// -> Template: <i class="fig-[icon-name]"></i>
///
$uicons-type: 'regular-rounded';
$uicons-fonts: (
    "angle-down":"\f11a",
    "arrow-to-top":"\f157",
    "arrow-right":"\f14b",
    "bell":"\f183",
    "bell-ring":"\f181",
    "building":"\f1ab",
    "calendar":"\f1bf",
    "calendar-check":"\f1b7",
    "calendar-exclamation":"\f1b9",
    "calendar-minus":"\f1bc",
    "calendar-plus":"\f1be",
    "check":"\f1fb",
    "checkbox":"\f1fc",
    "clock":"\f231",
    "cross":"\f27c",
    "dashboard":"\f289",
    "document":"\f2a5",
    "edit":"\f2b5",
    "envelope":"\f2c2",
    "euro":"\f2c4",
    "eye":"\f2ce",
    "eye-crossed":"\f2cc",
    "globe":"\f315",
    "grid":"\f31c",
    "id-badge":"\f351",
    "marker":"\f39b",
    "map-marker":"\f398",
    "lock":"\f38c",
    "list":"\f388",
    "phone-call":"\f3eb",
    "picture":"\f3f3",
    "search":"\f454",
    "shield-check":"\f45f",
    "sign-out-alt":"\f472",
    "trash":"\f505",
    "user":"\f525",
    "user-add":"\f523",
    "users":"\f527",
);
/// All brands icons that can be used like below:
/// -> Extend: @extend fib-[icon-name]
/// -> Template: <i class="fib-[icon-name]"></i>
///
$uicons-brands: 'true';
$uicons-brands-fonts: (
    "facebook":"\f14f",
    "instagram":"\f169",
    "linkedin":"\f176",
    "twitter":"\f1b7",
);

/// Utilities config
///
/// Variables down below are only here to make some CSS declarations easier to write and 
/// to make those one unify.
///
$themeName: "cezam";
$themePath: "/themes/" + $themeName;
$storage: "/storage";
