@keyframes spinning {
    from { transform: rotate(0); }
    to { transform: rotate(360deg); }
}
@keyframes fadeInBottomSmall {
    from { opacity: 0; visibility: hidden; transform: translateY(-1rem); }
    to { opacity: 1; visibility: visible; transform: translateY(0); }
}
@keyframes growTransp {
    from { opacity: .8; transform: scale(1); }
    to { opacity: 0; transform: scale(4); }
}