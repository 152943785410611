.partners {
    @include create-grid-columns-smart(
        $column-nbr: 4,
        $break-sensitivity: .85,
    );

    &__item {
        @extend %flex-center-center;
        background-color: #fff;
        padding: 2rem;
        text-align: center;
    }
}