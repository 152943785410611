// Color helpers
.u-color-primary { color: var(--color-primary) !important; }
.u-color-secondary { color: var(--color-secondary) !important; }
.u-color-tertiary { color: var(--color-tertiary) !important; }
.u-color-white { color: #fff !important; }

// Display helpers
.u-inline-block { display: inline-block !important; }

// Flex helpers
.u-align-items-end { align-items: flex-end !important; }

// Grid helpers
.u-col-grid-full { grid-column: 1 / -1 !important; }

// Text helper
.u-text-bold { font-weight: bold !important; }
.u-text-left { text-align: left !important; }
.u-text-center { text-align: center !important; }
.u-text-feat { font-size: 2rem !important; }
.u-text-italic { font-style: italic !important; }
.u-text-normal { font-weight: normal !important; }
.u-text-right { text-align: right !important; }
.u-text-uppercase { text-transform: uppercase !important; }
.u-text-transform-none { text-transform: none !important; }
.u-text-07 { font-size: .7em !important; }
.u-text-09 { font-size: .9em !important; }
.u-text-12 { font-size: 1.2em !important; }
.u-text-sm { font-size: 1.6rem !important; }

// Spacing helper
.u-margin-x-auto { margin-left: auto !important; margin-right: auto !important; }

// Width helper
.u-full-width { width: 100% !important; }

// Other helper
.u-gap-icon-i {
    display: inline-flex !important;
    align-items: center !important;
    gap: .5rem 2rem !important;
}




/* -----------------------------------------------
 *  Auto generated helpers
 * -> You should not modify that part
 ------------------------------------------------- */

// Spacing helpers
@each $value, $abbreviations in $spacings {
    @if (type-of($value) == 'string') {
        @each $abbreviation in $abbreviations {
            .u-#{$abbreviation}-#{$value} {
                @extend %#{$abbreviation}-#{$value}-important;
            }
        }
    }
}