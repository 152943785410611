.socials {
    @extend %flex-center-center;
    gap: 1.5rem;

    &__item {
        @extend %flex-center-center;
        background-color: var(--color-primary-white-2);
        border-radius: 99rem;
        color: var(--color-primary);
        cursor: pointer;
        height: 5.5rem;
        width: 5.5rem;
        transition: $transition;

        i {
            font-size: 2.5rem;
            transition: $transition;
            &::before { transform: translateY(3px); }
        }

        &:hover, &:focus-visible {
            background-color: var(--color-primary);
            i { color: #fff; }
        }
    }
}