.list-1 {
    padding-left: 1.5rem;
    margin-bottom: 2rem;
    li {
        font-weight: bold;
        margin-bottom: 1rem;
        padding-left: 2.5rem;
        position: relative;
        text-align: left;

        &::before {
            content: "►";
            color: var(--color-primary);
            display: inline-block;
            font-size: 1.6rem;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(.2em);
        }
    }
}

.list-icon {

    .list-icon__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: .5rem;
        
        &, & > * {
            color: currentColor;
        }

        i { 
            margin-right: 1.5rem; 
        }
    }
}