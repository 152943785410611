.identity-card {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 1rem 2rem 1rem 7rem;
    position: relative;

    &::before {
        content: "";
        background-color: var(--color-tertiary-3);
        opacity: .15;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 82%;
        width: 95%;
    }

    &::after {
        @extend %fig-user;
        @extend %flex-center-center;
        color: #fff;
        background-color: var(--color-tertiary-3);
        border-radius: 5rem;
        font-size: 2.5rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 5rem;
        height: 5rem;
    }

    & > * {
        @extend %zindex-1;
    }

    &__title {
        color: currentColor;
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: .05em;
    }

    &__sub {
        color: currentColor;
        font-size: 1.6rem;
        margin-bottom: 1rem;
        opacity: .7;
    }

    &--2 {
        &::before, &::after {
            background-color: var(--color-primary);
        }
        &::before {
            top: .5rem;
        }
        .identity-card__title {
            color: var(--color-primary);
            font-size: 2rem;
        }
        .identity-card__sub {
            font-size: 2rem;
        }
    }
}
.identity-card.identity-card--email {
    &::after {
        content: "\f2c2";
    }
}
.identity-card.identity-card--phone {
    &::after {
        content: "\f3eb";
    }
}