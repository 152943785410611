.list-card {
    counter-reset: list-card-item;
    margin-bottom: 2rem;
    
    &__item {
        background-color: var(--color-secondary-white-2);
        border-radius: 1rem;
        counter-increment: list-card-item;
        margin: 4rem 0 2rem 0;
        padding: 3rem 2rem 1rem 2rem;
        position: relative;
        animation: fadeInBottomSmall .5s ease-in-out forwards;
    }

    &__head {
        background-color: var(--color-secondary);
        border-radius: 1rem;
        color: #fff;
        font-weight: bold;
        padding: .5rem 2rem;
        position: absolute;
        top: 0;
        left: 2rem;
        text-transform: uppercase;
        transform: translateY(-50%);

        &::after {
            content: counter(list-card-item);
        }
    }

    &__remove {
        @extend %flex-center-center;
        background-color: var(--color-danger);
        border: none;
        border-radius: 1rem;
        color: #fff;
        cursor: pointer;
        font-size: 2rem;
        line-height: 1;
        position: absolute;
        padding: .5rem 1.5rem;
        top: 0;
        right: 2rem;
        transform: translateY(-50%);
    }

    &--bordered {
        border-top: 3px dashed var(--color-tertiary-4);

        .list-card__item:last-child {
            position: relative;
            margin-bottom: 4rem;

            &::after {
                content: "";
                border-bottom: 3px dashed var(--color-tertiary-4);
                position: absolute;
                bottom: -2rem;
                left: 0;
                width: 100%;
            }
        }
    }
}