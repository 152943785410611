.checkbox-group {
    cursor: pointer;
    display: inline-block;

    input { 
        font-family: var(--font-family-1st);
        display: none; 

        &:checked {
            & ~ .label {
                color: var(--color-primary);
                &::before {
                    background-color: var(--color-primary);
                    border-color: var(--color-primary);
                }
            }
        }
    }

    .label {
        display: inline-flex;
        align-items: flex-start;
        position: relative;
        transition: $transition;

        &::before {
            @extend %fig-check;
            background-color: #fff;
            border: 2px solid rgba(#365481, .15);
            color: #fff;
            display: inline-block;
            margin-right: 1rem;
            padding: 2px;
            transition: $transition;
        }
    }    
}

.checkbox-switch-group {
    display: flex;
    align-items: flex-start;
    gap: 15px;

    &__label { cursor: pointer; font-weight: bold; }
}

.checkbox-switch {
    background-color: #d8e1e3;
    border-radius: 99rem;
    border: 2px solid rgba(50,50,50,.2);
    cursor: pointer; 
    display: inline-block;
    position: relative;
    height: 2.6rem;
    width: 5rem;
    transition: $transition;

    &::before {
        content: "";
        background-color: #fff;
        border-radius: 99rem;
        position: absolute;
        height: 2.6rem;
        width: 2.6rem;
        top: 50%;
        left: -.2rem;
        transform: translateY(-50%);
        transition: $transition;
    }

    &::after {
        @extend %fig-check;
        color: var(--color-primary);
        font-size: 1.4rem;
        line-height: 1;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: .4rem;
        transform: translateY(-50%) rotate(180deg);
        transition: $transition;
    }
}
.checkbox-switch-input {
    display: none;

    &:checked + .checkbox-switch,
    &:checked + * .checkbox-switch {
        background-color: var(--color-primary);
        &::before {
            transform: translate(2.6rem,-50%);
        }
        &::after {
            opacity: 1;
            transform: translate(2.6rem, -50%) rotate(0);
        }
    }
} 

.checkbox-button {

    &__content {
        @extend %flex-center-center;
        flex-flow: row wrap;
        border: 3px solid var(--color-grey-3);
        border-radius: 99rem;
        color: var(--color-tertiary);
        cursor: pointer;
        padding: 1rem 2.5rem;
        transition: $transition;
    }

    &__label { 
        font-weight: bold; 
        transition: $transition;
    }
    &__icon { 
        color: var(--color-primary);
        margin-right: 1rem; 
        transition: $transition;
    }

    &__input { display: none; }
    &__input:checked + &__content { 
        border-color: var(--color-primary);
        background-color: var(--color-primary);
        color: #fff;
        & > * { color: #fff; }
    }

    // Variant 2
    &--2 {
        .checkbox-button__icon { color: var(--color-secondary); }
        .checkbox-button__input:checked + .checkbox-button__content {
            border-color: var(--color-secondary);
            background-color: var(--color-secondary);
        }
    }
}

.form-group-packed {
    margin-bottom: 2rem;
    position: relative;

    .fgp-wrap {
        position: relative;
    }

    label {
        font-size: 1.4rem;
        position: absolute;
        top: .75rem;
        left: 3.5rem;
        transition: $transition;
    }

    i {
        color: var(--color-grey);
        font-size: 2rem;
        line-height: 1;
        position: absolute;
        top: 2.2rem;
        right: 3rem;
        transition: $transition;
    }

    input {
        background-color: #fff;
        border-radius: 99rem;
        border: 2px solid rgba(#365481, .15);
        color: var(--color-tertiary);
        display: block;
        font-size: 1.8rem;
        font-weight: 700;
        padding: 3rem 2rem 1rem 3rem;
        width: 100%;
        transition: $transition;

        &::placeholder {
            font-weight: normal;
        }

        &:focus {
            border-color: var(--color-primary);
            box-shadow: none;
            outline: none;
            & ~ label, & ~ i { color: var(--color-primary); }
        }
    }
}

.form-group {
    margin-bottom: 2rem;
}
.form-group-sm {
    margin-bottom: 1rem;
}

.fieldset {
    border: none;
    margin-bottom: 3rem;

    &__legend {
        background-color: var(--color-secondary);
        border-radius: 99rem;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 2.2rem;
        gap: .5rem 1.5rem;
        margin-bottom: 1.5rem;
        padding: .5rem 2.5rem;
        letter-spacing: .07em;
        text-transform: uppercase;
        width: 100%;
    }
}

.input-1 {
    background-color: #fff;
    border: 2px solid var(--color-primary-white-2);
    border-radius: 1rem;
    color: var(--color-tertiary);
    display: block;
    font-family: var(--font-family-1st);
    font-size: inherit;
    font-weight: bold;
    padding: .75em 1.25em;
    width: 100%;
    transition: $transition;

    &:hover, &:focus-visible {
        border-color: var(--color-primary);
        outline: none;
    }
}
.input-2 {
    @extend .input-1;
    border: 3px solid var(--color-grey-4);

    &:hover, &:focus-visible {
        border-color: var(--color-primary-white-2);
    }
}

.input-group {
    display: flex;
    align-items: flex-start;

    &__label {
        @extend %flex-center-center;
        background-color: var(--color-primary);
        border-radius: 1rem;
        color: #fff;
        margin-right: 1.5rem;
        height: 6rem;
        width: 6rem;

        &--facebook { background-color: var(--color-facebook); }
        &--instagram { background-color: var(--color-instagram); }
        &--linkedin { background-color: var(--color-linkedin); }
        &--twitter { background-color: var(--color-twitter); }
    }

    &__inputs {
        flex-grow: 1;
        margin-top: .25rem;
    }
}

.input-length-1 {
    width: 45rem;
}

.input-icon {
    position: relative;

    &__icon {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);

        i {
            @extend %icon-fix;
            color: var(--color-primary);
            font-size: 1.7rem;
        }

        &:is(button) {
            background-color: var(--color-primary);
            border: none;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.5rem;
            width: 3.5rem;
            transition: $transition;

            i {
                color: #fff;
            }

            &:hover {
                background-color: var(--color-secondary);
            }
        }
    }
}

.label {
    display: block;
    font-weight: 400;
}

.see-password-wrap {
    position: relative;

    .see-password {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);
        &::before { @extend %fig-eye-crossed; }
        &.active::before { @extend %fig-eye; color: var(--color-primary); }
    }
}

.nice-select {
    cursor: pointer;
    position: relative;
    &-dropdown {
        @extend %is-hidden;
        background-color: #fff;
        cursor: pointer;
        overflow-y: scroll;
        position: absolute;
        top: 100%;
        left: 0;
        max-height: 30rem;
        min-width: 25rem;
        width: 100%;
        transform: translateY(-1rem);
        transition: $transition;
        z-index: 10;

        .list .option {
            padding: 1.5rem;
            transition: $transition;

            &:hover, &:focus-visible  {
                background-color: var(--color-primary) !important;
                color: #fff !important;
            }
        }
        .list .option.selected {
            background-color: var(--color-primary-2);
            color: #fff;
        }
    }

    &.open {
        .nice-select-dropdown {
            @extend %is-visible;
            transform: translateY(1rem);
        }
    }
}

.select-nice {
    @extend .input-1;
    line-height: 1.2;
    padding-right: 4rem;
    position: relative;

    &::before {
        content: "►";
        color: var(--color-primary);
        display: inline-block;
        font-size: 0.8em;
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
    }

    &--2 {
        @extend .input-2;
    }
}

.required {
    position: relative;
    &::after {
        content: "*";
        color: var(--color-danger);
        font-weight: bold;
        display: inline-block;
        margin-left: .5rem;
    }
}

.notif,
.invalid-feedback,
.outcome .error.visible {
    display: inline-block;
    font-size: 15px;
    margin-top: 4px;
    padding-left: 25px;
    position: relative;

    &::before, &::after {
        content: "";
        border-radius: 100px;
        position: absolute;
        left: 6px;
        top: 7px;
        height: 6px;
        width: 6px;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    &::after {
        top: 10px;
        left: 9px;
    }

    &::before {
        -webkit-animation: growTransp 1s infinite backwards;
        animation: growTransp 1s infinite backwards;
    }
}

.notif-success, .notif--success {
    color: var(--color-success);
    &::before, &::after { background-color: var(--color-success); }
}

.notif-danger, .notif--danger,
.invalid-feedback,
.outcome .error.visible {
    color: var(--color-danger);
    &::before, &::after { background-color: var(--color-danger); }
}


// Form notification success
.notif-form-success {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        flex-shrink: 0;
        margin-right: 15px;
        height: 50px;
        width: 50px;
        
        & > * { 
            stroke-dasharray: 1200;
            stroke-dashoffset: 1200;
            stroke: var(--color-success); 
        }
        & > *:first-child { animation: stroke-appear-1 .75s .5s cubic-bezier(0.33, 1, 0.68, 1) forwards; }
        & > *:last-child { animation: stroke-appear-1 1s 1s cubic-bezier(0.5, 1, 0.89, 1) forwards; }
    }

    span {
        color: var(--color-success);
        font-size: 18px;
        animation: fade-in .5s ease-in-out forwards;
    }
}


// Animations
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes grow-transp {
    from { opacity: .8; transform: scale(1); }
    to { opacity: 0; transform: scale(4); }
}
@keyframes stroke-appear-1 {
    from { 
        stroke-dasharray: 1200;
        stroke-dashoffset: 1200;
    }
    to {
        stroke-dasharray: 1200;
        stroke-dashoffset: 0;
    }
}
