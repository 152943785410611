.bubble-text {
    background-color: var(--color-primary);
    border-radius: 999rem;
    color: #fff;
    font-size: 2.8rem;
    line-height: 1.3;
    padding-top: 7rem;
    text-align: center;
    height: 37rem;
    width: 37rem;
    text-transform: uppercase;

    span { display: block; }
    span:nth-child(1) { font-size: 1.2em; font-weight: bold; }
    span:nth-child(2){ font-size: .7em; }
    span:nth-child(3) { font-size: 3em; font-weight: bold; }
    span:nth-child(4) { font-size: 1em; font-weight: bold; }

    &::before {
        content: "";
        background-color: var(--color-grey-3);
        border-radius: 999rem;
        position: absolute;
        inset: -3rem;
        z-index: -1;
    }
}